import { z } from 'zod';
import dayjs from 'dayjs';

import { schemaFields } from 'utils/schemaBuilder';
import { CommunicationType, IAvailability, Status } from 'interfaces/Agent';
import { ICardItem } from 'interfaces/CardItem';

export type AvailabilityValidity = {
  isValid: boolean;
  error?: string;
  index?: number;
  cause?: string;
};

export const newAvailabilty = {
  monday: { start: '09:00', end: '17:00', enabled: true },
  tuesday: { start: '09:00', end: '17:00', enabled: true },
  wednesday: { start: '09:00', end: '17:00', enabled: true },
  thursday: { start: '09:00', end: '17:00', enabled: true },
  friday: { start: '09:00', end: '17:00', enabled: true },
  saturday: { start: '09:00', end: '17:00', enabled: true },
  sunday: { start: '09:00', end: '17:00', enabled: true },
};

export const emptyAvailabilty = {
  monday: { start: '', end: '', enabled: false },
  tuesday: { start: '', end: '', enabled: false },
  wednesday: { start: '', end: '', enabled: false },
  thursday: { start: '', end: '', enabled: false },
  friday: { start: '', end: '', enabled: false },
  saturday: { start: '', end: '', enabled: false },
  sunday: { start: '', end: '', enabled: false },
};

export const formatCards = (
  cards: ICardItem[] | undefined,
  defaultCards: ICardItem[] | undefined
) => {
  if (!cards || cards.length === 0) {
    return defaultCards || [];
  }

  return cards.map((card) => ({
    ...card,
    body: card.body || '',
    sort_order: card.sort_order || 0,
    title: card.title || '',
  }));
};

const cleanTime = (time: string | undefined) => {
  if (!time || time === 'Invalid Date') {
    return '';
  }

  if (time.length > 5) {
    return time.slice(0, 5);
  }

  return time;
};

export const formatAvailability = (availability: IAvailability) => {
  if (!availability) {
    return emptyAvailabilty;
  }

  const formattedAvailability: IAvailability = {} as IAvailability;

  Object.keys(availability || {}).forEach((key) => {
    const typedKey = key as keyof IAvailability;
    formattedAvailability[typedKey] = {
      ...availability[typedKey],
      enabled: availability[typedKey].enabled || false,
      start: cleanTime(availability[typedKey].start),
      end: cleanTime(availability[typedKey].end),
    };
  });

  return formattedAvailability;
};

export const checkAvailability = (availability: IAvailability) => {
  return Object.values(availability || {}).some((item) => item.enabled);
};

export const checkValidAvailability = (availability: IAvailability) => {
  let validity: AvailabilityValidity = { isValid: true };

  Object.values(availability || {}).forEach((item, index) => {
    if (item.enabled) {
      if (!item.start || !item.end) {
        validity = {
          index,
          isValid: false,
          cause: !item.start ? 'start' : 'end',
          error: `${!item.start ? 'Start' : 'End'} time must be provided when day is enabled`,
        };
        return;
      }

      const startTime = dayjs(`2000-01-01 ${item.start}`);
      const endTime = dayjs(`2000-01-01 ${item.end}`);

      if (endTime.isBefore(startTime) || endTime.isSame(startTime)) {
        validity = {
          index,
          isValid: false,
          cause: 'start',
          error: 'Start time must be before end time',
        };
        return;
      }
    }
  });

  return validity;
};

const availabilityItemSchema = z.object({
  end: z.string().optional(),
  start: z.string().optional(),
  enabled: z.boolean().default(false),
});

export const formSchema = z.object({
  name: z.string().min(1, 'Business name is required'),
  voice: z.string().min(1, 'Voice profile is required'),
  time_zone: z.string().min(1, 'Time zone is required'),
  goodbye_message: z.string().min(1, 'Goodbye message is required'),
  welcome_message: z.string().min(1, 'Welcome greeting is required'),
  cards: z.array(
    z.object({
      title: z.string().min(1, 'Title is required'),
      body: z.string().min(1, 'Body is required'),
      sort_order: z.number(),
      id: z.string(),
    })
  ),
  users: z.array(
    z
      .object({
        id: z.string(),
        tags: z.array(z.string()).optional(),
        email: schemaFields.email.schema,
        name: schemaFields.fullName.schema,
        phone: schemaFields.phoneNumber.schema,
        status: z.nativeEnum(Status, {
          message: 'Availability status is required',
        }),
        availability: z.object({
          monday: availabilityItemSchema,
          tuesday: availabilityItemSchema,
          wednesday: availabilityItemSchema,
          thursday: availabilityItemSchema,
          friday: availabilityItemSchema,
          saturday: availabilityItemSchema,
          sunday: availabilityItemSchema,
        }),
        prefered_communication: z.nativeEnum(CommunicationType),
      })
      .superRefine((data, ctx) => {
        let isValidStatus = true;
        let availabilityValidity: AvailabilityValidity = {
          isValid: true,
          index: -1,
        };

        if (data.status === Status.SCHEDULE) {
          isValidStatus = checkAvailability(data.availability);
          availabilityValidity = checkValidAvailability(data.availability);
        }

        if (!isValidStatus) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: `At least one day must be enabled when on 'Schedule'`,
            path: ['status'],
          });
        } else if (!availabilityValidity.isValid) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: availabilityValidity.error || '',
            path: [
              `availability.${Object.keys(newAvailabilty)[availabilityValidity.index || 0]}.${availabilityValidity.cause}`,
            ],
          });
        }
      })
  ),
});
