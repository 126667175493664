import { z } from 'zod';
import { useContext, useState } from 'react';

import routes from 'utils/routes';
import Form from 'components/form';
import AuthContext from 'context/AuthContext';
import useFormHandler from 'hooks/useFormHandler';
import TermsAndPolicy from 'components/TermsAndPolicy';

const ForgotPassword = () => {
  const {
    actions: { forgotPassword },
  } = useContext(AuthContext);
  const [success, setSuccess] = useState<string | null>(null);
  const formSchema = z.object({
    email: z.string().email('Invalid email'),
  });

  const { form, loading, error, isValid, setError } =
    useFormHandler(formSchema);

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    try {
      setSuccess(null);
      await forgotPassword(values.email);
      setSuccess(
        'If an account exists with this email, a password reset link was sent'
      );
    } catch (error) {
      setError('An error occurred. Please try again.');
    }
  };

  return (
    <div className="flex-1 grid grid-cols-1 min-h-screen overflow-hidden">
      <div className="min-h-screen  overflow-y-auto md:overflow-y-hidden">
        <nav className="h-[70px] sticky top-0 w-full custom-bg-color  px-3 md:px-6 flex items-center justify-between">
          <a
            href={routes.dashboardPath.href}
            className="flex items-center gap-4"
          >
            <img src="/images/logo-icon.svg" alt="Nucleus Logo" />
          </a>

          <span></span>
          <div className="flex items-center gap-4">
            <a
              href={routes.signInPath.href}
              className="custom-border-color font-medium border rounded-md h-[40px] px-6 custom-bg-color hover:bg-hover-primary transition-all flex items-center"
            >
              <span>Sign in</span>
            </a>
          </div>
        </nav>
        <div className="md:flex-col h-[calc(100vh-150px)] md:h-[calc(100vh-130px)] items-center justify-center  lg:items-center signup-container px-3 md:px-0">
          <div className="border custom-border-color bg-white rounded-2xl p-6 md:p-12 w-full md:w-3/6 xl:w-[540px] mx-auto">
            <div className="mb-6 text-center">
              <h2 className="text-2xl font-semibold custom-text-color mb-2">
                Forgot your password?
              </h2>
              <p className="text-sm custom-text-color opacity-70">
                No problem! Enter your email and we'll send you a link to reset
                it.
              </p>
            </div>

            <Form
              form={form}
              error={error}
              success={success}
              onSubmit={onSubmit}
            >
              <Form.Input
                type="email"
                name="email"
                label="Email"
                placeholder="Email"
              />
              <Form.Submit loading={loading} disabled={!isValid || loading}>
                Reset password
              </Form.Submit>
            </Form>
          </div>
        </div>
        <div className="w-full h-[80px] md:h-[50px]">
          <TermsAndPolicy />
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
