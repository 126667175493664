import { ActionIcon } from '@mantine/core';
import { IconPlayerPlayFilled } from '@tabler/icons-react';

const AttendantPlayButton = () => {
  return (
    <ActionIcon 
      mb={2}
      ml="sm"
      size={32}
      radius="xl"
      color="gray"
      variant="light"
      onClick={() => console.log('Play sound')}
      bd="1px solid var(--mantine-color-gray-3)"
    >
      <IconPlayerPlayFilled size={16} />
    </ActionIcon>
  );
};

export default AttendantPlayButton;
