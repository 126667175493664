import {
  Text,
  Flex,
  Grid,
  Stack,
  Alert,
  Button,
  ActionIcon,
} from '@mantine/core';
import Form from 'components/form';
import { v4 as uuidv4 } from 'uuid';
import Section from 'components/Section';
import { IAgentPronunciation } from 'interfaces/Agent';
import AttendantPlayButton from './AttendantPlayButton';
import { useFormComponentContext } from 'context/FormContext';
import { IconInfoOctagon, IconPlus, IconTrash } from '@tabler/icons-react';

export const PronunciationList = () => {
  const form = useFormComponentContext();
  const pronunciationList = form.values.pronunciations || [];

  const addPronunciation = () => {
    const pronunciations = pronunciationList;
    form.setFieldValue('pronunciations', [
      ...pronunciations,
      {
        id: uuidv4(),
        word: '',
        sound: '',
      },
    ]);
  };

  const removePronunciation = (index: number) => {
    const pronunciations = pronunciationList.filter(
      (_: IAgentPronunciation, i: number) => i !== index
    );
    form.setFieldValue('pronunciations', pronunciations);
  };

  return (
    <Grid>
      <Grid.Col span={{ lg: 10, base: 12 }}>
        <Flex justify="space-between" align="center">
          <Text size="sm" fw={600} my="xl">
            Pronunciation settings
          </Text>
        </Flex>

        <Alert
          mb="xl"
          radius="md"
          color="#c92a2a"
          variant="default"
          icon={<IconInfoOctagon />}
        >
          Help your AI employee speak words correctly. Fix names, terms, or
          phrases that sound wrong. You cann use hyphens to separate syllables
          or to pronounce single letters
        </Alert>

        {pronunciationList.length === 0 ? (
          <Button
            variant="outline"
            onClick={addPronunciation}
            radius="md"
            leftSection={<IconPlus size={16} />}
            fullWidth
            mb="lg"
            style={{ borderStyle: 'dashed' }}
            disabled={false}
          >
            Add Pronunciation
          </Button>
        ) : (
          <Section mb="lg" bg="#fcfcfc" p={{ md: 'xl', base: 'md' }}>
            {pronunciationList.map((_: IAgentPronunciation, index: number) => (
              <Grid key={index}>
                <Grid.Col span={{ md: 5, base: 12 }}>
                  <Form.Input
                    required
                    name={`pronunciations.${index}.word`}
                    label="Original word"
                    placeholder="Ex: SKU"
                  />
                </Grid.Col>

                <Grid.Col span={{ md: 7, base: 12 }}>
                  <Flex align="center" gap="sm" w="100%">
                    <Stack gap={10} w="100%">
                      <Text size="sm" fw={500}>
                        Sounds like *
                      </Text>
                      <Flex
                        align="center"
                        gap={{ md: 'md', base: 'xs' }}
                        w="100%"
                      >
                        <Flex align="center" gap="sm" w="100%">
                          <Form.Input
                            required
                            name={`pronunciations.${index}.sound`}
                            mb={0}
                            placeholder="Ex: skew"
                            style={{ flex: 1 }}
                          />
                          <AttendantPlayButton />
                        </Flex>
                        <ActionIcon
                          variant="default"
                          style={{ border: 'none' }}
                          onClick={() => removePronunciation(index)}
                        >
                          <IconTrash color="#c92a2a" stroke={1} size={26} />
                        </ActionIcon>
                      </Flex>
                    </Stack>
                  </Flex>
                </Grid.Col>
              </Grid>
            ))}
            <Button
              mt="md"
              mb="xs"
              fullWidth
              radius="md"
              disabled={false}
              variant="default"
              onClick={addPronunciation}
              style={{ border: '1px dashed var(--mantine-color-gray-4)' }}
              leftSection={<IconPlus size={16} />}
            >
              Add Pronunciation
            </Button>
          </Section>
        )}
      </Grid.Col>
    </Grid>
  );
};
