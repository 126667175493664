import { z } from 'zod';
import { useEffect, useState, useContext } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import routes from 'utils/routes';
import Form from 'components/form';
import AuthContext from 'context/AuthContext';
import useFormHandler from 'hooks/useFormHandler';
import TermsAndPolicy from 'components/TermsAndPolicy';

const ResetPassword = () => {
  const {
    actions: { resetPassword },
  } = useContext(AuthContext);
  const [searchParams] = useSearchParams();
  const token = searchParams.get('reset-token');
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [passwordValid, setPasswordValid] = useState(false);

  useEffect(() => {
    if (!token) {
      navigate(routes.signInPath.href);
    }
  }, [token, navigate]);

  const formSchema = z
    .object({
      password: z.string(),
      confirm_password: z.string(),
      valid_password: z.boolean(),
    })
    .refine((data) => data.password === data.confirm_password, {
      message: "Passwords don't match",
      path: ['confirm_password'],
    });

  const { form, loading, error, isValid, setError } =
    useFormHandler(formSchema);

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    try {
      await resetPassword(values.password, token || '');
      navigate(routes.dashboardPath.href);
    } catch (error) {
      setError('An error occurred. Please try again.');
    }
  };

  const getConfirmPasswordError = () => {
    if (
      !form.values.confirm_password ||
      !form.values.password ||
      form.values.confirm_password === form.values.password
    ) {
      return undefined;
    }

    return form.values.password.length > 0 &&
      form.values.confirm_password.length >= form.values.password.length
      ? 'Passwords do not match'
      : undefined;
  };

  return (
    <div className="flex-1 grid grid-cols-1 min-h-screen overflow-hidden">
      <div className="min-h-screen  overflow-y-auto md:overflow-y-hidden">
        <nav className="h-[70px] sticky top-0 w-full custom-bg-color  px-3 md:px-6 flex items-center justify-between">
          <a
            href={routes.dashboardPath.href}
            className="flex items-center gap-4"
          >
            <img src="/images/logo-icon.svg" alt="Nucleus Logo" />
          </a>

          <span></span>
          <div className="flex items-center gap-4">
            <a
              href={routes.signInPath.href}
              className="custom-border-color font-medium border rounded-md h-[40px] px-6 custom-bg-color hover:bg-hover-primary transition-all flex items-center"
            >
              <span>Sign in</span>
            </a>
          </div>
        </nav>
        <div className="md:flex-col h-[calc(100vh-150px)] md:h-[calc(100vh-130px)] items-center justify-center  lg:items-center signup-container px-3 md:px-0">
          <div className="border custom-border-color bg-white rounded-2xl p-6 md:p-12 w-full md:w-3/6 xl:w-[540px] mx-auto">
            <div className="mb-6 text-center">
              <h2 className="text-2xl font-semibold custom-text-color mb-2">
                Choose your new password
              </h2>
            </div>

            <Form form={form} error={error} onSubmit={onSubmit}>
              <Form.Password
                name="password"
                label="Password"
                placeholder="Password"
                onUpdate={(value) => setPassword(value)}
              />
              <Form.Password
                name="confirm_password"
                label="Confirm Password"
                placeholder="Confirm password"
                error={getConfirmPasswordError()}
              />
              <Form.PasswordCheck
                value={password}
                validatePassword={(isValid) => {
                  setPasswordValid(isValid);
                  form.setValues({ valid_password: isValid });
                }}
              />
              <Form.Submit
                loading={loading}
                disabled={!isValid || loading || !passwordValid}
              >
                Reset Password
              </Form.Submit>
            </Form>
          </div>
        </div>
        <div className="w-full h-[80px] md:h-[50px]">
          <TermsAndPolicy />
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
