import { Flex, Loader } from '@mantine/core';
import { useContext, useEffect } from 'react';

import AuthContext from 'context/AuthContext';
import useStripeUrlHelper from 'hooks/useStripeUrlHelper';
import BillingContext from 'context/BillingContext';
import ScrollSection from 'components/ScrollSection';
import { useNotification } from 'context/NotificationContext';

const BillingPage = () => {
  const { showError } = useNotification();
  const { buildReturnUrl } = useStripeUrlHelper();
  const {
    data: { authToken },
    actions: { isLoggedIn },
  } = useContext(AuthContext);
  const {
    actions: { createStorePortalSession },
  } = useContext(BillingContext);

  const loadStoreUrl = async () => {
    try {
      const url = await createStorePortalSession(buildReturnUrl());
      window.open(url, '_self');
    } catch (e) {
      showError('An error occurred. Please try again.');
    }
  };

  useEffect(() => {
    if (isLoggedIn()) loadStoreUrl();
  }, [authToken]);

  return (
    <ScrollSection withNav={false}>
      <Flex align="center" justify="center" h="100%">
        <Loader size="md" />
      </Flex>
    </ScrollSection>
  );
};

export default BillingPage;
