import { Dispatch, useEffect, useState } from 'react';

const useLocalStorage = <T>(
  key: string,
  initialValue: T
): [T, Dispatch<any>, boolean] => {
  const isWindowDefined = typeof window !== 'undefined';

  const [fetched, setFetched] = useState(false);
  const [storedValue, setStoredValue] = useState<T>(() => {
    if (!isWindowDefined) return initialValue;
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (e) {
      return initialValue;
    }
  });

  useEffect(() => {
    if (!isWindowDefined) return;
    try {
      const item = window.localStorage.getItem(key);
      setStoredValue(item ? JSON.parse(item) : initialValue);
      setFetched(true);
    } catch (e) {
      setStoredValue(initialValue);
      setFetched(true);
    }
  }, [key, isWindowDefined, initialValue]);

  useEffect(() => {
    if (!isWindowDefined) return;
    try {
      const valueToStore =
        storedValue instanceof Function
          ? storedValue(storedValue)
          : storedValue;
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (e) {
      console.log(e);
    }
  }, [storedValue, key, isWindowDefined]);

  return [storedValue, setStoredValue, fetched];
};

export default useLocalStorage;
