import { useState } from 'react';
import { IconChevronRight } from '@tabler/icons-react';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
import { Flex, Group, NavLink, Collapse, UnstyledButton } from '@mantine/core';

import getClassNames from 'utils/classNames';

import classes from './NavbarLink.module.scss';

interface NavbarLinkProps {
  link?: string;
  label: string;
  icon: React.FC<any>;
  closeNav?: () => void;
  initiallyOpened?: boolean;
  links?: { label: string; link: string }[];
}

const NavbarLink = ({
  link,
  label,
  links,
  closeNav,
  icon: Icon,
  initiallyOpened,
}: NavbarLinkProps) => {
  const [opened, setOpened] = useState(initiallyOpened || false);
  const location = useLocation();
  const currentRoute =
    links?.find((link) => link.link === location.pathname) ||
    link === location.pathname;

  const items = (links || []).map((link) => (
    <NavLink
      to={link.link}
      key={link.label}
      label={link.label}
      onClick={closeNav}
      component={RouterLink}
      className={getClassNames(classes.link, {
        [classes.controlActive]: !opened && !!currentRoute,
        [classes.controlOpenActive]: opened && !!currentRoute,
      })}
    />
  ));

  if (link) {
    return (
      <NavLink
        to={link}
        onClick={closeNav}
        component={RouterLink}
        className={getClassNames(classes.control, {
          [classes.controlActive]: !opened && !!currentRoute,
          [classes.controlOpenActive]: opened && !!currentRoute,
        })}
        label={
          <Group justify="space-between" gap={0}>
            <Flex align="flex-start">
              <Icon size={20} className={classes.linkIcon} stroke={1.5} />
              <span style={{ whiteSpace: 'nowrap' }}>{label}</span>
            </Flex>
          </Group>
        }
      />
    );
  }

  return (
    <>
      <UnstyledButton
        className={getClassNames(classes.control, {
          [classes.controlActive]: !opened && !!currentRoute,
          [classes.controlOpenActive]: opened && !!currentRoute,
        })}
        onClick={() => setOpened((o) => !o)}
      >
        <Group justify="space-between" gap={0}>
          <Flex align="flex-start">
            <Icon size={20} className={classes.linkIcon} stroke={1.5} />
            <span>{label}</span>
          </Flex>
          <IconChevronRight
            size={16}
            stroke={1.5}
            className={classes.chevron}
            style={{ transform: opened ? 'rotate(-90deg)' : 'none' }}
          />
        </Group>
      </UnstyledButton>
      <Collapse in={opened} className={classes.items}>
        <Flex gap="sm" direction="column">
          {items}
        </Flex>
      </Collapse>
    </>
  );
};

export default NavbarLink;
