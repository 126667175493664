import routes from 'utils/routes';
import { Anchor } from '@mantine/core';

const TermsAndPolicy = () => {
  return (
    <p className="text-base custom-text-color opacity-70 text-center mt-auto py-4 mx-w-[80%]">
      © 2025 Nucleus. By using this site, you agree to our{' '}
      <Anchor href={routes.termsPath.href} target="_blank" inherit>
        Terms of Use
      </Anchor>
      {' and '}
      <Anchor href={routes.privacyPath.href} target="_blank" inherit>
        Privacy Policy
      </Anchor>
    </p>
  );
};

export default TermsAndPolicy;
