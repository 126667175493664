import { useNavigate } from 'react-router-dom';
import axios, { AxiosInstance, InternalAxiosRequestConfig } from 'axios';

import routes from 'utils/routes';
import { STORAGE_AUTH_DATA } from 'constants/localStorage';

class ApiClient {
  private static instance: ApiClient;
  private api: AxiosInstance;

  private constructor() {
    this.api = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    this.api.interceptors.request.use((config: InternalAxiosRequestConfig) => {
      if (!config.headers['Content-Type']) {
        config.headers['Content-Type'] = 'application/json';
      }

      return config;
    });

    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem(STORAGE_AUTH_DATA);

          const navigate = useNavigate();
          navigate(routes.signInPath.href);
        }
        return Promise.reject(error);
      }
    );
  }

  public static getInstance(): AxiosInstance {
    if (!ApiClient.instance) {
      ApiClient.instance = new ApiClient();
    }
    return ApiClient.instance.api;
  }
}

const api = ApiClient.getInstance();
export default api;
