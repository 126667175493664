import { FC } from 'react';
import { Button, ButtonProps, Loader } from '@mantine/core';

import { useFormComponentContext } from 'context/FormContext';

interface SubmitProps extends ButtonProps {
  loading?: boolean;
}

const Submit: FC<SubmitProps> = ({ children, loading, ...props }) => {
  const form = useFormComponentContext();

  return (
    <Button
      fullWidth
      mt="xl"
      type="submit"
      rightSection={loading ? <Loader size="14" color="#adb5bd" /> : null}
      {...props}
      disabled={
        !(form.isValid() && Object.keys(form.errors).length === 0) ||
        props.disabled
      }
    >
      {children}
    </Button>
  );
};

export default Submit;
