import { FC, useEffect, useState } from 'react';
import { IconPlus } from '@tabler/icons-react';
import { Box, Flex, Text, Button } from '@mantine/core';

import { ITeamMember, IUserCreate } from 'interfaces/Agent';
import { UpgradeWrapper } from 'components/upgrade';
import { MAX_STAFF_MEMBERS } from 'constants/settings';
import StaffListItem from './StaffListitem';
import { useFormComponentContext } from 'context/FormContext';
import { useFormContext } from 'context/FormContext';
import ConfirmModal from 'components/modal/ConfirmModal';

import classes from './StaffList.module.scss';
import { pluralize } from 'utils/string';

interface IStaffListProps {
  users: IUserCreate[];
  userId: string | null;
  setUserId: (id: string) => void;
  onAddStaff: () => void;
}

const StaffList: FC<IStaffListProps> = ({
  users,
  userId,
  setUserId,
  onAddStaff,
}) => {
  const form = useFormComponentContext();
  const { onSubmit, error, success, recoveryState, setRecoveryState } =
    useFormContext();

  const [deleteList, setDeleteList] = useState<Record<string, boolean>>({});
  const [loading, setLoading] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const deleteCount = Object.values(deleteList).filter(Boolean).length;

  const handleSetDeleteList = (id: string, checked: boolean) => {
    setDeleteList((prev) => ({ ...prev, [id]: checked }));
  };

  //const handleDeleteUsers = async () => {
  //  setLoading(true);
  //  const updatedUsers = users.filter(
  //    (user: ITeamMember) => !deleteList[user.id]
  //  );

  //  setRecoveryState?.(form.values);
  //  form.setFieldValue('users', updatedUsers);
  //  onSubmit?.();
  //};

  //useEffect(() => {
  //  if (success) {
  //    setDeleteList({});
  //    setShowConfirmDelete(false);
  //    setLoading(false);
  //  }
  //}, [success]);

  //useEffect(() => {
  //  if (error) {
  //    setLoading(false);
  //    setDeleteList({});
  //    setShowConfirmDelete(false);
  //    form.setFieldValue('users', recoveryState.users);
  //  }
  //}, [error]);

  return (
    <>
      <Flex direction="column" gap={0}>
        <Box
          pt="lg"
          bg="white"
          pos="sticky"
          top={0}
          style={{
            zIndex: 10,
            borderTopLeftRadius: 'var(--mantine-radius-md)',
            borderTopRightRadius: 'var(--mantine-radius-md)',
          }}
        >
          <Flex w="100%" px="md" align="center" justify="space-between">
            <h3 className="text-base custom-text-color font-semibold">
              Staff Directory
            </h3>
            <UpgradeWrapper
              as={Button}
              requiresUpgrade={users.length >= MAX_STAFF_MEMBERS}
              upgradeTitle="Look at you grow!"
              upgradeDescription="To add more team members and unlock more benefits..."
              componentProps={{
                size: 'xs',
                variant: 'default',
                onClick: onAddStaff,
                leftSection: <IconPlus size={14} />,
              }}
            >
              Add Staff
            </UpgradeWrapper>
          </Flex>
          <Flex mt="lg">
            {/*{deleteCount > 0 && (
              <Flex
                style={{ marginBottom: '-1px' }}
                align="center"
                justify="space-between"
                className={classes.deleteBanner}
              >
                <Text fz="sm">Delete selected</Text>
                <Button
                  size="xs"
                  onClick={() => setShowConfirmDelete(true)}
                  color="var(--mantine-color-red-6)"
                >
                  Delete {deleteCount}
                </Button>
              </Flex>
            )}*/}
          </Flex>
        </Box>
        <Box>
          {users.map((user, index) => (
            <StaffListItem
              key={user.id}
              user={user}
              index={index}
              userId={userId}
              checkList={deleteList}
              setChecked={handleSetDeleteList}
              setUserId={setUserId}
            />
          ))}
        </Box>
      </Flex>
      {/*<ConfirmModal
        loading={loading}
        isOpen={showConfirmDelete}
        onClose={() => setShowConfirmDelete(false)}
        onConfirm={handleDeleteUsers}
        title="Delete Users"
        message={`Are you sure you want to delete ${pluralize('user', deleteCount)}? This action cannot be undone.`}
      />*/}
    </>
  );
};

export default StaffList;
