import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from '@stripe/react-stripe-js';
import { Stripe, loadStripe } from '@stripe/stripe-js';
import { useContext } from 'react';
import ApiContext, { PRO_YEAR_PRICE_NAME } from 'context/BillingContext';
import AuthContext from 'context/AuthContext';
import { Navigate, useSearchParams } from 'react-router-dom';
import ScrollSection from 'components/ScrollSection';
import { AccessLevel } from 'interfaces/GetUserResult';
import routes from 'utils/routes';
import useStripeUrlHelper from 'hooks/useStripeUrlHelper';
import { Loader, Flex } from '@mantine/core';

const apiKey = process.env.REACT_APP_STRIPE_API_KEY;

const UpgradePage = () => {
  const [searchParams] = useSearchParams();
  const { buildReturnUrl } = useStripeUrlHelper();

  const {
    data: { stripe, account, userFetched },
  } = useContext(AuthContext);

  const {
    actions: { createStoreSession },
  } = useContext(ApiContext);

  const fetchClientSecret = (): Promise<string> => {
    const promo_code = (window as any).Rewardful.coupon || searchParams.get('promo_code') || undefined;
    const lookup_key = searchParams.get('lookup_key') || PRO_YEAR_PRICE_NAME;
    const refer_id = (window as any).Rewardful.referral || searchParams.get('via') || undefined;

    return createStoreSession({
      refer_id,
      promo_code,
      lookup_key,
      return_url: buildReturnUrl(),
    });
  };

  const loadStripePromise = async (): Promise<Stripe | null> => {
    if (apiKey) {
      const stripePromise = await loadStripe(apiKey);
      return stripePromise;
    } else {
      console.log('MISSING STRIPE_API_KEY');
      return null;
    }
  };

  if (!userFetched) {
    return (
      <ScrollSection>
        <Flex align="center" justify="center" h="100%">
          <Loader size="md" />
        </Flex>
      </ScrollSection>
    );
  }

  if (stripe?.has_billing && account?.access_level === AccessLevel.PRO) {
    return <Navigate to={routes.attendantPath.href} replace />;
  }

  const options = { fetchClientSecret };

  return (
    <ScrollSection withNav={false}>
      <EmbeddedCheckoutProvider stripe={loadStripePromise()} options={options}>
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </ScrollSection>
  );
};

export default UpgradePage;
