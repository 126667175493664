import { Box, Flex, Group, Text, Button } from '@mantine/core';

import StaffForm from './StaffForm';
import StaffInfoMenu from './StaffInfoMenu';
import StaffImageUploader from './StaffImageUploader';
import classes from './StaffInfo.module.scss';
import { FC } from 'react';
import { IUserCreate } from 'interfaces/Agent';
import { IconArrowLeft } from '@tabler/icons-react';

interface IStaffInfoProps {
  userId: string | null;
  users: IUserCreate[];
  onBack?: () => void;
  setUserId: (id: string | null) => void;
}

const StaffInfo: FC<IStaffInfoProps> = ({
  userId,
  setUserId,
  users,
  onBack,
}) => {
  return (
    <Flex direction="column" gap="md">
      <Group px="lg" justify="space-between" h={25}>
        <Flex gap="md" align="center">
          <Text fz="md" className={classes.title} fw={500}>
            Edit Information
          </Text>
          <Button
            size="xs"
            variant="default"
            leftSection={<IconArrowLeft size={16} />}
            onClick={onBack}
            hiddenFrom="md"
          >
            Back to List
          </Button>
        </Flex>
        <StaffInfoMenu userId={userId} users={users} setUserId={setUserId} />
      </Group>
      <Box px="lg">
        {false && <StaffImageUploader />}
        <StaffForm userId={userId} users={users} />
      </Box>
    </Flex>
  );
};

export default StaffInfo;
