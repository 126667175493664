import { FC, useEffect, useState } from 'react';
import { IconCheck, IconX } from '@tabler/icons-react';
import { PasswordInput, PasswordInputProps } from '@mantine/core';

import { useFormComponentContext } from 'context/FormContext';
import useEditStyles from 'hooks/useEditStyles';

export const MINIMUM_PASSWORD_LENGTH = 8;

interface FormPasswordProps extends Omit<PasswordInputProps, 'onChange'> {
  name: string;
  label?: string;
  error?: string;
  value?: string;
  required?: boolean;
  showEdit?: boolean;
  placeholder?: string;
  description?: string;
  onChange?: (value: string) => void;
  onUpdate?: (value: string) => void;
}

interface FormPasswordRequirementProps {
  label: string;
  meets: boolean;
}

interface FormPasswordCheckProps {
  value: string;
  validatePassword: (isValid: boolean) => void;
}

const defaultPasswordRequirements = [
  { regex: /[a-z]/, label: 'One lowercase character' },
  { regex: /[$&+,:;=?@#|'<>.^*()%!-]/, label: 'One special character' },
  { regex: /[0-9]/, label: 'One number' },
  { regex: /[A-Z]/, label: 'One uppercase character' },
  {
    regex: new RegExp(`^.{${MINIMUM_PASSWORD_LENGTH},}$`),
    label: `${MINIMUM_PASSWORD_LENGTH} characters minimum`,
  },
];

const PasswordRequirement: FC<FormPasswordRequirementProps> = ({
  meets,
  label,
}) => {
  return (
    <div
      className={`flex items-center gap-2 min-w-[182px] ${meets ? 'text-teal-500' : 'text-red-500'}`}
    >
      <span className="inline-block rounded-full">
        {meets ? (
          <IconCheck size={14} stroke={1.5} />
        ) : (
          <IconX size={14} stroke={1.5} />
        )}
      </span>
      <span className="text-sm custom-text-color opacity-70">{label}</span>
    </div>
  );
};

const FormPassword: FC<FormPasswordProps> = ({
  name,
  label,
  placeholder,
  description,
  required = false,
  showEdit = false,
  ...props
}) => {
  const form = useFormComponentContext();
  const { editedInputStyles } = useEditStyles({ name, showEdit });
  const { onUpdate, value, ...otherProps } = props;

  return (
    <PasswordInput
      label={label}
      required={required}
      value={value || ''}
      placeholder={placeholder}
      description={description}
      styles={{ ...editedInputStyles, ...props.styles }}
      {...form.getInputProps(name)}
      {...otherProps}
      onChange={(e) => {
        onUpdate?.(e.target.value);
        form.getInputProps(name).onChange(e.target.value);
      }}
    />
  );
};

const PasswordCheck: FC<FormPasswordCheckProps> = ({
  value,
  validatePassword,
}) => {
  const [checkValue, setCheckValue] = useState(value);

  useEffect(() => {
    setCheckValue(value);
  }, [value]);

  useEffect(() => {
    const invalidCount = defaultPasswordRequirements.reduce(
      (acc: number, requirement: { regex: RegExp; label: string }) => {
        const meets = requirement.regex.test(value || '');
        return meets ? acc : acc + 1;
      },
      0
    );

    validatePassword(invalidCount === 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkValue]);

  const checkList = defaultPasswordRequirements.map((requirement, index) => {
    const meets = requirement.regex.test(checkValue || '');
    return (
      <PasswordRequirement
        key={index}
        label={requirement.label}
        meets={meets}
      />
    );
  });

  return (
    <div className="grid sm:grid-cols-2 gap-2 justify-between mb-6">
      {checkList}
    </div>
  );
};

export default FormPassword;
export { PasswordCheck };
