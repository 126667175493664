import { useContext } from 'react';
import AuthContext from 'context/AuthContext';
import dayjs from 'dayjs';
import { AccessLevel } from 'interfaces/GetUserResult';

const useStripeTrial = () => {
  const {
    data: { stripe, account },
  } = useContext(AuthContext);

  const now = dayjs().utc();
  const trialStart = dayjs.utc(stripe?.trial_start);
  const trialEnd = dayjs.utc(stripe?.trial_end);
  const isPremium = account?.access_level === AccessLevel.PRO;

  const isOnTrial =
    !stripe?.has_billing &&
    isPremium &&
    now.isAfter(trialStart) &&
    now.isBefore(trialEnd);

  return { isOnTrial };
};

export default useStripeTrial;
