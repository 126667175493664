import ScrollSection from 'components/ScrollSection';
import TrainingForm from './TrainingForm';

const TrainingData = () => {
  return (
    <ScrollSection>
      <TrainingForm />
    </ScrollSection>
  );
};

export default TrainingData;
