import {
  Text,
  Flex,
  Grid,
  Alert,
  Group,
  Button,
  Progress,
  UnstyledButton,
} from '@mantine/core';
import Form from 'components/form';
import { v4 as uuidv4 } from 'uuid';
import Section from 'components/Section';
import { formatNumber } from 'utils/string';
import { useContext, useState } from 'react';
import AuthContext from 'context/AuthContext';
import { ICardItem } from 'interfaces/CardItem';
import BillingContext from 'context/BillingContext';
import { AccessLevel } from 'interfaces/GetUserResult';
import { useFormComponentContext } from 'context/FormContext';
import { IconPlus, IconInfoOctagon } from '@tabler/icons-react';
import { MAX_PRO_CARD_LENGTH, MAX_FREE_CARD_LENGTH } from 'constants/settings';
import { useDeviceType } from 'hooks/useDeviceType';

import classes from './CardList.module.scss';

const getProgressColor = (progress: number) => {
  if (progress > 100) {
    return 'red';
  } else if (progress >= 80) {
    return 'yellow';
  } else {
    return 'blue';
  }
};

export const CardList = () => {
  const form = useFormComponentContext();
  const {
    actions: { setPricingOpened },
  } = useContext(BillingContext);
  const {
    data: { account },
  } = useContext(AuthContext);
  const { isTablet } = useDeviceType();

  const getTotalChars = () => {
    return form.values.cards.reduce((total: number, card: ICardItem) => {
      return total + (card.title?.length || 0) + (card.body?.length || 0);
    }, 0);
  };

  const isPremium = account?.access_level === AccessLevel.PRO;

  const maxCards = isPremium ? MAX_PRO_CARD_LENGTH : MAX_FREE_CARD_LENGTH;
  const totalChars = getTotalChars();
  const progress = (totalChars / maxCards) * 100;

  const [alertOpened, setAlertOpened] = useState(!isPremium && progress >= 80);

  const addCard = () => {
    const cards = form.values.cards;
    form.setFieldValue('cards', [
      ...cards,
      {
        id: uuidv4(),
        title: '',
        body: '',
        sort_order: cards.length,
      },
    ]);
  };

  const removeCard = (index: number) => {
    const cards = form.values.cards.filter(
      (_: ICardItem, i: number) => i !== index
    );
    form.setFieldValue('cards', cards);
  };

  return (
    <Grid>
      <Grid.Col span={{ lg: 10, base: 12 }}>
        <Group
          pt="xl"
          pb="sm"
          bg="white"
          pos="sticky"
          top="-1.5rem"
          style={{ zIndex: 10 }}
        >
          <Flex
            w="100%"
            gap={{ lg: 0, base: 'md' }}
            justify="space-between"
            align="center"
          >
            <Text size="md" fw={600} style={{ whiteSpace: 'nowrap' }}>
              Business Information
            </Text>

            <Flex
              justify="flex-end"
              gap={{ md: 20, base: '20' }}
              w={{ lg: 'auto', base: '100%' }}
              align={{ md: 'center', base: 'center' }}
            >
              <Text
                span
                size="sm"
                fw={500}
                visibleFrom="lg"
                style={{ whiteSpace: 'nowrap' }}
              >
                Total characters used:
              </Text>
              <div className={classes.progressBar}>
                <Progress
                  size="sm"
                  radius="sm"
                  value={progress}
                  visibleFrom="sm"
                  transitionDuration={200}
                  color={getProgressColor(progress)}
                />
              </div>
              <Text
                span
                size="sm"
                fw={500}
                display="block"
                c={{ sm: 'dimmed', base: getProgressColor(progress) }}
              >
                {totalChars}/{maxCards}
              </Text>
            </Flex>
          </Flex>
          {alertOpened && (
            <Alert
              radius="md"
              variant="light"
              withCloseButton
              p={{ base: 'sm', md: 'md' }}
              onClose={() => setAlertOpened(false)}
              icon={
                isTablet ? undefined : (
                  <IconInfoOctagon size="xl" stroke={1.5} />
                )
              }
              style={{ border: '1px solid', flex: 'auto' }}
              title={
                <Text fz={{ base: 'sm', md: 'md' }} fw={isTablet ? 400 : 600}>
                  Upgrade for more content space
                </Text>
              }
              styles={{
                title: {
                  color: 'var(--text-color)',
                },
              }}
            >
              <Flex
                mt="sm"
                align="stretch"
                justify="space-between"
                gap={{ base: 'md', md: 'xl' }}
                direction={{ base: 'column', md: 'row' }}
              >
                <Text c="var(--mantine-color-gray-7)" visibleFrom="md">
                  Your Free account includes{' '}
                  {formatNumber(MAX_FREE_CARD_LENGTH)} characters of information
                  about your business. Upgrade to Premium and expand to{' '}
                  {formatNumber(MAX_PRO_CARD_LENGTH)} characters.
                </Text>
                <Button
                  size={isTablet ? 'xs' : 'sm'}
                  radius="sm"
                  miw="fit-content"
                  color="var(--green-color)"
                  onClick={() => setPricingOpened(true)}
                >
                  Upgrade now
                </Button>
              </Flex>
            </Alert>
          )}
        </Group>

        {form.values.cards.map((_: ICardItem, index: number) => (
          <Section
            key={index}
            my={{ base: 'md', md: 'lg' }}
            bg="#fcfcfc"
            p={{ md: 'xl', base: 'md' }}
          >
            <Form.Input
              name={`cards.${index}.title`}
              label="Subject"
              mb="sm"
              showEdit
              placeholder="Enter a question or topic"
            />
            <Form.Textarea
              name={`cards.${index}.body`}
              placeholder="Enter the answer to the question, or details for the provided topic"
              label="Body"
              minRows={3}
              autosize
              showEdit
            />
            <UnstyledButton onClick={() => removeCard(index)}>
              <Text size="sm" mt="md" fw={600} c="red">
                Remove card
              </Text>
            </UnstyledButton>
          </Section>
        ))}

        <Button
          variant="outline"
          onClick={addCard}
          radius="md"
          leftSection={<IconPlus size={16} />}
          fullWidth
          mb="lg"
          mt="md"
          style={{ borderStyle: 'dashed' }}
          disabled={totalChars >= maxCards}
        >
          Add information card
        </Button>
      </Grid.Col>
    </Grid>
  );
};
