import { z } from 'zod';
import { useState } from 'react';
import { useForm, UseFormInput, zodResolver } from '@mantine/form';

interface UseFormHandlerReturn<T extends z.ZodType> {
  errors: any;
  loading: boolean;
  isValid: boolean;
  error: string | null;
  form: ReturnType<typeof useForm<z.infer<T>>>;
  setError: React.Dispatch<React.SetStateAction<string | null>>;
}

const useFormHandler = <T extends z.ZodType>(
  schema: T,
  config?: Omit<UseFormInput<z.infer<T>>, 'validate'>
): UseFormHandlerReturn<T> => {
  const form = useForm<z.infer<T>>({
    ...config,
    validateInputOnBlur: true,
    validate: zodResolver(schema),
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const originalOnSubmit = form.onSubmit;

  const onSubmit: typeof originalOnSubmit = (handleSubmit) => {
    return originalOnSubmit(async (values, e) => {
      try {
        setLoading(true);
        setError(null);
        await handleSubmit(values, e);
      } catch (err) {
        setError(err instanceof Error ? err.message : 'An error occurred');
        throw err;
      } finally {
        setLoading(false);
      }
    });
  };

  form.onSubmit = onSubmit;

  return {
    form,
    error,
    loading,
    setError,
    errors: form.errors || [],
    isValid: form.isValid() && Object.keys(form.errors).length === 0,
  };
};

export default useFormHandler;
