import { useContext } from 'react';
import { Outlet, useLocation, Navigate } from 'react-router-dom';

import routes from 'utils/routes';
import AuthContext from 'context/AuthContext';
import { Center, Loader, Stack } from '@mantine/core';

const GuestRoute = () => {
  const {
    data: { userFetched },
    actions: { isLoggedIn },
  } = useContext(AuthContext);
  const location = useLocation();

  if (!userFetched) {
    return (
      <Center h="100vh">
        <Stack align="center">
          <Loader size="md" />
        </Stack>
      </Center>
    );
  }

  const isAuthenticated = isLoggedIn();
  const isResetPassword = location.pathname === routes.resetPasswordPath.href;
  const isVerifyAdmin = location.pathname === routes.verifyAdminPath.href;
  const isLogout = location.pathname === routes.logoutPath.href;

  if (isAuthenticated && !isResetPassword && !isVerifyAdmin && !isLogout) {
    return <Navigate to={routes.attendantPath.href} replace />;
  }

  if (!isAuthenticated && isLogout) {
    return <Navigate to={routes.signInPath.href} replace />;
  }

  return <Outlet />;
};

export default GuestRoute;
