import {
  Flex,
  Box,
  Divider,
  Grid,
  Popover,
  Text,
  UnstyledButton,
} from '@mantine/core';
import { useContext } from 'react';
import { useDisclosure } from '@mantine/hooks';

import Form from 'components/form';
import { CardList } from './CardList';
import AuthContext from 'context/AuthContext';
import { AccessLevel } from 'interfaces/GetUserResult';
import { IconAlertOctagon } from '@tabler/icons-react';

const TrainingForm = () => {
  const {
    data: { account },
  } = useContext(AuthContext);

  const isPremium = account?.access_level === AccessLevel.PRO;

  const [popoverOpened, { open: openPopover, close: closePopover }] =
    useDisclosure(false);

  return (
    <Box px="lg">
      <Grid gutter={20}>
        <Grid.Col span={{ lg: 10, base: 12 }}>
          <Form.Input
            required
            showEdit
            name="name"
            label="Business name"
            inputWrapperOrder={['label', 'input', 'description', 'error']}
            description="This is the name your customers will hear when they call."
          />
        </Grid.Col>

        <Grid.Col span={{ lg: 10, base: 12 }}>
          <Flex gap="sm" justify="flex-start" align="center" mb="lg">
            <Text size="md" fw={500}>
              Greetings
            </Text>
            {!isPremium && (
              <Popover
                radius="md"
                width={250}
                position="top"
                withArrow
                opened={popoverOpened}
              >
                <Popover.Target>
                  <UnstyledButton
                    size={22}
                    onMouseEnter={openPopover}
                    onMouseLeave={closePopover}
                    variant="transparent"
                    color="var(--text-color)"
                  >
                    <IconAlertOctagon size={18} stroke={1.75} />
                  </UnstyledButton>
                </Popover.Target>
                <Popover.Dropdown>
                  <Text size="sm" px={4} py={6}>
                    Free accounts may include a Nucleus branding message at the
                    start or end of calls
                  </Text>
                </Popover.Dropdown>
              </Popover>
            )}
          </Flex>

          <Form.Textarea
            required
            showEdit
            autosize
            name="welcome_message"
            label="Welcome message"
          />
        </Grid.Col>

        <Grid.Col span={{ lg: 10, base: 12 }}>
          <Form.Textarea
            required
            showEdit
            autosize
            name="goodbye_message"
            label="Goodbye message"
          />
        </Grid.Col>
      </Grid>
      <Grid>
        <Grid.Col span={{ lg: 10, base: 12 }}>
          <Divider mt="lg" />
        </Grid.Col>
      </Grid>

      <CardList />
    </Box>
  );
};

export default TrainingForm;
