import { PhoneNumberUtil } from 'google-libphonenumber';

const phoneUtil = PhoneNumberUtil.getInstance();

const isValidPhoneForRegion = (val: string, region: string) => {
  return phoneUtil.isValidNumberForRegion(phoneUtil.parse(val, region), region);
};

const formatPhoneNumber = (val: string, region: string = 'US') => {
  const phoneNumber = phoneUtil.parse(val, region);
  return `+${phoneUtil.formatOutOfCountryCallingNumber(phoneNumber, region)}`;
};

export { isValidPhoneForRegion, formatPhoneNumber };
