import { useLocation, useSearchParams } from 'react-router-dom';

const useStripeUrlHelper = () => {
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const getRedirectRoute = () => {
    const currentPath = `${location.pathname}${location.search}`;
    return encodeURIComponent(currentPath);
  };

  const buildReturnUrl = () => {
    const redirectPath = searchParams.get('redirect');
    if (!redirectPath)
      return `${window.location.origin}/?sessionid={CHECKOUT_SESSION_ID}`;

    const decodedPath = decodeURIComponent(redirectPath);
    const hasParams = decodedPath.includes('?');
    const returnUrl = `${window.location.origin}${decodedPath}${hasParams ? '&' : '?'}sessionid={CHECKOUT_SESSION_ID}`;

    return returnUrl;
  };

  return { getRedirectRoute, buildReturnUrl };
};

export default useStripeUrlHelper;
