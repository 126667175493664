import { FC } from 'react';
import { Avatar } from '@mantine/core';
import { ITeamMember } from 'interfaces/Agent';
import { getInitials, mergeNames } from 'utils/string';

interface UserAvatarProps {
  user?: ITeamMember | null;
  title?: string;
  color?: string;
  size?: number;
}

const UserAvatar: FC<UserAvatarProps> = ({ user, title, color, size }) => {
  let initials = '';
  let name = '';

  if (title) {
    initials = title.charAt(0).toUpperCase();
    name = title;
  } else {
    initials = getInitials(user?.first_name, user?.last_name);
    name = mergeNames(user?.first_name, user?.last_name);
  }

  return (
    <Avatar
      name={name}
      size={size || 40}
      radius={size || 40}
      color={color ? color : initials ? 'initials' : undefined}
    >
      {initials}
    </Avatar>
  );
};

export default UserAvatar;
