import { FC } from 'react';
import { Paper, PaperProps } from '@mantine/core';

import classes from './ScrollSection.module.scss';

interface ScrollSectionProps extends PaperProps {
  children: React.ReactNode;
  withNav?: boolean;
  withsStickyHeader?: boolean;
}

const ScrollSection: FC<ScrollSectionProps> = ({
  children,
  withNav = true,
  withsStickyHeader = false,
  ...props
}) => {
  const offset = withNav ? 10 : 6.25;

  return (
    <Paper
      radius="md"
      withBorder
      pb={{ md: 'lg', base: 'md' }}
      pt={withsStickyHeader ? 0 : { md: 'lg', base: 'md' }}
      className={classes.scrollSection}
      pos={{ md: 'sticky', base: 'relative' }}
      h={{ md: `calc(100vh - ${offset}rem)`, base: 'unset' }}
      {...props}
    >
      {children}
    </Paper>
  );
};

export default ScrollSection;
