import { createContext, useContext, ReactNode, useState } from 'react';
import { UseFormReturnType } from '@mantine/form';

interface FormContextType {
  error?: any;
  success?: boolean;
  loading?: boolean;
  defaultValues?: any;
  recoveryState?: any;
  onSubmit?: () => void;
  setError?: (error: any) => void;
  setSuccess?: (value: boolean) => void;
  currentForm?: UseFormReturnType<any>;
  setLoading?: (loading: boolean) => void;
  setDefaultValues?: (defaultValues: any) => void;
  setRecoveryState?: (recoveryState: any) => void;
  setOnSubmit?: (handler: (() => void) | undefined) => void;
  setCurrentForm?: (form: UseFormReturnType<any> | undefined) => void;
}

const FormContext = createContext<FormContextType>({});

const FormComponentContext = createContext<UseFormReturnType<any> | null>(null);

export const useFormContext = () => useContext(FormContext);
export const useFormComponentContext = () => {
  const context = useContext(FormComponentContext);
  if (!context) {
    throw new Error('Form components must be used within a Form');
  }
  return context;
};

interface FormProviderProps {
  children: ReactNode;
}

interface FormComponentProviderProps {
  children: ReactNode;
  form: UseFormReturnType<any>;
}

export const FormProvider = ({ children }: FormProviderProps) => {
  const [currentForm, setCurrentForm] = useState<
    UseFormReturnType<any> | undefined
  >();
  const [onSubmit, setOnSubmit] = useState<(() => void) | undefined>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [defaultValues, setDefaultValues] = useState<any>(null);
  const [recoveryState, setRecoveryState] = useState<any>(null);

  return (
    <FormContext.Provider
      value={{
        error,
        success,
        loading,
        onSubmit,
        setError,
        setSuccess,
        setLoading,
        setOnSubmit,
        currentForm,
        defaultValues,
        recoveryState,
        setCurrentForm,
        setRecoveryState,
        setDefaultValues,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};

export const FormComponentProvider = ({
  children,
  form,
}: FormComponentProviderProps) => (
  <FormComponentContext.Provider value={form}>
    {children}
  </FormComponentContext.Provider>
);

export default FormContext;
