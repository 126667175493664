import AuthContext from 'context/AuthContext';
import { AccessLevel } from 'interfaces/GetUserResult';
import { FC, ReactNode, useState, useContext, useEffect } from 'react';
import {
  Popover,
  Button,
  Text,
  Stack,
  ButtonProps,
  Switch,
  Flex,
} from '@mantine/core';
import BillingContext from 'context/BillingContext';
import SwitchRadio from 'components/form/SwitchRadio';
import Slider from 'components/form/Slider';

export interface UpgradeWrapperProps extends ButtonProps {
  onOpen?: () => void;
  onClose?: () => void;
  children?: ReactNode;
  close?: boolean;
  upgradeTitle?: string;
  upgradeDescription?: string;
  requiresUpgrade?: boolean;
  maxWidth?: number | string;
  primaryActionProps?: ButtonProps & any;
  secondaryActionProps?: ButtonProps & any;
  as:
    | typeof Button
    | typeof SwitchRadio
    | typeof Slider
    | typeof Switch
    | typeof Flex;
  componentProps?: any;
}

const UpgradeWrapper: FC<UpgradeWrapperProps> = ({
  as,
  children,
  requiresUpgrade = true,
  upgradeTitle = 'Upgrade your plan',
  upgradeDescription = 'Unlock more possibilities by upgrading to our premium plan.',
  componentProps,
  onOpen,
  onClose,
  close = false,
  maxWidth = 260,
  primaryActionProps,
  secondaryActionProps,
  ...props
}) => {
  const [popoverOpened, openPopover] = useState(false);
  const {
    data: { account },
  } = useContext(AuthContext);
  const {
    actions: { setPricingOpened },
  } = useContext(BillingContext);

  const handleUpgrade = async () => {
    onClose?.();
    togglePopover();
    setPricingOpened(true);
  };

  const handleSecondaryAction = () => {
    onClose?.();
    togglePopover();
    secondaryActionProps?.onClick?.();
  };

  const togglePopover = () => {
    if (popoverOpened) {
      onClose?.();
    }
    openPopover((o) => !o);
  };

  useEffect(() => {
    if (close) {
      onClose?.();
      openPopover(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [close]);

  const checkAccess = (value: any) => {
    const isFree = account?.access_level === AccessLevel.FREE;

    if (isFree && requiresUpgrade) {
      onOpen?.();

      togglePopover();
    } else {
      componentProps?.onClick?.(value);
    }
  };

  const renderComponent = () => {
    const Component = as;

    if (Component === SwitchRadio || Component === Slider) {
      return (
        <Component
          {...(props as any)}
          {...componentProps}
          onChange={(value: any) => {
            const disabledOption = componentProps?.options.find(
              (option: any) => option.value === value
            );
            if (disabledOption?.disabled) {
              togglePopover();
            }
          }}
        >
          {children}
        </Component>
      );
    }
    return (
      <Component
        {...(props as any)}
        {...componentProps}
        onClick={(value: any) => checkAccess(value)}
      >
        {children}
      </Component>
    );
  };

  return (
    <>
      <Popover
        radius="md"
        opened={popoverOpened}
        onChange={openPopover}
        position="bottom-end"
      >
        <Popover.Target>{renderComponent()}</Popover.Target>
        <Popover.Dropdown>
          <Stack gap="sm" py="sm" maw={maxWidth}>
            <Text fw={600} size="md">
              {upgradeTitle || 'Upgrade your plan'}
            </Text>
            <Text size="xs" c="dimmed">
              {upgradeDescription ||
                'Unlock more possibilities by adding team members and accessing even greater benefits.'}
            </Text>
            <Flex w="100%" gap="sm">
              {secondaryActionProps && (
                <Button
                  size="xs"
                  mt="sm"
                  w="100%"
                  {...secondaryActionProps}
                  onClick={handleSecondaryAction}
                >
                  {secondaryActionProps.children}
                </Button>
              )}
              <Button
                size="xs"
                mt="sm"
                w="100%"
                color="var(--green-color)"
                onClick={handleUpgrade}
                {...primaryActionProps}
              >
                Upgrade now
              </Button>
            </Flex>
          </Stack>
        </Popover.Dropdown>
      </Popover>
    </>
  );
};

export default UpgradeWrapper;
