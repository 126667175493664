import { Alert } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { FormComponentProvider } from 'context/FormContext';
import { IconInfoCircle, IconExclamationCircle } from '@tabler/icons-react';

import Tags from './Tags';
import Input from './Input';
import Select from './Select';
import Submit from './Submit';
import Number from './Number';
import Slider from './Slider';
import Switch from './Switch';
import UrlInput from './UrlInput';
import Checkbox from './Checkbox';
import Textarea from './Textarea';
import Timezone from './Timezone';
import PhoneInput from './PhoneInput';
import SwitchRadio from './SwitchRadio';
import Autocomplete from './Autocomplete';
import Password, { PasswordCheck } from './Password';

interface FormProps {
  error?: string | null;
  success?: string | null;
  children: React.ReactNode;
  form: UseFormReturnType<any>;
  onSubmit: (values: any) => Promise<void>;
}

const Form = ({ form, error, onSubmit, children, success }: FormProps) => {
  return (
    <FormComponentProvider form={form}>
      <form onSubmit={form.onSubmit(onSubmit)}>
        {error && (
          <Alert
            p="sm"
            mb="lg"
            color="red"
            radius="md"
            icon={<IconExclamationCircle size={20} />}
          >
            {error}
          </Alert>
        )}
        {success && (
          <Alert
            p="sm"
            mb="lg"
            radius="md"
            color="green"
            variant="light"
            icon={<IconInfoCircle size={20} />}
          >
            {success}
          </Alert>
        )}
        {children}
      </form>
    </FormComponentProvider>
  );
};

Form.Tags = Tags;
Form.Input = Input;
Form.Select = Select;
Form.Number = Number;
Form.Submit = Submit;
Form.Slider = Slider;
Form.Switch = Switch;
Form.Password = Password;
Form.UrlInput = UrlInput;
Form.Checkbox = Checkbox;
Form.Textarea = Textarea;
Form.Timezone = Timezone;
Form.PhoneInput = PhoneInput;
Form.SwitchRadio = SwitchRadio;
Form.Autocomplete = Autocomplete;
Form.PasswordCheck = PasswordCheck;

export default Form;
