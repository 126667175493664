import { jwtDecode } from 'jwt-decode';
import {
  createContext,
  useEffect,
  useState,
  ReactElement,
  ReactNode,
} from 'react';

import {
  STORAGE_AUTH_DATA,
  STORAGE_NEW_SIGNUP,
  STORAGE_STRIPE_DATA,
  STORAGE_COMPANY_DATA,
  STORAGE_ACCOUNT_DATA,
  STORAGE_REGISTER_EMAIL,
  STORAGE_REGISTER_PASSWORD,
  STORAGE_CURRENT_FORM,
} from 'constants/localStorage';
import {
  IStripe,
  IAccount,
  ICompany,
  AccessLevel,
  IGetUserResult,
} from 'interfaces/GetUserResult';
import ApiService from 'services/api';
import { IAuthUser } from 'interfaces/AuthUser';
import useLocalStorage from 'hooks/useLocalStorage';
import { DEFAULT_COUNTRY } from 'constants/company';
import { ICompanyWithWebsite } from 'interfaces/Business';
import { ICompanyFormInput } from 'interfaces/CompanyFormInput';

interface IAuthContext {
  actions: {
    login: (username: string, password: string) => {};
    loginToken: (token: string) => {};
    logout: () => void;
    signup: (username: string, password: string, referer?: string) => {};
    signupWithWebsite: (company: ICompanyWithWebsite) => {};
    updateAccount: (
      username: string,
      password?: string,
      referer?: string
    ) => {};
    deleteAccount: () => {};
    forgotPassword: (email: string) => {};
    resetPassword: (password: string, token: string) => {};
    isLoggedIn: (t?: IAuthUser | null | undefined) => boolean;
    expireAt: (authToken: IAuthUser | null | undefined) => number;
    getUser: (t?: IAuthUser) => {};
    updateUser: (data: ICompanyFormInput) => Promise<IGetUserResult>;
    setTokenResponse: (data: IAuthUser) => void;
    setRegisterEmail: (email: string) => void;
    setRegisterPassword: (password: string) => void;
    setNewSignup: (newSignup: { website: string } | null) => void;
    setAccount: (account: IAccount) => void;
    checkAvailableEmail: (email: string) => Promise<boolean>;
  };
  data: {
    userFetched: boolean;
    stripe: IStripe | null;
    account: IAccount | null;
    company: ICompany | null;
    registerEmail: string | null;
    registerPassword: string | null;
    authToken: IAuthUser | null | undefined;
    newSignup: { website: string } | null;
  };
}

interface IAuthProvider {
  children: ReactNode;
}

const AuthContext = createContext<IAuthContext>({
  actions: {
    login: async () => undefined,
    loginToken: async () => undefined,
    logout: () => undefined,
    signup: async () => undefined,
    signupWithWebsite: async () => undefined,
    updateAccount: async () => undefined,
    deleteAccount: async () => undefined,
    forgotPassword: async () => undefined,
    resetPassword: async () => undefined,
    isLoggedIn: (t?: IAuthUser | null | undefined) => false,
    expireAt: (_) => -1,
    getUser: async () => undefined,
    updateUser: async () => ({
      account: {
        id: -1,
        username: '',
        access_level: AccessLevel.GUEST,
        has_password: false,
        verified: false,
        created_on: 0,
      },
      company: {
        id: -1,
        did: '',
        name: '',
        voice: '',
        users: [],
        cards: [],
      },
    }),
    setTokenResponse: () => undefined,
    setRegisterEmail: () => undefined,
    setRegisterPassword: () => undefined,
    setNewSignup: () => undefined,
    setAccount: () => undefined,
    checkAvailableEmail: () => Promise.resolve(false),
  },
  data: {
    stripe: null,
    account: null,
    company: null,
    newSignup: null,
    userFetched: false,
    authToken: undefined,
    registerEmail: null,
    registerPassword: null,
  },
});

const checkToken = (token?: IAuthUser | null | undefined) => {
  if (!token) return false;

  const exp = expireAt(token);
  if (exp < 0 || Date.now() >= exp) {
    return false;
  }
  return true;
};

const expireAt = (token: IAuthUser | null | undefined) => {
  if (token) {
    try {
      const decoded = jwtDecode(token.access_token);
      if (decoded && decoded.exp) return decoded.exp * 1000;
    } catch (e) {
      console.log(e);
    }
  }
  return -1;
};

const clearAuthStorage = () => {
  localStorage.removeItem(STORAGE_AUTH_DATA);
  localStorage.removeItem(STORAGE_STRIPE_DATA);
  localStorage.removeItem(STORAGE_ACCOUNT_DATA);
  localStorage.removeItem(STORAGE_COMPANY_DATA);
};

const loadToken = () => {
  const json = localStorage.getItem(STORAGE_AUTH_DATA);

  if (json) {
    const token = JSON.parse(json);
    if (checkToken(token)) return token;
  }

  clearAuthStorage();
  return null;
};

export function AuthProvider(props: IAuthProvider): ReactElement {
  const { children } = props;
  const [authToken, setAuthToken] = useState<IAuthUser | null>(() =>
    loadToken()
  );
  const [account, setAccount] = useLocalStorage<IAccount | null>(
    STORAGE_ACCOUNT_DATA,
    null
  );
  const [company, setCompany] = useLocalStorage<ICompany | null>(
    STORAGE_COMPANY_DATA,
    null
  );
  const [stripe, setStripe] = useLocalStorage<IStripe | null>(
    STORAGE_STRIPE_DATA,
    null
  );
  const [registerEmail, setRegisterEmail] = useLocalStorage<string | null>(
    STORAGE_REGISTER_EMAIL,
    null
  );
  const [registerPassword, setRegisterPassword] = useLocalStorage<
    string | null
  >(STORAGE_REGISTER_PASSWORD, null);
  const [newSignup, setNewSignup] = useLocalStorage<{ website: string } | null>(
    STORAGE_NEW_SIGNUP,
    null
  );
  const [userFetched, setUserFetched] = useState<boolean>(false);

  const isLoggedIn = (t?: IAuthUser | null | undefined) => {
    const token = t ? t : authToken;
    return checkToken(token);
  };

  const logout = () => {
    setAuthToken(null);
    setAccount(null);
    setCompany(null);
    setStripe(null);
    localStorage.clear();
  };

  const setUserResult = (data: IGetUserResult) => {
    setAccount(data.account);

    const newCompany = data.company;
    newCompany.cards = data.company.cards
      .map((card) => ({ ...card }))
      .sort((a, b) => a.sort_order - b.sort_order);

    setCompany(newCompany);
    setUserFetched(true);

    const newStripe = data.stripe;
    setStripe(newStripe);
  };

  const getUser = async (t?: IAuthUser) => {
    const token = t ? t : authToken;

    if (!isLoggedIn(token)) {
      setUserFetched(true);
      throw new Error('You are not logged in. Please log in and try again');
    }
    try {
      const response = await ApiService.get('/user', {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${token?.access_token}`,
        },
      });

      setUserResult(response.data);
      return response.data;
    } catch (e: any) {
      throw e;
    }
  };

  const setTokenResponse = async (data: IAuthUser) => {
    const token: IAuthUser = data;
    await getUser(token);
    setAuthToken(token);
    localStorage.setItem(STORAGE_AUTH_DATA, JSON.stringify(token));
  };

  const login = async (username: string, password: string) => {
    try {
      const response = await ApiService.post(
        'login',
        {
          username,
          password,
        },
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      );
      const token = response.data;
      await setTokenResponse(token);
      setRegisterEmail('');
      setRegisterPassword('');
      return token;
    } catch (e) {
      logout();
      throw e;
    }
  };

  const signup = async (
    username: string,
    password: string,
    referer?: string
  ) => {
    try {
      const response = await ApiService.post('account', {
        username,
        password,
        referer,
      });
      const token: IAuthUser = response.data;
      await setTokenResponse(token);
      return token;
    } catch (e: any) {
      throw e;
    }
  };

  const signupWithWebsite = async (company: ICompanyWithWebsite) => {
    const validCompany = { ...company };

    if (!validCompany.location.country) {
      validCompany.location.country = DEFAULT_COUNTRY;
    }

    try {
      const response = await ApiService.post('user/website', validCompany);
      const token: IAuthUser = response.data;
      await setTokenResponse(token);
      setRegisterEmail('');
      setRegisterPassword('');
      setNewSignup({ website: validCompany.website });
      return token;
    } catch (e: any) {
      throw e;
    }
  };

  const updateAccount = async (
    username: string,
    password?: string,
    referer?: string
  ) => {
    if (!isLoggedIn()) {
      throw new Error('You are not logged in. Please log in and try again');
    }
    try {
      const response = await ApiService.put(
        'account',
        { username, password, referer },
        {
          headers: {
            Authorization: `Bearer ${authToken?.access_token}`,
          },
        }
      );
      await setTokenResponse(response.data);
      return response;
    } catch (e: any) {
      throw e;
    }
  };

  const deleteAccount = async () => {
    if (!isLoggedIn()) {
      throw new Error('You are not logged in. Please log in and try again');
    }
    try {
      await ApiService.delete('account', {
        headers: {
          Authorization: `Bearer ${authToken?.access_token}`,
        },
      });
      logout();
    } catch (e: any) {
      throw e;
    }
  };

  const checkAvailableEmail = async (email: string) => {
    try {
      const response = await ApiService.get('account/available', {
        params: { email },
      });
      return response.data?.available;
    } catch (e: any) {
      throw e;
    }
  };

  const forgotPassword = async (email: string) => {
    try {
      const response = await ApiService.post('forgot-password', { email });

      return response;
    } catch (e: any) {
      throw e;
    }
  };

  const resetPassword = async (password: string, token: string) => {
    try {
      const response = await ApiService.post('reset-password', {
        new_password: password,
        token,
      });

      await setTokenResponse(response.data);
      return response;
    } catch (e: any) {
      throw e;
    }
  };

  const updateUser = async (data: ICompanyFormInput) => {
    if (!isLoggedIn()) {
      throw new Error('You are not logged in. Please log in and try again');
    }
    try {
      const response = await ApiService.put(
        'user',
        { ...data },
        {
          headers: {
            Authorization: `Bearer ${authToken?.access_token}`,
          },
        }
      );
      setUserResult(response.data);
      return response.data;
    } catch (e: any) {
      throw e;
    }
  };

  const loginToken = async (access_token: string) => {
    const token: IAuthUser = {
      access_token: access_token,
      token_type: 'bearer',
    };

    logout();

    if (checkToken(token)) {
      await setTokenResponse(token);
    }
  };

  useEffect(() => {
    if (isLoggedIn()) {
      getUser();
    } else {
      setUserFetched(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContext.Provider
      value={{
        actions: {
          login,
          logout,
          signup,
          getUser,
          expireAt,
          updateUser,
          setAccount,
          isLoggedIn,
          loginToken,
          setNewSignup,
          updateAccount,
          resetPassword,
          deleteAccount,
          forgotPassword,
          setRegisterEmail,
          setTokenResponse,
          signupWithWebsite,
          checkAvailableEmail,
          setRegisterPassword,
        },
        data: {
          stripe,
          account,
          company,
          authToken,
          newSignup,
          userFetched,
          registerEmail,
          registerPassword,
        },
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export default AuthContext;
