import { z } from 'zod';
import { MINIMUM_PASSWORD_LENGTH } from 'components/form/Password';

const isValidPhone = (val: string) => {
  const northAmericanPhoneRegex = /^\+1[2-9]\d{9}$/;
  return northAmericanPhoneRegex.test(val);
};

export interface FieldConfig {
  required?: boolean;
  min?: number;
  max?: number;
  url?: boolean;
  email?: boolean;
  regex?: RegExp;
  message?: string;
  validate?: (val: string) => boolean;
}

const createSchemaField = (config: FieldConfig) => {
  let schema = z.string();

  if (config.required) {
    schema = schema.min(1, config.message);
  }

  if (config.email) {
    schema = schema.email('Invalid email address');
  }

  if (config.min) {
    schema = schema.min(
      config.min,
      config.message || `Must be at least ${config.min} characters`
    );
  }

  if (config.max) {
    schema = schema.max(
      config.max,
      `Must be less than ${config.max} characters`
    );
  }

  if (config.regex) {
    schema = schema.regex(config.regex, config.message || 'Invalid format');
  }

  if (config.url) {
    schema = schema.url(config.message);
  }

  if (config.validate) {
    return {
      schema: schema.refine((val) => config?.validate?.(val), {
        message: config?.message || 'Invalid phone number format',
      }),
      props: {
        required: config.required,
      },
    };
  }

  return {
    schema,
    props: {
      required: config.required,
    },
  };
};

// Define field configurations
const schemaFields = {
  email: createSchemaField({
    email: true,
    required: true,
    message: 'Invalid email address',
  }),

  password: createSchemaField({
    min: MINIMUM_PASSWORD_LENGTH,
    required: true,
    message: `Password must be at least ${MINIMUM_PASSWORD_LENGTH} characters`,
  }),

  fullName: createSchemaField({
    min: 2,
    max: 50,
    required: true,
    message: 'Invalid full name',
  }),

  phoneNumber: createSchemaField({
    required: true,
    validate: isValidPhone,
    message: 'Please enter a valid US or Canadian phone number',
  }),

  url: createSchemaField({
    url: true,
    message: 'Invalid URL',
  }),
};

export { createSchemaField, schemaFields };
