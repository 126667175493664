import '@mantine/core/styles.css'; // This must be at the top of the page

import { StrictMode } from 'react';
import { MantineProvider } from '@mantine/core';
import {
  Route,
  Outlet,
  RouterProvider,
  ScrollRestoration,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';

import theme from 'theme';
import routes from 'utils/routes';
import SigninPage from 'pages/signin';
import LogoutPage from 'pages/logout';
import WelcomePage from 'pages/welcome';
import BillingPage from 'pages/billing';
import UpgradePage from 'pages/upgrade';
import NotFoundPage from 'pages/notFound';
import RegisterPage from 'pages/register';
import AppLayout from 'layouts/appLayout';
import DashboardPage from 'pages/dashboard';
import AttendantPage from 'pages/attendant';
import ReportingPage from 'pages/reporting';
import OnboardingPage from 'pages/onboarding';
import VerifyAdminPage from 'pages/verifyAdmin';
import ResetPasswordPage from 'pages/resetPassword';
import GuestRoute from 'components/route/GuestRoute';
import ForgotPasswordPage from 'pages/forgotPassword';
import AdditionalDetailsPage from 'pages/additionalDetails';
import ProtectedRoute from 'components/route/ProtectedRoute';

import { FormProvider } from 'context/FormContext';
import { AuthProvider } from 'context/AuthContext';
import { SocketProvider } from 'context/SocketContext';
import { BillingProvider } from 'context/BillingContext';
import { BusinessProvider } from 'context/BusinessContext';
import { TrackingProvider } from 'context/TrackingContext';
import { NotificationProvider } from 'context/NotificationContext';

import 'styles/variables.scss';
import './App.scss';
import ErrorBoundary from 'components/ErrorBoundary';
import TrialUpgrade from 'pages/upgrade/TrialUpgrade';

const WrapperComponent = () => {
  return (
    <ErrorBoundary>
      <TrackingProvider>
        <AuthProvider>
          <NotificationProvider>
            <SocketProvider>
              <BillingProvider>
                <BusinessProvider>
                  <Outlet />
                  <ScrollRestoration />
                </BusinessProvider>
              </BillingProvider>
            </SocketProvider>
          </NotificationProvider>
        </AuthProvider>
      </TrackingProvider>
    </ErrorBoundary>
  );
};

const ProtectedRouteWrapper = () => {
  return (
    <FormProvider>
      <ProtectedRoute />
    </FormProvider>
  );
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<WrapperComponent />}>
      <Route element={<GuestRoute />}>
        <Route path={routes.signInPath.href} element={<SigninPage />} />
        <Route path={routes.registerPath.href} element={<RegisterPage />} />
        <Route path={routes.logoutPath.href} element={<LogoutPage />} />
        <Route
          path={routes.forgotPasswordPath.href}
          element={<ForgotPasswordPage />}
        />
        <Route
          path={routes.resetPasswordPath.href}
          element={<ResetPasswordPage />}
        />
        <Route
          path={routes.verifyAdminPath.href}
          element={<VerifyAdminPage />}
        />
      </Route>
      <Route element={<ProtectedRouteWrapper />}>
        <Route path={routes.onboardingPath.href} element={<OnboardingPage />} />
        <Route path={routes.welcomePath.href} element={<WelcomePage />} />
        <Route element={<AppLayout />}>
          <Route path={routes.dashboardPath.href} element={<DashboardPage />} />
          <Route path={routes.reportingPath.href} element={<ReportingPage />} />
          <Route path={routes.attendantPath.href} element={<AttendantPage />} />
          <Route
            path={routes.additionalDetailsPath.href}
            element={<AdditionalDetailsPage />}
          />
          <Route path={routes.billingPath.href} element={<BillingPage />} />
          <Route path={routes.upgradePath.href} element={<UpgradePage />} />
          <Route
            path={routes.trialUpgradePath.href}
            element={<TrialUpgrade />}
          />
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Route>
    </Route>
  )
);

function App() {
  return (
    <StrictMode>
      <MantineProvider theme={theme}>
        <RouterProvider router={router} />
      </MantineProvider>
    </StrictMode>
  );
}

export default App;
