const getNames = (fullName: string | undefined) => {
  if (!fullName) return { first_name: '', last_name: '' };

  const nameParts = fullName.trim().split(/\s+/);
  const first_name = nameParts[0];
  const last_name = nameParts.slice(1).join(' ') || '';

  return { first_name, last_name };
};

const getInitials = (
  firstName: string | undefined,
  lastName: string | undefined
) => {
  return `${(firstName || '').charAt(0)}${(lastName || '').charAt(0)}`
    .toUpperCase()
    .trim();
};

const mergeNames = (
  first_name: string | undefined,
  last_name: string | undefined
) => {
  if (!first_name && !last_name) return '';
  return `${first_name || ''} ${last_name || ''}`.trim();
};

const capitalize = (str: string) => {
  if (!str) return '';
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const formatNumber = (num: number): string => {
  return num.toLocaleString('en-US');
};

const capitalizeWords = (str: string): string => {
  return str
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

const pluralize = (str: string, count: number, plural?: string) => {
  return `${count} ${count > 1 ? plural || `${str}s` : str}`;
};

export {
  getNames,
  pluralize,
  mergeNames,
  capitalize,
  getInitials,
  formatNumber,
  capitalizeWords,
};
