import dayjs from 'dayjs';
import { useContext, useEffect, useState } from 'react';
import { Text, Flex, Alert, Button } from '@mantine/core';

import { pluralize } from 'utils/string';
import AuthContext from 'context/AuthContext';
import BillingContext from 'context/BillingContext';
import { AccessLevel } from 'interfaces/GetUserResult';
import useStripeTrial from 'hooks/useStripeTrial';

const TrialBanner = () => {
  const [trialTime, setTrialTime] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const { isOnTrial } = useStripeTrial();

  const {
    actions: { setPricingOpened },
  } = useContext(BillingContext);
  const {
    data: { stripe, account },
  } = useContext(AuthContext);

  const isPremium = account?.access_level === AccessLevel.PRO;

  const trialStart = dayjs.utc(stripe?.trial_start);
  const trialEnd = dayjs.utc(stripe?.trial_end);

  const getRemainingTime = () => {
    const now = dayjs().utc();
    const trialEnd = dayjs.utc(stripe?.trial_end);
    return trialEnd.diff(now, 'seconds') * 1000;
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (!isOnTrial) {
      return;
    }

    const checkTrialStatus = () => {
      if (!showAlert) {
        setShowAlert(true);
        return;
      }

      // Get remaining time in milliseconds
      const remainingTime = getRemainingTime();
      setTrialTime(remainingTime);

      if (remainingTime <= 0) {
        setShowAlert(true);
        return;
      }

      // Set dynamic timeout intervals
      if (isPremium && trialStart && trialEnd) {
        let interval = 24 * 60 * 60 * 1000; // Default to daily (24 hours)

        if (remainingTime < 60 * 1000) {
          // Less than 1 minute
          interval = 1000; // Check every second
        } else if (remainingTime < 60 * 60 * 1000) {
          // Less than 1 hour
          interval = 10 * 1000; // Check every 10 seconds
        } else if (remainingTime < 24 * 60 * 60 * 1000) {
          // Less than 24 hours
          interval = 10 * 60 * 1000; // Check every 10 minutes
        }

        timeout = setTimeout(checkTrialStatus, interval);
      }
    };

    checkTrialStatus();

    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPremium, stripe?.trial_end, trialEnd, trialStart]);

  const formatTime = () => {
    const days = Math.floor(trialTime / 86400000);
    const hours = Math.floor((trialTime % 86400000) / 3600000);
    const minutes = Math.floor((trialTime % 3600000) / 60000);
    const remainingSeconds = Math.floor((trialTime % 60000) / 1000);

    if (days > 0) {
      return pluralize('day', days);
    } else if (hours > 0) {
      return pluralize('hour', hours);
    } else if (minutes > 0) {
      return pluralize('minute', minutes);
    }
    return `${remainingSeconds} second${remainingSeconds > 1 ? 's' : ''}`;
  };

  if (!isOnTrial) {
    return null;
  }

  return (
    <Alert
      radius="md"
      variant="light"
      withCloseButton={trialTime <= 0}
      onClose={() => setShowAlert(false)}
      style={{ border: '1px solid', flex: 'auto' }}
      title={
        <Text fz="sm" fw={600}>
          {trialTime > 0
            ? `You have ${formatTime()} left in your Premium trial.`
            : 'Your Premium trial has expired.'}
        </Text>
      }
      styles={{
        title: {
          color: 'var(--text-color)',
        },
        closeButton: {
          top: 18,
          right: 14,
          position: 'absolute',
        },
      }}
    >
      <Flex mt="sm" align="stretch" gap="md" direction="column">
        <Text fz="sm" c="var(--mantine-color-gray-7)" visibleFrom="md">
          Click here to see all the benefits that come with your Premium
          account.
        </Text>
        <Button
          size="sm"
          radius="sm"
          miw="fit-content"
          color="var(--green-color)"
          onClick={() => setPricingOpened(true)}
        >
          See the features
        </Button>
      </Flex>
    </Alert>
  );
};

export default TrialBanner;
