import { FC } from 'react';
import { Loader, Text, TextInput, TextInputProps } from '@mantine/core';

import { useFormComponentContext } from 'context/FormContext';
import useEditStyles from '../../hooks/useEditStyles';

interface FormUrlInputProps extends TextInputProps {
  name: string;
  label?: string;
  error?: string;
  value?: string;
  prefix?: string;
  loading?: boolean;
  required?: boolean;
  showEdit?: boolean;
}

const FormUrlInput: FC<FormUrlInputProps> = ({
  name,
  label,
  prefix,
  loading,
  required,
  showEdit = false,
  ...props
}) => {
  const form = useFormComponentContext();
  const { editedInputStyles } = useEditStyles({ name, showEdit });
  const leftSection = <Text>{prefix || 'https://'}</Text>;

  const rightSection = loading ? <Loader size="14" color="#adb5bd" /> : null;

  return (
    <TextInput
      type="text"
      label={label}
      required={required}
      leftSectionWidth={70}
      placeholder="example.com"
      leftSection={leftSection}
      rightSection={rightSection}
      styles={{ ...editedInputStyles, ...props.styles }}
      {...form.getInputProps(name)}
      {...props}
    />
  );
};

export default FormUrlInput;
