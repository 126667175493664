import { FC } from 'react';
import {
  parseCountry,
  usePhoneInput,
  defaultCountries,
} from 'react-international-phone';
import { useFormComponentContext } from 'context/FormContext';
import { TextInput, TextInputProps, Text } from '@mantine/core';
import useEditStyles from 'hooks/useEditStyles';
import 'react-international-phone/style.css';

const countries = defaultCountries.filter((country) => {
  const { iso2 } = parseCountry(country);
  return ['us', 'ca'].includes(iso2);
});

interface FormPhoneInputProps extends Omit<TextInputProps, 'onChange'> {
  name: string;
  label?: string;
  value?: string;
  error?: string;
  showEdit?: boolean;
  required?: boolean;
  placeholder?: string;
  description?: string;
  onChange?: (value: string) => void;
}

const FormPhoneInput: FC<FormPhoneInputProps> = ({
  name,
  label,
  required,
  onChange,
  placeholder,
  description,
  showEdit = false,
  ...props
}) => {
  const form = useFormComponentContext();
  const { editedInputStyles } = useEditStyles({ name, showEdit });
  const leftSection = <Text size="sm">+1</Text>;

  const phoneInput = usePhoneInput({
    countries,
    defaultCountry: 'us',
    disableDialCodeAndPrefix: true,
    value: form.getInputProps(name).value,
    onChange: (data) => {
      form.getInputProps(name).onChange(data.phone);
    },
  });

  return (
    <TextInput
      type="tel"
      label={label}
      required={required}
      leftSectionWidth={40}
      description={description}
      leftSection={leftSection}
      ref={phoneInput.inputRef}
      placeholder="(123) 456-7890"
      styles={{ ...editedInputStyles, ...props.styles }}
      {...form.getInputProps(name)}
      {...props}
      value={phoneInput.inputValue}
      onChange={phoneInput.handlePhoneValueChange}
    />
  );
};

export default FormPhoneInput;
