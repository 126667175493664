import { Crisp } from 'crisp-sdk-web';
import { useContext, useEffect } from 'react';
import AuthContext from '../../context/AuthContext';

const CrispChat = () => {
  const {
    data: { account },
  } = useContext(AuthContext);
  const accountId = account?.id;
  const email = account?.username;

  const loadCrisp = async () => {
    const crispId: string | undefined = process.env.REACT_APP_CRISP_WEBSITE_ID;
    if (crispId) {
      Crisp.configure(crispId);
    }
  };

  useEffect(() => {
    loadCrisp();
  }, []);

  useEffect(() => {
    Crisp.user.setEmail(email || '');
    Crisp.session.setData({
      user_id: accountId,
    });
  }, [accountId, email]);

  return <></>;
};

export default CrispChat;
