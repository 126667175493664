import { Stack, Text, Title, Paper } from '@mantine/core';

interface ComingSoonProps {
  image: string;
  title: string;
  description: string;
  features: string[];
  stayTuned?: string;
}

const ComingSoon = ({
  image,
  title,
  description,
  features,
  stayTuned,
}: ComingSoonProps) => {
  return (
    <div className="relative">
      <div className="inset-0 flex items-center justify-center p-4">
        <img alt="" src={image} className="w-full object-cover opacity-[0.2]" />
      </div>
      <div className="absolute top-0 z-10 w-full h-[calc(100vh-2rem)] lg:h-[calc(100vh-10rem)] flex items-center justify-center backdrop-blur-[2px]">
        <Paper shadow="md" radius="md" p="xl" w="55rem" maw="100%">
          <Stack>
            <div className="flex items-center gap-3">
              <Title fz={{ base: 'h4', lg: 'h1' }} className="text-[#1A1B1E]">
                {title}
              </Title>
            </div>
            <Text size="sm" opacity={0.7} mt="sm">
              {description}
            </Text>
            <Stack gap="sm" mt="sm">
              {features.map((feature, index) => (
                <div key={index} className="flex items-start gap-3">
                  <div className="w-1.5 h-1.5 rounded-full bg-[var(--text-color)] mt-2.5" />
                  <Text size="sm" className="flex-1">
                    {feature}
                  </Text>
                </div>
              ))}
            </Stack>
            {stayTuned && (
              <Text size="sm" mt="md">
                {stayTuned}
              </Text>
            )}
          </Stack>
        </Paper>
      </div>
    </div>
  );
};

export default ComingSoon;
