import { useContext, useEffect } from 'react';
import AuthContext from 'context/AuthContext';
import { Flex, Loader } from '@mantine/core';
import ScrollSection from 'components/ScrollSection';
import BillingContext, { PRO_YEAR_PRICE_NAME } from 'context/BillingContext';
import { useSearchParams } from 'react-router-dom';
import useStripeUrlHelper from 'hooks/useStripeUrlHelper';

const TrialUpgrade = () => {
  const [searchParams] = useSearchParams();
  const { buildReturnUrl } = useStripeUrlHelper();
  const {
    actions: { isLoggedIn },
  } = useContext(AuthContext);

  const {
    actions: { createStoreUpgradeSession },
  } = useContext(BillingContext);

  const loadStoreUrl = async () => {
    const promo_code = searchParams.get('promo_code') || undefined;
    const lookup_key = searchParams.get('lookup_key') || PRO_YEAR_PRICE_NAME;
    const url = await createStoreUpgradeSession({
      promo_code,
      lookup_key,
      return_url: buildReturnUrl(),
    });
    window.open(url, '_self');
  };

  useEffect(() => {
    if (isLoggedIn()) loadStoreUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  return (
    <ScrollSection withNav={false}>
      <Flex align="center" justify="center" h="100%">
        <Loader size="md" />
      </Flex>
    </ScrollSection>
  );
};

export default TrialUpgrade;
