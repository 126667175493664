import { FC } from 'react';
import { Textarea, TextareaProps } from '@mantine/core';

import { useFormComponentContext } from 'context/FormContext';
import useEditStyles from 'hooks/useEditStyles';

interface FormTextareaProps extends Omit<TextareaProps, 'onChange'> {
  name: string;
  label?: string;
  error?: string;
  value?: string;
  required?: boolean;
  showEdit?: boolean;
  placeholder?: string;
  description?: string;
  onChange?: (value: string) => void;
}

const FormTextarea: FC<FormTextareaProps> = ({
  name,
  label,
  required,
  onChange,
  placeholder,
  description,
  showEdit = false,
  ...props
}) => {
  const form = useFormComponentContext();
  const { editedInputStyles } = useEditStyles({ name, showEdit });

  return (
    <Textarea
      label={label}
      required={required}
      placeholder={placeholder}
      description={description}
      styles={{ ...editedInputStyles, ...props.styles }}
      {...form.getInputProps(name)}
      {...props}
      onChange={(e) => {
        onChange?.(e.target.value);
        form.getInputProps(name).onChange(e);
      }}
    />
  );
};

export default FormTextarea;
