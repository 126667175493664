import { useState, useEffect, useContext } from 'react';
import { Outlet, useBlocker } from 'react-router-dom';
import { useDisclosure } from '@mantine/hooks';
import { AppShell, Burger, Container, Group } from '@mantine/core';

import Header from './header';
import SideNav from './sideNav';
import AuthContext from 'context/AuthContext';
import CrispChat from 'components/crisp/crisp';
import { useFormContext } from 'context/FormContext';
import NavBlockerModal from 'components/modal/NavBlockerModal';
import SessionTimeoutModal from 'components/modal/SessionTimeoutModal';

import classes from './index.module.scss';
import { UpgradePricing } from 'components/upgrade';

const AppLayout = () => {
  const [opened, { toggle, close }] = useDisclosure();
  const { currentForm, defaultValues } = useFormContext();
  const [showSessionModal, setShowSessionModal] = useState(false);
  const {
    actions: { isLoggedIn },
  } = useContext(AuthContext);

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      isLoggedIn() &&
      !!currentForm?.isDirty() &&
      currentLocation.pathname !== nextLocation.pathname
  );

  const onOk = () => {
    if (blocker && blocker.proceed) blocker.proceed();
    currentForm?.setValues(defaultValues);
    currentForm?.resetDirty();
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined = undefined;

    timeout = setInterval(() => {
      if (!isLoggedIn() && !showSessionModal) setShowSessionModal(true);
    }, 60 * 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [isLoggedIn, showSessionModal]);

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (isLoggedIn() && !!currentForm?.isDirty()) {
        // Show browser's default "Leave Site?" dialog
        e.preventDefault();
        e.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentForm?.isDirty()]);

  return (
    <AppShell
      layout="alt"
      className={classes.appShell}
      navbar={{ width: 300, breakpoint: 'sm', collapsed: { mobile: true } }}
      padding={{ base: 'md', sm: 'xl' }}
    >
      <AppShell.Navbar className={classes.navbar}>
        <SideNav opened={opened} toggle={toggle} close={close} />
      </AppShell.Navbar>
      <AppShell.Main className={classes.main}>
        <Group>
          <Burger opened={opened} onClick={toggle} hiddenFrom="sm" size="sm" />
          <Header />
        </Group>
        <Container maw="100%" mt="lg" p={0}>
          <Outlet />
        </Container>
      </AppShell.Main>
      <CrispChat />
      <SessionTimeoutModal
        isOpen={showSessionModal}
        onClose={() => setShowSessionModal(false)}
      />
      <NavBlockerModal
        isOpen={blocker.state === 'blocked'}
        onClose={() => {}}
        onOk={onOk}
        onCancel={() => {
          if (blocker && blocker.reset) blocker.reset();
        }}
      />
      <UpgradePricing />
    </AppShell>
  );
};

export default AppLayout;
