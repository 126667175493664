import { Text, Group } from '@mantine/core';
import { useMemo } from 'react';
import { getRandomTestimonial } from 'constants/testimonials';

const Testimonial = () => {
  const testimonial = useMemo(() => getRandomTestimonial(), []);
  const { name, title, image, quote } = testimonial;

  return (
    <div
      className="w-[80%] 3xl:w-[540px] mx-auto mb-16"
      style={{ minHeight: '200px' }}
    >
      <img src="/images/quote.svg" alt="Quotation mark icon" className="mb-2" />
      <Text size="lg" className="custom-text-color leading-[1.7]">
        <span className="opacity-80 font-medium">{quote}</span>
      </Text>
      <Group align="center" gap="md" mt="xl">
        <img
          src={image}
          alt={`${name}'s avatar`}
          className="w-12 h-12 rounded-full object-cover border border-[#5E6AB2]"
        />
        <div>
          <Text fw={500} className="custom-text-color">
            {name}
          </Text>
          <Text size="sm" className="custom-text-color opacity-70">
            {title}
          </Text>
        </div>
      </Group>
    </div>
  );
};

export default Testimonial;
