import { useMediaQuery } from '@mantine/hooks';

export const useDeviceType = () => {
  const isMobile = useMediaQuery('(max-width: 47.99em)'); // < 48em (sm)
  const isTablet = useMediaQuery('(max-width: 61.99em)'); // 48em-62em (sm-md)
  const isSmallDesktop = useMediaQuery('(max-width: 74.99em)'); // 62em-75em (md-lg)
  const isDesktop = useMediaQuery('(min-width: 75em)'); // >= 75em (lg+)

  return {
    isMobile,
    isTablet,
    isSmallDesktop,
    isDesktop,
  };
};
