import { createTheme, rem } from '@mantine/core';

const tagsTheme = {
  styles: {
    root: {
      marginBottom: rem(20),
    },
    input: {
      fontSize: rem(14),
      paddingTop: rem(9.8),
      paddingBottom: rem(9.8),
    },
    error: {
      fontSize: rem(12),
      marginTop: rem(4),
      color: 'var(--mantine-color-red-6)',
      borderColor: 'var(--mantine-color-red-6)',
    },
    description: {
      fontSize: rem(12),
      marginTop: rem(6),
    },
  },
};

const inputTheme = {
  defaultProps: {
    size: 'md',
  },
  styles: {
    root: {
      marginBottom: rem(20),
    },
    label: {
      fontSize: rem(14),
      marginBottom: rem(6),
    },
    input: {
      height: rem(44),
      fontSize: rem(14),
      borderRadius: rem(6),
      backgroundColor: 'white',
      transition: 'all 0.2s ease',

      '&:hover': {
        borderColor: '#5E6AB2',
      },

      '&:focus': {
        borderColor: '#263BB6',
        boxShadow: '0 0 0 1px #263BB6',
      },
    },
    option: {
      fontSize: rem(14),
    },
    dropdown: {
      padding: `${rem(4)} 0`,
    },
    error: {
      fontSize: rem(12),
      marginTop: rem(4),
      color: 'var(--mantine-color-red-6)',
      borderColor: 'var(--mantine-color-red-6)',
    },
    description: {
      fontSize: rem(12),
      marginTop: rem(6),
    },
  },
};

const anchorTheme = {
  styles: {
    root: {
      color: '#1A1A1A',
      textDecoration: 'none',
      borderBottom: '1px solid #1A1A1A',
      transition: 'all 0.2s ease',

      '&:hover': {
        textDecoration: 'none',
        opacity: 0.8,
      },
    },
  },
};

const checkboxTheme = {
  styles: {
    label: {
      fontSize: rem(14),
      color: '#1A1A1A',
      opacity: 0.7,
      cursor: 'pointer',

      a: {
        color: '#1A1A1A',
        borderBottom: '1px solid #1A1A1A',
        textDecoration: 'none',
        transition: 'opacity 0.2s ease',

        '&:hover': {
          textDecoration: 'none',
          opacity: 0.8,
        },
      },
    },
    input: {
      cursor: 'pointer',
      width: rem(20),
      height: rem(20),
      borderRadius: rem(4),
      border: '1px solid #E5E7EB',
      transition: 'all 0.2s ease',

      '&:hover': {
        borderColor: '#5E6AB2',
      },

      '&:checked': {
        backgroundColor: '#5E6AB2',
        borderColor: '#5E6AB2',
      },
    },
  },
};

const notificationTheme = {
  styles: {
    root: {
      padding: `${rem(16)} ${rem(40)} ${rem(16)} ${rem(16)}`,
      minWidth: rem(400),
      position: 'relative',
    },
    title: {
      marginBottom: rem(4),
      fontSize: rem(14),
      paddingRight: rem(24),
    },
    description: {
      fontSize: rem(14),
      lineHeight: 1.5,
    },
    closeButton: {
      position: 'absolute',
      top: rem(12),
      right: rem(12),
      padding: rem(4),
    },
  },
};

export default createTheme({
  cursorType: 'pointer',
  fontFamily: 'Roboto, sans-serif',
  fontFamilyMonospace: 'Monaco, Courier, monospace',
  headings: { fontFamily: 'Roboto, sans-serif' },
  primaryColor: 'purple',
  defaultRadius: 'sm',
  colors: {
    purple: [
      '#F5F6FA', // 0 - Lightest
      '#E1E3F0', // 1
      '#B6BBD9', // 2
      '#8990C2', // 3
      '#7278B9', // 4
      '#4B559E', // 5
      '#5E6AB2', // 6 - Primary (moved here)
      '#3A428A', // 7
      '#2A3075', // 8
      '#1B1F61', // 9 - Darkest
    ],
    error: [
      '#FFF5F5',
      '#FFE3E3',
      '#FFC9C9',
      '#FFA8A8',
      '#FF8787',
      '#FF6B6B',
      '#FA5252', // This is var(--mantine-color-red-6)
      '#F03E3E',
      '#E03131',
      '#C92A2A',
    ],
  },
  shadows: {
    md: '1px 1px 3px rgba(0, 0, 0, .25)',
    xl: '5px 5px 3px rgba(0, 0, 0, .25)',
  },
  spacing: {
    xs: rem(4),
    sm: rem(8),
    md: rem(16),
    lg: rem(24),
    xl: rem(32),
  },
  breakpoints: {
    xs: '36em',
    sm: '48em',
    md: '62em',
    lg: '75em',
    xl: '88em',
  },
  radius: {
    xxs: rem(1),
    xs: rem(2),
    xsm: rem(3),
    sm: rem(4),
    md: rem(8),
    xlg: rem(12),
    lg: rem(16),
    xl: rem(32),
    '2xl': rem(40),
    '3xl': rem(48),
    circle: '50%',
    none: '0',
  },
  components: {
    Button: {
      defaultProps: {
        size: 'md',
      },
      styles: () => ({
        root: {
          '&:disabled, &[dataDisabled]': {
            backgroundColor: '#F5F5F5',
            borderColor: '#E0E0E0',
            color: '#A0A0A0',
          },
        },
      }),
    },
    TextInput: inputTheme,
    PasswordInput: inputTheme,
    Select: inputTheme,
    Autocomplete: inputTheme,
    NumberInput: inputTheme,
    Textarea: inputTheme,
    TagsInput: tagsTheme,
    Checkbox: checkboxTheme,
    Anchor: anchorTheme,
    Notification: notificationTheme,
  },
});
