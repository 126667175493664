import {
  IconHeadset,
  IconChartBar,
  IconLayoutDashboard,
} from '@tabler/icons-react';
import { MouseEventHandler } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Group, Drawer, Burger, ScrollArea } from '@mantine/core';

import routes from 'utils/routes';
import Navlink from './NavbarLink';
import UserButton from './UserButton';
import TrialBanner from './TrialBanner';
import UserProfile from '../header/UserProfile';
import { NewtCloudLogo } from 'img/NewtCloudLogo';

import classes from './index.module.scss';

const navLinks = [
  {
    icon: IconLayoutDashboard,
    link: routes.dashboardPath.href,
    label: 'Dashboard (Coming Soon)',
  },
  {
    icon: IconHeadset,
    link: routes.attendantPath.href,
    label: routes.attendantPath.title,
  },
  {
    icon: IconChartBar,
    link: routes.reportingPath.href,
    label: 'Reporting (Coming Soon)',
  },
];

interface SideNavProps {
  opened: boolean;
  close: () => void;
  toggle: MouseEventHandler<HTMLButtonElement>;
}

const SideNav = ({ opened, toggle, close }: SideNavProps) => {
  const links = navLinks.map((item) => (
    <Navlink {...item} key={item.label} closeNav={close} />
  ));

  return (
    <>
      <div className={classes.navbarContainer}>
        <div className={classes.header}>
          <RouterLink to={routes.dashboardPath.href}>
            <div className={classes.logo}>
              <NewtCloudLogo />
            </div>
          </RouterLink>
        </div>

        <ScrollArea className={classes.links}>
          <div className={classes.linksInner}>{links}</div>
        </ScrollArea>

        <div className={classes.footer}>
          <TrialBanner />
          <UserButton />
        </div>
      </div>

      <Drawer
        size="100%"
        padding={0}
        opened={opened}
        onClose={close}
        hiddenFrom="sm"
        withCloseButton={false}
      >
        <div className={classes.navbarContainer}>
          <div className={classes.header}>
            <Group>
              <Burger
                size="sm"
                hiddenFrom="sm"
                opened={opened}
                onClick={toggle}
              />
              <RouterLink to={routes.dashboardPath.href}>
                <div className={classes.logo}>
                  <NewtCloudLogo />
                </div>
              </RouterLink>
            </Group>
          </div>

          <ScrollArea className={classes.links}>
            <Box mx="xl" mt="lg" className={classes.user}>
              <UserProfile />
            </Box>
            <div className={classes.linksInner}>{links}</div>
          </ScrollArea>

          <div className={classes.footer}>
            <UserButton />
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default SideNav;
