import { useContext, useEffect } from 'react';
import { Center, Loader, Stack } from '@mantine/core';

import AuthContext from 'context/AuthContext';

const LogoutPage = () => {
  const {
    actions: { logout },
  } = useContext(AuthContext);

  useEffect(() => {
    logout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Center h="100vh">
      <Stack align="center">
        <Loader size="md" />
      </Stack>
    </Center>
  );
};

export default LogoutPage;
