import { Text, Button, Modal, Group, Stack } from '@mantine/core';
import { IconAlertCircle } from '@tabler/icons-react';

interface DiscardModalProps {
  opened: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const DiscardModal = ({ opened, onClose, onConfirm }: DiscardModalProps) => {
  return (
    <Modal
      centered
      size="md"
      radius="lg"
      shadow="lg"
      padding="lg"
      opened={opened}
      onClose={onClose}
      withCloseButton={false}
    >
      <Stack align="center" gap="lg">
        <IconAlertCircle
          size={50}
          color="var(--mantine-color-red-6)"
          stroke={1.5}
        />

        <Stack gap="xs" ta="center">
          <Text size="xl" fw={600}>
            Discard Changes?
          </Text>
          <Text size="sm" c="dimmed">
            Are you sure you want to discard your changes? This action cannot be
            undone.
          </Text>
        </Stack>

        <Group justify="center" w="100%" gap="sm">
          <Button variant="default" onClick={onClose} size="md">
            Cancel
          </Button>
          <Button color="red" onClick={onConfirm} size="md">
            Yes, discard
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
};

export default DiscardModal;
