import { Flex, Loader } from '@mantine/core';
import ScrollSection from 'components/ScrollSection';
import AuthContext from 'context/AuthContext';
import { useContext, FC } from 'react';

interface ContainerProps {
  data?: any;
  children?: any;
  emptyComponent?: any;
}

const Container: FC<ContainerProps> = ({ data, children, emptyComponent }) => {
  const {
    data: { userFetched },
  } = useContext(AuthContext);

  if (!userFetched) {
    return (
      <ScrollSection>
        <Flex align="center" justify="center" h="100%">
          <Loader size="md" />
        </Flex>
      </ScrollSection>
    );
  }

  if ((!data || data.length === 0) && !!emptyComponent) {
    return (
      <ScrollSection>
        <Flex align="center" justify="center" h="100%">
          {emptyComponent}
        </Flex>
      </ScrollSection>
    );
  }

  return children;
};

export default Container;
