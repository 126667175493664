import { z } from 'zod';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Anchor } from '@mantine/core';

import routes from 'utils/routes';
import Form from 'components/form';
import AuthContext from 'context/AuthContext';
import useFormHandler from 'hooks/useFormHandler';
import { schemaFields } from 'utils/schemaBuilder';
import Testimonial from 'components/Testimonial';
import { useDebouncedValue } from '@mantine/hooks';

export default function Register() {
  const navigate = useNavigate();
  const [passwordValid, setPasswordValid] = useState(false);
  const [emailCheckLoading, setEmailCheckLoading] = useState(false);
  const [checkedPassword, setCheckedPassword] = useState('');
  const [emailValue, setEmailValue] = useState('');
  const [debouncedEmail] = useDebouncedValue(emailValue, 500);
  const {
    actions: { setRegisterEmail, setRegisterPassword, checkAvailableEmail },
  } = useContext(AuthContext);

  const formSchema = z
    .object({
      password: z.string(),
      terms: z.boolean(),
      valid_password: z.boolean(),
      confirm_password: z.string(),
      email: schemaFields.email.schema,
    })
    .refine((data) => data.password === data.confirm_password, {
      message: "The passwords don't match",
      path: ['confirm_password'],
    })
    .refine((data) => data.terms === true, {
      message: 'You must accept the terms and conditions',
      path: ['terms'],
    });

  const { form, loading, error, setError } = useFormHandler(formSchema, {
    initialValues: {
      email: '',
      password: '',
      terms: false,
      confirm_password: '',
      valid_password: false,
    },
  });

  useEffect(() => {
    const validateEmail = async () => {
      if (!debouncedEmail) {
        setEmailCheckLoading(false);
        return;
      }

      const errorMessage = 'Email is already in use';

      try {
        const isAvailable = await checkAvailableEmail(debouncedEmail);

        if (!isAvailable) {
          form.setErrors({
            email: errorMessage,
          });
        }
      } catch (error: any) {
        if (error.status === 400) {
          form.setErrors({
            email: errorMessage,
          });
        }
      } finally {
        setEmailCheckLoading(false);
      }
    };

    validateEmail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedEmail]);

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    try {
      setRegisterEmail(values.email);
      setRegisterPassword(values.password);
      navigate(routes.onboardingPath.href);
    } catch (error) {
      setError('Error. Please try again.');
    }
  };

  const getConfirmPasswordError = () => {
    if (
      !form.values.confirm_password ||
      !form.values.password ||
      form.values.confirm_password === form.values.password
    ) {
      return undefined;
    }

    return form.values.password.length > 0 &&
      form.values.confirm_password.length >= form.values.password.length
      ? 'Passwords do not match'
      : undefined;
  };

  return (
    <div className="flex-1 grid grid-cols-1 md:grid-cols-2 min-h-screen overflow-hidden">
      <div className="min-h-screen overflow-y-auto">
        <nav className="h-[70px] sticky top-0 w-full custom-bg-color  px-3 md:px-6 flex items-center justify-between">
          <a
            href={routes.dashboardPath.href}
            className="flex items-center gap-4"
          >
            <img src="/images/logo-icon.svg" alt="Nucleus Logo" />
          </a>

          <span></span>
          <div className="flex items-center gap-4 md:hidden">
            <a
              href={routes.signInPath.href}
              className="custom-border-color font-medium border rounded-md h-[40px] px-6 custom-bg-color hover:bg-hover-primary transition-all flex align-center"
            >
              <span>Sign in</span>
            </a>
          </div>
        </nav>
        <div className="md:flex items-center  lg:items-center signup-container px-3 md:px-0">
          <div className="border custom-border-color bg-white rounded-2xl p-6 md:p-12 md:w-5/6 xl:w-[540px] mx-auto">
            <div className="mb-4 text-center">
              <h2 className="text-2xl font-semibold custom-text-color">
                Create an account
              </h2>
            </div>

            <Form form={form} error={error} onSubmit={onSubmit}>
              <Form.Input
                name="email"
                type="email"
                label="Email"
                loading={emailCheckLoading}
                placeholder="name@example.com"
                onChange={(value: string | number) => {
                  setEmailCheckLoading(true);
                  setEmailValue(value as string);
                }}
                onBlur={() => {
                  // do nothing
                }}
              />
              <Form.Password
                name="password"
                label="Password"
                placeholder="Password"
                onUpdate={(value) => setCheckedPassword(value)}
              />
              <Form.Password
                name="confirm_password"
                label="Confirm Password"
                placeholder="Confirm password"
                error={getConfirmPasswordError()}
              />
              <Form.PasswordCheck
                value={checkedPassword}
                validatePassword={(isValid) => {
                  setPasswordValid(isValid);
                  form.setValues({ valid_password: isValid });
                }}
              />
              <div className="flex items-center gap-2 mt-8">
                <Form.Checkbox
                  name="terms"
                  label={
                    <>
                      I agree to the{' '}
                      <Anchor
                        href={routes.termsPath.href}
                        target="_blank"
                        inherit
                        style={{ borderBottom: '1px solid #1A1A1A' }}
                      >
                        Terms of Use
                      </Anchor>
                      {' and '}
                      <Anchor
                        href={routes.privacyPath.href}
                        target="_blank"
                        inherit
                        style={{ borderBottom: '1px solid #1A1A1A' }}
                      >
                        Privacy Policy
                      </Anchor>
                    </>
                  }
                />
              </div>
              <Form.Submit
                loading={loading}
                mt={20}
                disabled={!passwordValid || emailCheckLoading}
              >
                Sign Up
              </Form.Submit>
            </Form>
          </div>
        </div>
      </div>
      <div className="min-h-screen hidden md:block bg-white border-l custom-border-color">
        <nav className="h-[70px] sticky bg-white z-10 top-0 w-full  px-6 flex items-center justify-between">
          <span></span>

          <div className="items-center gap-4 hidden md:flex">
            <a
              href={routes.signInPath.href}
              className="custom-border-color font-medium border hover:bg-hover-primary transition-all rounded-md h-[40px] px-6 custom-bg-color flex items-center"
            >
              <span>Sign in</span>
            </a>
          </div>
        </nav>
        <div className="min-h-[calc(100vh-70px)] flex flex-col justify-end">
          <Testimonial />
          <div className="w-full mx-auto relative min-h-[500px] ml-[10%] xl:min-h-[600px] overflow-hidden border-l-[3px] border-t-[3px] border-[#5e6ab2] rounded-ss-3xl">
            <img
              src="/images/report-scrnshot.png"
              alt=""
              className="absolute bottom-0 top-0 custom-box-shadow w-[90%]"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
