import { useDisclosure } from '@mantine/hooks';
import { Box, Flex, Text, Button, Loader, Badge, Popover } from '@mantine/core';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import routes from 'utils/routes';
import UserProfile from './UserProfile';
import { NewtCloudLogo } from 'img/NewtCloudLogo';
import { useFormContext } from 'context/FormContext';

import classes from './index.module.scss';
import { useState } from 'react';
import DiscardModal from 'components/modal/DiscardModal';
import { getErrorCount } from 'utils/form';
import { pluralize } from 'utils/string';

const Header = () => {
  const location = useLocation();
  const [discardModalOpened, setDiscardModalOpened] = useState(false);
  const [
    openedErrorPopover,
    { close: closeErrorPopover, open: openErrorPopover },
  ] = useDisclosure(false);
  const { currentForm, onSubmit, loading, defaultValues } = useFormContext();
  const disabled = !currentForm || loading || !currentForm?.isDirty();

  const currentRoute = Object.values(routes).find(
    (route) => route.href === location.pathname
  );

  const scrollToFirstError = (validator: any) => {
    const errorInput = document.querySelector(
      `[data-path="${Object.keys(validator?.errors || {})[0]}"]`
    );

    if (errorInput) {
      errorInput.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  const onSave = () => {
    const validator = currentForm?.validate();

    if (validator?.hasErrors) {
      scrollToFirstError(validator);
    }
    !disabled && onSubmit?.();
  };

  const confirmDiscard = () => {
    if (!disabled) {
      currentForm?.setValues(defaultValues);
      currentForm?.resetDirty();
    }
    setDiscardModalOpened(false);
  };

  const errorCount = getErrorCount(currentForm?.errors);

  return (
    <>
      <Box hiddenFrom="sm">
        <RouterLink to={routes.dashboardPath.href}>
          <div className={classes.logo}>
            <NewtCloudLogo />
          </div>
        </RouterLink>
      </Box>
      <Box className={classes.headerContainer}>
        <header className={classes.header}>
          <Flex
            h="100%"
            gap="sm"
            align="center"
            justify={{ base: 'flex-end', sm: 'space-between' }}
          >
            <Text fw={{ base: 600, sm: 700 }} fz={{ base: 14, sm: 17 }}>
              {currentRoute?.title}
            </Text>
            <Flex align="center" gap={40}>
              {currentForm && (
                <Flex gap={10}>
                  <Button
                    size="sm"
                    variant="default"
                    disabled={disabled}
                    onClick={() => setDiscardModalOpened(true)}
                  >
                    Discard
                  </Button>
                  <Popover
                    width={200}
                    position="bottom"
                    withArrow
                    shadow="md"
                    opened={openedErrorPopover && errorCount > 0}
                  >
                    <Popover.Target>
                      <Button
                        size="sm"
                        onClick={onSave}
                        disabled={disabled}
                        onMouseEnter={openErrorPopover}
                        onMouseLeave={closeErrorPopover}
                        rightSection={
                          loading ? (
                            <Loader size="16" color="#adb5bd" ml="7" />
                          ) : (
                            errorCount > 0 && (
                              <Badge
                                circle
                                size="xs"
                                ml={4}
                                color="var(--mantine-color-red-6)"
                              >
                                {errorCount}
                              </Badge>
                            )
                          )
                        }
                      >
                        Save
                      </Button>
                    </Popover.Target>
                    <Popover.Dropdown style={{ pointerEvents: 'none' }}>
                      <Text size="sm">
                        You have {pluralize('error', errorCount)}
                      </Text>
                    </Popover.Dropdown>
                  </Popover>
                </Flex>
              )}
              <Flex visibleFrom="sm">
                <UserProfile />
              </Flex>
            </Flex>
          </Flex>
        </header>
      </Box>
      <DiscardModal
        onConfirm={confirmDiscard}
        opened={discardModalOpened}
        onClose={() => setDiscardModalOpened(false)}
      />
    </>
  );
};

export default Header;
