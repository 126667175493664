import { z } from 'zod';
import { useContext } from 'react';
import { Text } from '@mantine/core';

import Form from 'components/form';
import Tabs from 'components/Tabs';
import AuthContext from 'context/AuthContext';
import { ICardItem } from 'interfaces/CardItem';
import { getNames, mergeNames } from 'utils/string';
import AttendantSettings from './attendantSettings';
import { getErrorCountByField } from 'utils/form';
import { useFormPersist } from 'hooks/useFormPersist';
import TrainingData from 'pages/attendant/trainingData';
import { formSchema, formatAvailability, formatCards } from './schema';
import StaffDirectory from 'pages/attendant/staffDirectory';
import { CommunicationType, Status } from 'interfaces/Agent';
import OrganizationContext from 'context/OrganizationContext';
import { ICompanyFormInput } from 'interfaces/CompanyFormInput';
import { AccessLevel, ICompany } from 'interfaces/GetUserResult';
import { default_welcome, default_goodbye } from 'constants/company';

const ID = 'attendant';

const defaultFromCompany = (
  company: ICompany | null,
  defaultCards: ICardItem[]
) => ({
  name: company?.name || '',
  welcome_message: company?.welcome_message || default_welcome(),
  goodbye_message: company?.goodbye_message || default_goodbye(),
  voice: company?.voice || '',
  time_zone: company?.time_zone || '',
  cards: formatCards(company?.cards, defaultCards),
  users:
    company?.users.map((user) => {
      return {
        ...user,
        email: user.email || '',
        phone: user.phone || '',
        status: user.status || Status.AVAILABLE,
        name: mergeNames(user.first_name, user.last_name),
        availability: formatAvailability(user.availability),
        prefered_communication:
          user.prefered_communication || CommunicationType.EMAIL,
        tags: user.tags || [],
      };
    }) || [],
});

const AttendantPage = () => {
  const {
    data: { account, company },
    actions: { updateUser },
  } = useContext(AuthContext);
  const {
    data: { defaultCards },
  } = useContext(OrganizationContext);

  const onSubmit = async (
    values: z.infer<typeof formSchema>,
    setError: (error?: any) => void,
    setSuccess: (values: z.infer<typeof formSchema>) => void
  ) => {
    const data: ICompanyFormInput = {
      ...company,
      name: values.name,
      voice: values.voice,
      cards: values.cards,
      time_zone: values.time_zone,
      welcome_message: values.welcome_message,
      goodbye_message: values.goodbye_message,
      users: values.users.map((user) => {
        const { first_name, last_name } = getNames(user.name);

        return {
          last_name,
          first_name,
          id: user.id,
          tags: user.tags,
          email: user.email,
          phone: user.phone,
          status: user.status,
          availability: user.availability,
          prefered_communication:
            account?.access_level === AccessLevel.PRO
              ? user.prefered_communication
              : CommunicationType.EMAIL,
        };
      }),
    };

    try {
      const result = await updateUser(data);

      const defaultValues = defaultFromCompany(result.company, defaultCards);
      setSuccess(defaultValues);
    } catch (error) {
      setError(error);
    } finally {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  const { form, handleSubmit } = useFormPersist({
    formSchema,
    onSubmit,
    id: ID,
    defaultValues: defaultFromCompany(company, defaultCards),
  });

  const trainingFields = [
    'name',
    'welcome_message',
    'goodbye_message',
    'cards',
  ];
  const staffDirectoryFields = ['users'];
  const attendantSettingsFields = ['voice', 'time_zone'];

  const trainingUnsavedCount = getErrorCountByField(
    form.errors,
    trainingFields
  );
  const staffDirectoryUnsavedCount = getErrorCountByField(
    form.errors,
    staffDirectoryFields
  );

  const attendantSettingsUnsavedCount = getErrorCountByField(
    form.errors,
    attendantSettingsFields
  );

  const getBadge = (count: number) => {
    return count > 0 ? (
      <Text
        top={6}
        fw={900}
        fz="xs"
        right={-7}
        pos="absolute"
        c="var(--mantine-color-red-6)"
      >
        !
      </Text>
    ) : undefined;
  };

  const tabs = [
    {
      value: 'training',
      label: 'Training',
      content: <TrainingData />,
      style: { display: 'inline' },
      rightSection: getBadge(trainingUnsavedCount),
    },
    {
      value: 'staff-directory',
      label: 'Staff Directory',
      content: <StaffDirectory />,
      style: { display: 'inline' },
      rightSection: getBadge(staffDirectoryUnsavedCount),
    },
    {
      value: 'attendant-settings',
      label: 'Settings',
      content: <AttendantSettings />,
      style: { display: 'inline' },
      rightSection: getBadge(attendantSettingsUnsavedCount),
    },
  ];

  return (
    <Form form={form} onSubmit={handleSubmit}>
      <Tabs defaultTab="training" tabs={tabs} />
    </Form>
  );
};

export default AttendantPage;
