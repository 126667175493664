interface Testimonial {
  name: string;
  title: string;
  image: string;
  quotes: string[];
}

export const testimonials: Testimonial[] = [
  {
    name: 'Nelin Krull',
    title: 'Co-owner and Clinic Director, Myofascial Release Mississauga',
    image: '/images/nelin.png',
    quotes: [
      'Nucleus was super easy to set up. Love it! Such a life-saver!',
      'Our office assistant was working part-time so Nucleus helped fill in the gaps. We decided to keep it even when she came back so clients can contact us after-hours.',
      'The AI is so friendly that our clients thought they were talking to a member of our team.',
      "This is incredibly high value for the cost. The yearly cost is less than a day's work for our office assistant.",
      "Nucleus is a very useful tool to bridge the gap and help customers get answers and feel heard when you can't tend to the phones. It adds that extra level of customer service so no one is missed while you are on holiday, at lunch, or off sick.",
    ],
  },
  {
    name: 'Nikita Nathwani',
    title: 'Owner, 9Rounds Waterloo',
    image: '/images/nikita.png',
    quotes: [
      "It's an incredible platform - it's given me peace of mind and time freedom and as a business owner, you can't put a price on that.",
      "The feedback so far has been incredible; our customers think it's very professional. It has all the answers and doesn't rush them off the phone.",
      "She's very prompt in answering every call, very professional sounding. As a business, the first impression you make on people has to be professional or they're never going to walk through your doors.",
      "My joy is spending time on the floor training so I don't have time to answer all the questions coming in. My Nucleus attendant is doing jobs for me that I don't have to do. It's really like having another employee.",
      'For the price point it\'s very fair - if "Valerie" was going to take two phone calls in a month, that really covers the price.',
    ],
  },
];

export const getRandomTestimonial = () => {
  const testimonial =
    testimonials[Math.floor(Math.random() * testimonials.length)];
  const quote =
    testimonial.quotes[Math.floor(Math.random() * testimonial.quotes.length)];

  return {
    name: testimonial.name,
    title: testimonial.title,
    image: testimonial.image,
    quote,
  };
};
