import { ReactNode, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  Tabs as MantineTabs,
  FloatingIndicator,
  MantineStyleProp,
} from '@mantine/core';

import classes from './Tabs.module.scss';

interface Tab {
  value: string;
  label: ReactNode;
  content: ReactNode;
  leftSection?: ReactNode;
  style?: MantineStyleProp;
  rightSection?: ReactNode;
}

interface TabsProps {
  tabs: Tab[];
  defaultTab: string;
}

const Tabs = ({ defaultTab, tabs }: TabsProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const activeTab = searchParams.get('tab') || defaultTab;

  const [rootRef, setRootRef] = useState<HTMLDivElement | null>(null);
  const [value, setValue] = useState<string | null>('1');
  const [controlsRefs, setControlsRefs] = useState<
    Record<string, HTMLButtonElement | null>
  >({});
  const setControlRef = (val: string) => (node: HTMLButtonElement) => {
    controlsRefs[val] = node;
    setControlsRefs(controlsRefs);
  };

  const handleTabChange = (value: string | null) => {
    if (value) {
      setSearchParams({ tab: value });
    }
    setValue(value);
  };

  useEffect(() => {
    setValue(activeTab);
  }, [activeTab]);

  return (
    <MantineTabs value={activeTab} onChange={handleTabChange} variant="none">
      <MantineTabs.List ref={setRootRef} className={classes.list} mb={40}>
        {tabs.map((tab) => (
          <MantineTabs.Tab
            key={tab.value}
            value={tab.value}
            className={classes.tab}
            leftSection={tab.leftSection}
            ref={setControlRef(tab.value)}
            rightSection={tab.rightSection}
            style={tab.style}
          >
            {tab.label}
          </MantineTabs.Tab>
        ))}
        <FloatingIndicator
          parent={rootRef}
          className={classes.indicator}
          style={{ background: 'inherit' }}
          target={value ? controlsRefs[value] : null}
        />
      </MantineTabs.List>

      <MantineTabs.Panel
        key={activeTab}
        value={activeTab}
        className={classes.panel}
      >
        {tabs.find((tab) => tab.value === activeTab)?.content}
      </MantineTabs.Panel>
    </MantineTabs>
  );
};

export default Tabs;
