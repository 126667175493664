import { FC } from 'react';
import { Paper, PaperProps } from '@mantine/core';

interface SectionProps extends PaperProps {
  children: React.ReactNode;
}

const Section: FC<SectionProps> = ({ children, ...props }) => {
  return (
    <Paper radius="md" withBorder py={{ md: 'lg', base: 'md' }} {...props}>
      {children}
    </Paper>
  );
};

export default Section;
