export interface IAvailabilityTime {
  end?: string;
  start?: string;
  enabled: boolean;
}

export enum DaysOfWeek {
  MONDAY = 'monday',
  TUESDAY = 'tuesday',
  WEDNESDAY = 'wednesday',
  THURSDAY = 'thursday',
  FRIDAY = 'friday',
  SATUDAY = 'saturday',
  SUNDAY = 'sunday',
}

export enum Status {
  AVAILABLE = 'available',
  SCHEDULE = 'schedule',
  AWAY = 'away',
}

export interface IAvailability {
  monday: IAvailabilityTime;
  tuesday: IAvailabilityTime;
  wednesday: IAvailabilityTime;
  thursday: IAvailabilityTime;
  friday: IAvailabilityTime;
  saturday: IAvailabilityTime;
  sunday: IAvailabilityTime;
}

export enum CommunicationType {
  TEXT = 'text',
  EMAIL = 'email',
}

export interface ITeamMember {
  id: string;
  phone: string;
  email: string;
  tags?: string[];
  status?: Status;
  last_name: string;
  first_name: string;
  availability: IAvailability;
  prefered_communication: CommunicationType;
}

export interface IAgentCreate {
  phone: string;
  last_name: string;
  first_name: string;
}

export interface IAgentPronunciation {
  id: string;
  word: string;
  sound: string;
}

export interface IUserCreate extends ITeamMember {
  name?: string;
  unsaved?: boolean;
}
