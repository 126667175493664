import { FC } from 'react';
import { Slider, Box, Flex, SliderProps, Text } from '@mantine/core';

import { useFormComponentContext } from 'context/FormContext';
import useEditStyles from 'hooks/useEditStyles';

interface FormSliderProps extends Omit<SliderProps, 'onChange'> {
  name: string;
  label?: string;
  error?: string;
  required?: boolean;
  showEdit?: boolean;
  defaultValue: number;
  onChange?: (value: string | number) => void;
}

const FormSlider: FC<FormSliderProps> = ({
  name,
  label,
  required,
  defaultValue,
  showEdit = false,
  ...props
}) => {
  const form = useFormComponentContext();
  const { editedBoxStyles } = useEditStyles({ name, showEdit });

  return (
    <div style={{ marginBottom: '2.5rem' }}>
      <Flex justify="space-between" align="center" mb="lg">
        <Text size="sm" fw={600}>
          {label}
          {required && ' *'}
        </Text>
        <Flex align="center" gap="sm">
          <Box
            py={4}
            px="xs"
            style={{ borderRadius: 4, ...editedBoxStyles }}
            bd="1px solid var(--mantine-color-gray-3)"
          >
            <Text size="sm">{(form.values[name] || 0).toFixed(2)}</Text>
          </Box>
        </Flex>
      </Flex>
      <Slider
        max={1}
        size="sm"
        step={0.1}
        label={null}
        thumbSize={22}
        defaultValue={defaultValue}
        styles={{ thumb: { borderWidth: 3, padding: 3 } }}
        {...form.getInputProps(name)}
        {...props}
      />
    </div>
  );
};

export default FormSlider;
