import { useDisclosure } from '@mantine/hooks';
import { IconPlayerPlayFilled } from '@tabler/icons-react';
import { useContext, useEffect, useRef, useState } from 'react';
import { Text, Flex, Button, SelectProps } from '@mantine/core';

import Form from 'components/form';
import Badge from 'components/Badge';
import AuthContext from 'context/AuthContext';
import { capitalizeWords } from 'utils/string';
import BusinessContext from 'context/BusinessContext';
import { IVoiceResult } from 'interfaces/VoiceResult';
import { AccessLevel } from 'interfaces/GetUserResult';
import UpgradeWrapper from 'components/upgrade/Wrapper';
import { useFormComponentContext } from 'context/FormContext';

import classes from './VoiceProfile.module.scss';

const VoiceProfile = () => {
  const form = useFormComponentContext();
  const audioRef = useRef<HTMLAudioElement>(null);
  const {
    data: { account },
  } = useContext(AuthContext);
  const [
    openedVoiceOptions,
    { toggle: toggleVoiceOptions, close: closeVoiceOptions },
  ] = useDisclosure();
  const [openedUpgradePopover, setOpenedUpgradePopover] = useState<
    Record<string, boolean>
  >({});

  const previewDisabled = !form.values.voice || openedVoiceOptions;

  const {
    data: { voices },
    actions: { getVoices },
  } = useContext(BusinessContext);

  useEffect(() => {
    getVoices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getVoiceOptions = (voices: IVoiceResult[]) => {
    return voices.map((voice) => {
      const label = capitalizeWords(voice.name);

      return {
        label,
        value: voice.name,
        disabled: account?.access_level !== AccessLevel.PRO && voice.is_premium,
      };
    });
  };

  const getAudioPath = (voice: string) => {
    return `/audio/voices/${capitalizeWords(voice)}.mp3`;
  };

  const playVoicePreview = (voice?: string) => {
    const selectedVoice = voice || form.values.voice;
    if (!selectedVoice) return;

    const audioPath = getAudioPath(selectedVoice);
    if (!audioPath) return;

    if (audioRef.current) {
      audioRef.current.src = audioPath;
      audioRef.current.play();
    }
  };

  const renderSelectOption: SelectProps['renderOption'] = ({ option }) => {
    const premiumOnly = option.disabled;

    if (!premiumOnly)
      return (
        <Flex
          w="100%"
          p="var(--combobox-option-padding)"
          onClick={() => toggleVoiceOptions()}
        >
          {option.label}
        </Flex>
      );

    return (
      <UpgradeWrapper
        as={Flex}
        requiresUpgrade={premiumOnly}
        upgradeTitle="Unlock Premium voices"
        upgradeDescription="Upgrade for higher-quality voices with improved speech patterns."
        close={!openedVoiceOptions}
        onOpen={() => {
          setOpenedUpgradePopover({ [option.value]: true });
        }}
        onClose={() => setOpenedUpgradePopover({ [option.value]: false })}
        componentProps={{
          justify: 'space-between',
          gap: 'xs',
          w: '100%',
          p: 'var(--combobox-option-padding)',
          className: `${classes.voiceOptionItem} ${
            openedUpgradePopover[option.value]
              ? classes.voiceOptionItemClicked
              : ''
          }`,
        }}
        maxWidth="100%"
        primaryActionProps={{
          mt: 12,
          fz: 'sm',
          size: 'sm',
          miw: '250px',
        }}
        secondaryActionProps={{
          mt: 12,
          fz: 'sm',
          size: 'sm',
          variant: 'default',
          h: { xs: 'auto' },
          miw: '250px',
          bg: 'var(--bg-color)',
          onClick: () => playVoicePreview(option.value),
          children: 'Preview this voice',
          rightSection: (
            <IconPlayerPlayFilled
              size={17}
              color={
                previewDisabled ? 'var(--mantine-color-gray-5)' : '#00000073'
              }
            />
          ),
        }}
      >
        {option.label}
        <Badge
          variant="light"
          style={{ fontSize: 12 }}
          color="var(--green-color)"
        >
          Premium
        </Badge>
      </UpgradeWrapper>
    );
  };

  return (
    <Flex direction="column" gap={0} mb={10}>
      <audio ref={audioRef} />
      <Text size="sm" fw={500} mb={7}>
        Voice profile *
      </Text>
      <Flex
        justify="space-between"
        gap={{ base: 10, md: 'md' }}
        direction={{ base: 'column', xs: 'row' }}
      >
        <Form.Select
          m={0}
          flex={1}
          required
          showEdit
          name="voice"
          placeholder="Please select a profile"
          options={getVoiceOptions(voices)}
          renderOption={renderSelectOption}
          onClick={toggleVoiceOptions}
          dropdownOpened={openedVoiceOptions}
          onBlur={() => closeVoiceOptions()}
          classNames={{
            option: classes.voiceOption,
          }}
        />
        <Button
          mt={0}
          fz="sm"
          size="md"
          variant="default"
          h={{ xs: 'auto' }}
          w={{ base: '100%', xs: 'fit-content' }}
          bg="var(--bg-color)"
          onClick={() => playVoicePreview()}
          disabled={previewDisabled}
          rightSection={
            <IconPlayerPlayFilled
              size={17}
              color={
                previewDisabled ? 'var(--mantine-color-gray-5)' : '#00000073'
              }
            />
          }
        >
          Play voice preview
        </Button>
      </Flex>
    </Flex>
  );
};

export default VoiceProfile;
