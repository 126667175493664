const webBaseUrl = process.env.REACT_APP_WEB_URL;

const apiRoutes = {
  signInPath: {
    href: '/signin',
    title: 'Sign in',
  },
  registerPath: {
    href: '/register',
    title: 'Register',
  },
  onboardingPath: {
    href: '/onboarding',
    title: 'Onboarding',
  },
  forgotPasswordPath: {
    href: '/forgot-password',
    title: 'Forgot Password',
  },
  resetPasswordPath: {
    href: '/reset-password',
    title: 'Reset Password',
  },
  settingsPath: {
    href: '/settings',
    title: 'Settings',
  },
  accountPath: {
    href: '/profile',
    title: 'Account',
  },
  dashboardPath: {
    href: '/',
    title: 'Dashboard',
  },
  welcomePath: {
    href: '/welcome',
    title: 'Welcome',
  },
  reportingPath: {
    href: '/reporting',
    title: 'Reporting',
  },
  attendantPath: {
    href: '/attendant',
    title: 'Attendant',
  },
  additionalDetailsPath: {
    href: '/add-details',
    title: 'Additional Details',
  },
  billingPath: {
    href: '/billing',
    title: 'Billing',
  },
  upgradePath: {
    href: '/upgrade',
    title: 'Upgrade',
  },
  trialUpgradePath: {
    href: '/trial-upgrade',
    title: 'Trial Upgrade',
  },
  verifyAdminPath: {
    href: '/check',
    title: 'Verify',
  },
  logoutPath: {
    href: '/logout',
    title: 'Sign out',
  },
};

const webRoutes = {
  privacyPath: {
    href: `${webBaseUrl}/privacy`,
    title: 'Privacy',
  },
  termsPath: {
    href: `${webBaseUrl}/terms`,
    title: 'Terms',
  },
  faqsPath: {
    href: `${webBaseUrl}/faq`,
    title: 'FAQ',
  },
  contactPath: {
    href: `${webBaseUrl}/contact-us`,
    title: 'Contact Us',
  },
  aboutPath: {
    href: `${webBaseUrl}/about`,
    title: 'About',
  },
};

const routes = { ...apiRoutes, ...webRoutes };

export default routes;
