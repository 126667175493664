import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, Text, Button, Stack, ThemeIcon } from '@mantine/core';
import { IconClockExclamation } from '@tabler/icons-react';

import routes from 'utils/routes';
import AuthContext from 'context/AuthContext';

interface SessionTimeoutModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const SessionTimeoutModal = ({ isOpen, onClose }: SessionTimeoutModalProps) => {
  const navigate = useNavigate();
  const {
    actions: { logout },
  } = useContext(AuthContext);

  const handleLogin = () => {
    navigate(routes.signInPath.href, { replace: true });
    onClose();
  };

  useEffect(() => {
    if (isOpen) {
      logout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <Modal
      centered
      radius="md"
      shadow="lg"
      opened={isOpen}
      onClose={onClose}
      withCloseButton={false}
      closeOnClickOutside={false}
      closeOnEscape={false}
    >
      <Stack align="center" mb="xl">
        <ThemeIcon size={48} radius="xl" color="blue.4" variant="light">
          <IconClockExclamation size={28} />
        </ThemeIcon>
        <Stack gap={4} align="center">
          <Text fw={500} size="lg">
            Session Expired
          </Text>
          <Text size="sm" c="dimmed" ta="center">
            Your session has expired due to inactivity. Please sign in again to
            continue working.
          </Text>
        </Stack>
      </Stack>

      <Stack align="center">
        <Button size="sm" onClick={handleLogin} w={130}>
          Sign In
        </Button>
      </Stack>
    </Modal>
  );
};

export default SessionTimeoutModal;
