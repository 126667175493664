import { ITeamMember } from './Agent';
import { ICardItem } from './CardItem';

export enum AccessLevel {
  PRO = 'PRO',
  FREE = 'FREE',
  GUEST = 'GUEST',
  SUPER = 'SUPER',
  CLOSED = 'CLOSED',
  SUSPENDED = 'SUSPENDED',
}

export interface IAccount {
  id: number;
  username: string;
  verified: boolean;
  created_on: number;
  has_password: boolean;
  access_level: AccessLevel;
}

export interface ICompany {
  id: number;
  name: string;
  did: string;
  voice: string;
  cards: ICardItem[];
  time_zone?: string;
  users: ITeamMember[];
  welcome_message?: string;
  goodbye_message?: string;
}

export interface IStripe {
  trial_end?: number;
  promo_code?: string;
  trial_start?: number;
  has_billing?: boolean;
  promo_code_expiry?: number;
  promo_code_percent?: number;
}

export interface IStoreResult {
  promo_code?: string;
  return_url?: string;
  lookup_key?: string;
  refer_id?: string;
}

export interface IGetUserResult {
  stripe?: IStripe;
  account: IAccount;
  company: ICompany;
}

export interface ICreateStripePriceResult {
  secret: string;
  price_id: string;
}
