import { FC } from 'react';
import { NumberInput, NumberInputProps } from '@mantine/core';

import useEditStyles from 'hooks/useEditStyles';
import { useFormComponentContext } from 'context/FormContext';

interface FormNumberProps extends Omit<NumberInputProps, 'onChange'> {
  name: string;
  label?: string;
  value: number;
  error?: string;
  required?: boolean;
  showEdit?: boolean;
  placeholder?: string;
  description?: string;
  onChange?: (value: string | number) => void;
}

const FormNumber: FC<FormNumberProps> = ({
  name,
  type,
  label,
  required,
  placeholder,
  description,
  showEdit = false,
  ...props
}) => {
  const form = useFormComponentContext();
  const { editedInputStyles } = useEditStyles({ name, showEdit });

  return (
    <NumberInput
      label={label}
      required={required}
      placeholder={placeholder}
      description={description}
      styles={{ ...editedInputStyles, ...props.styles }}
      {...form.getInputProps(name)}
      {...props}
    />
  );
};

export default FormNumber;
