import { NavLink as RouterLink } from 'react-router-dom';
import { Button, Container, Group, Text } from '@mantine/core';

import routes from 'utils/routes';

import classes from './index.module.scss';

const NotFoundPage = () => {
  return (
    <Container className={classes.root}>
      <div className={classes.title}>404</div>
      <Text c="dimmed" size="lg" ta="center" className={classes.description}>
        You may have mistyped the address, or the page has been moved to another
        URL.
      </Text>
      <Group justify="center">
        <Button
          size="md"
          variant="subtle"
          component={RouterLink}
          to={routes.dashboardPath.href}
        >
          Take me to my dasboard
        </Button>
      </Group>
    </Container>
  );
};

export default NotFoundPage;
