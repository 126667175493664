import { FC } from 'react';
import { Select, SelectProps } from '@mantine/core';

import { useFormComponentContext } from 'context/FormContext';
import useEditStyles from 'hooks/useEditStyles';

interface FormSelectProps extends SelectProps {
  name: string;
  label?: string;
  error?: string;
  required?: boolean;
  showEdit?: boolean;
  placeholder?: string;
  description?: string;
  onChange?: (value: string | null) => void;
  options: { value: string; label: string }[];
}

const FormSelect: FC<FormSelectProps> = ({
  name,
  label,
  options,
  required,
  onChange,
  description,
  placeholder,
  showEdit = false,
  ...props
}) => {
  const form = useFormComponentContext();
  const { editedSelectStyles } = useEditStyles({ name, showEdit });

  return (
    <Select
      label={label}
      required={required}
      data={options || []}
      allowDeselect={false}
      description={description}
      placeholder={placeholder}
      styles={{ ...editedSelectStyles, ...props.styles }}
      {...form.getInputProps(name)}
      {...props}
      onClear={() => {
        form.setFieldValue(name, '');
      }}
      onChange={(value) => {
        onChange?.(value);
        form.getInputProps(name).onChange(value);
      }}
    />
  );
};

export default FormSelect;
