export const areObjectsEqual = (obj1: any, obj2: any): boolean => {
  if (obj1 === obj2) return true;
  if (!obj1 || !obj2) return false;
  if (typeof obj1 !== typeof obj2) return false;

  if (Array.isArray(obj1)) {
    if (!Array.isArray(obj2) || obj1.length !== obj2.length) return false;
    return obj1.every((item, index) => areObjectsEqual(item, obj2[index]));
  }

  if (typeof obj1 === 'object') {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) return false;
    return keys1.every((key) => areObjectsEqual(obj1[key], obj2[key]));
  }

  return obj1 === obj2;
};

export const getObjectDiff = (obj1: any, obj2: any, path = ''): string[] => {
  const differences: string[] = [];

  if (obj1 === obj2) return differences;
  if (!obj1 || !obj2) return [path];
  if (typeof obj1 !== typeof obj2) return [path];

  if (Array.isArray(obj1)) {
    if (!Array.isArray(obj2) || obj1.length !== obj2.length) return [path];
    obj1.forEach((item, index) => {
      const nestedDiff = getObjectDiff(
        item,
        obj2[index],
        `${path}${path ? '.' : ''}${index}`
      );
      differences.push(...nestedDiff);
    });
    return differences;
  }

  if (typeof obj1 === 'object') {
    const keys = new Set([...Object.keys(obj1), ...Object.keys(obj2)]);
    keys.forEach((key) => {
      const nestedDiff = getObjectDiff(
        obj1[key],
        obj2[key],
        `${path}${path ? '.' : ''}${key}`
      );
      differences.push(...nestedDiff);
    });
    return differences;
  }

  return obj1 === obj2 ? [] : [path];
};
