import { Modal, Stack, Text, Box, ThemeIcon, Loader } from '@mantine/core';

interface LogoutModalProps {
  isOpen: boolean;
  title?: string;
  message?: string;
}

const LogoutModal = ({
  isOpen,
  title = 'Closing your account',
  message = 'Please wait while we log you out...',
}: LogoutModalProps) => {
  return (
    <Modal
      centered
      opened={isOpen}
      onClose={() => {}}
      withCloseButton={false}
      closeOnClickOutside={false}
      closeOnEscape={false}
      radius="lg"
      shadow="lg"
      padding="xl"
      size="sm"
    >
      <Stack align="center" gap="xl" py="md">
        <Box pos="relative">
          <ThemeIcon size={60} radius="xl" variant="light">
            <Loader size={40} />
          </ThemeIcon>
        </Box>
        <Stack gap={4} align="center">
          <Text size="lg" fw={500}>
            {title}
          </Text>
          <Text size="sm" c="dimmed" ta="center">
            {message}
          </Text>
        </Stack>
      </Stack>
    </Modal>
  );
};

export default LogoutModal;
