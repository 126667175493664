import {
  Box,
  Grid,
  Text,
  Flex,
  Divider,
  Collapse,
  ActionIcon,
} from '@mantine/core';
import { IconChevronUp, IconChevronDown } from '@tabler/icons-react';

import Form from 'components/form';
import VoiceProfile from './VoiceProfile';
import { useDisclosure } from '@mantine/hooks';
import { PronunciationList } from './PronunciationList';
import { useFormComponentContext } from 'context/FormContext';

const AttendantForm = () => {
  const form = useFormComponentContext();
  const [openedAdvancedSettings, { toggle: toggleAdvancedSettings }] =
    useDisclosure(false);

  return (
    <Box px="lg">
      <Grid gutter={20}>
        <Grid.Col span={{ lg: 10, base: 12 }}>
          <VoiceProfile />
        </Grid.Col>

        <Grid.Col span={{ lg: 10, base: 12 }}>
          <Form.Timezone
            required
            showEdit
            name="time_zone"
            label="Timezone"
            placeholder="Select timezone"
          />
        </Grid.Col>

        {false && (
          <Grid.Col span={{ lg: 10, base: 12 }}>
            <Flex
              gap={12}
              wrap="nowrap"
              justify="space-between"
              align="center"
              mb={{ base: 'sm' }}
              onClick={toggleAdvancedSettings}
              style={{ cursor: 'pointer' }}
            >
              <Text size="sm" fw={600} my="xl" style={{ whiteSpace: 'nowrap' }}>
                Advanced settings
              </Text>

              <Divider w="100%" />
              <ActionIcon
                size={26}
                radius={20}
                variant="default"
                aria-label="Advanced settings"
              >
                {openedAdvancedSettings ? (
                  <IconChevronUp size={16} stroke={1.5} />
                ) : (
                  <IconChevronDown size={16} stroke={1.5} />
                )}
              </ActionIcon>
            </Flex>
          </Grid.Col>
        )}
      </Grid>
      <Grid>
        <Grid.Col>
          <Collapse in={openedAdvancedSettings}>
            <Grid>
              <Grid.Col span={{ md: 6, lg: 5, base: 12 }}>
                <Form.Slider
                  required
                  showEdit
                  label="Speed"
                  name="speech_speed"
                  defaultValue={form.values.speech_speed}
                />
              </Grid.Col>

              <Grid.Col span={{ md: 6, lg: 5, base: 12 }}>
                <Form.Slider
                  required
                  showEdit
                  name="curiosity"
                  label="Curiosity"
                  defaultValue={form.values.curiosity}
                />
              </Grid.Col>

              <Grid.Col span={{ md: 6, lg: 5, base: 12 }}>
                <Form.Slider
                  required
                  showEdit
                  name="surprise"
                  label="Surprise"
                  defaultValue={form.values.surprise}
                />
              </Grid.Col>

              <Grid.Col span={{ md: 6, lg: 5, base: 12 }}>
                <Form.Slider
                  required
                  showEdit
                  name="positivity"
                  label="Positivity"
                  defaultValue={form.values.positivity}
                />
              </Grid.Col>
            </Grid>
            <PronunciationList />
          </Collapse>
        </Grid.Col>
      </Grid>
    </Box>
  );
};

export default AttendantForm;
