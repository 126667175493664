type Conditions = Record<string, boolean>;

const getClassNames = (baseClass: string, conditions: Conditions): string => {
  const classNames = [baseClass];

  Object.entries(conditions).forEach(([className, condition]) => {
    if (condition) {
      classNames.push(className);
    }
  });

  return classNames.join(' ');
};

export default getClassNames;
