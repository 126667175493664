import { FC, useState } from 'react';
import { IconAlertCircleFilled, IconTrash } from '@tabler/icons-react';
import {
  Flex,
  Text,
  Group,
  UnstyledButton,
  ActionIcon,
  Checkbox,
} from '@mantine/core';

import getClassNames from 'utils/classNames';
import UserAvatar from 'components/UserAvatar';
import { IUserCreate } from 'interfaces/Agent';

import classes from './StaffListItem.module.scss';
import { useFormComponentContext } from 'context/FormContext';

interface IStaffListItemProps {
  user: IUserCreate;
  userId: string | null;
  setUserId: (id: string) => void;
  index: number;
  checkList: Record<string, boolean>;
  setChecked: (id: string, checked: boolean) => void;
}

const StaffListItem: FC<IStaffListItemProps> = ({
  user,
  userId,
  setUserId,
  index,
  checkList,
  setChecked,
}) => {
  const form = useFormComponentContext();
  const [itemHovered, setItemHovered] = useState(false);

  const getUsersErrorMap = () => {
    return Object.keys(form.errors).reduce<Record<string, number>>(
      (acc, key) => {
        const index = key.split('.')[1];
        acc[index] = (acc[index] || 0) + 1;
        return acc;
      },
      {}
    );
  };

  //const handleCheck = () => {
  //  setChecked(user.id, !checkList[user.id]);
  //};

  //const checkedCount = Object.values(checkList).filter(Boolean).length;

  return (
    <UnstyledButton
      px="md"
      gap="sm"
      onMouseLeave={() => setItemHovered(false)}
      onMouseEnter={() => setItemHovered(true)}
      wrap="nowrap"
      component={Group}
      className={getClassNames(classes.item, {
        [classes.itemActive]: userId === user.id,
      })}
      onClick={() => setUserId(user.id)}
    >
      <UserAvatar user={user} />
      <div style={{ flex: 1 }}>
        <Flex align="center" justify="space-between">
          <Text
            fz="sm"
            fw={500}
            maw={{ base: '12rem', sm: '25rem', md: '15rem', lg: '12rem' }}
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {user.name || 'Unnamed'}
          </Text>
          <Flex gap={4} align="center">
            {getUsersErrorMap()[index] && (
              <IconAlertCircleFilled
                color="#ff000060"
                size={16}
                strokeWidth={1.5}
              />
            )}
            {/*{checkedCount > 0 ? (
              <Checkbox
                checked={checkList[user.id]}
                onChange={(e) => {
                  e.stopPropagation();
                  handleCheck();
                }}
                size="xs"
                styles={{
                  input: { width: 16, height: 16, borderRadius: '50%' },
                  icon: { width: 8, height: 8 },
                }}
              />
            ) : itemHovered ? (
              <ActionIcon
                onClick={(e) => {
                  e.stopPropagation();
                  handleCheck();
                }}
                variant="subtle"
                color="var(--mantine-color-red-6)"
                size="xs"
              >
                <IconTrash stroke={1.5} />
              </ActionIcon>
            ) : null}*/}
          </Flex>
        </Flex>
        <Text
          span
          fz="xs"
          maw={{ base: '10rem', md: '12rem' }}
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {user.email || 'No email'}
        </Text>
      </div>
    </UnstyledButton>
  );
};

export default StaffListItem;
