import { useEffect, useState } from 'react';
import {
  Modal,
  Text,
  Stack,
  Button,
  Flex,
  Loader,
  Divider,
  Alert,
} from '@mantine/core';
import { z } from 'zod';
import Form from 'components/form';
import { useContext } from 'react';
import AuthContext from 'context/AuthContext';
import useFormHandler from 'hooks/useFormHandler';
import { schemaFields } from 'utils/schemaBuilder';
import { useNotification } from 'context/NotificationContext';
import { useDebouncedValue } from '@mantine/hooks';
import DeleteAccountModal from './DeleteAccountModal';

interface AccountSettingsModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const AccountSettingsModal = ({
  isOpen,
  onClose,
}: AccountSettingsModalProps) => {
  const { showSuccess, showError } = useNotification();
  const {
    data: { account },
    actions: { updateAccount, forgotPassword, checkAvailableEmail },
  } = useContext(AuthContext);
  const [emailCheckLoading, setEmailCheckLoading] = useState(false);
  const [emailValue, setEmailValue] = useState('');
  const [debouncedEmail] = useDebouncedValue(emailValue, 500);

  const [resetLoading, setResetLoading] = useState(false);
  const [showDeleteAccount, setShowDeleteAccount] = useState(false);
  const [resetSuccess, setResetSuccess] = useState<string | null>(null);

  const initialValues = {
    email: account?.username || '',
  };

  const handleResetPassword = async () => {
    try {
      setResetLoading(true);
      await forgotPassword(account?.username || '');
      setResetSuccess(`Password reset link sent to ${account?.username}`);
    } catch (error) {
      showError('Failed to send reset password link');
    } finally {
      setResetLoading(false);
    }
  };

  const formSchema = z.object({
    email: schemaFields.email.schema,
  });

  const {
    form,
    error,
    isValid,
    setError,
    loading: updateEmailLoading,
  } = useFormHandler(formSchema, {
    initialValues,
  });

  useEffect(() => {
    if (!isOpen) {
      form.setFieldValue('email', initialValues.email);
      setResetSuccess(null);
      setEmailCheckLoading(false);
    }
  }, [isOpen]);

  useEffect(() => {
    const validateEmail = async () => {
      if (!debouncedEmail || debouncedEmail === account?.username) {
        setEmailCheckLoading(false);
        return;
      }

      const errorMessage = 'Email is already in use';

      try {
        const isAvailable = await checkAvailableEmail(debouncedEmail);

        if (!isAvailable) {
          form.setErrors({
            email: errorMessage,
          });
        }
      } catch (error: any) {
        if (error.status === 400) {
          form.setErrors({
            email: errorMessage,
          });
        }
      } finally {
        setEmailCheckLoading(false);
      }
    };

    validateEmail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedEmail]);

  const onUpdateEmail = async (values: z.infer<typeof formSchema>) => {
    try {
      await updateAccount(values.email);
      showSuccess('Email updated successfully');
      onClose();
    } catch (error) {
      setError('An error occurred. Please try again.');
    }
  };

  return (
    <>
      <Modal
        centered
        size="lg"
        radius="lg"
        shadow="lg"
        padding="lg"
        opened={isOpen}
        onClose={onClose}
        title={
          <Text fw="600" size="xl">
            Account
          </Text>
        }
      >
        <Stack>
          <Stack gap="xs" mt="md">
            <Form form={form} error={error} onSubmit={onUpdateEmail}>
              <Flex direction="column" gap={0} mb={10}>
                <Text size="sm" fw={500} mb={7}>
                  Email
                </Text>
                <Flex
                  gap="md"
                  justify="space-between"
                  direction={{ base: 'column', sm: 'row' }}
                >
                  <Form.Input
                    m={0}
                    flex={1}
                    size="md"
                    name="email"
                    type="email"
                    loading={emailCheckLoading}
                    placeholder="Email"
                    onChange={(value: string | number) => {
                      if (value !== account?.username) {
                        setEmailCheckLoading(true);
                      }
                      setEmailValue(value as string);
                    }}
                    onBlur={() => {
                      // do nothing
                    }}
                  />
                  <Form.Submit
                    mt={0}
                    size="md"
                    h="2.75rem"
                    w={{ base: '100%', sm: 'fit-content' }}
                    variant="default"
                    loading={updateEmailLoading}
                    disabled={
                      !isValid ||
                      updateEmailLoading ||
                      emailCheckLoading ||
                      account?.username === form.values.email
                    }
                  >
                    Update email
                  </Form.Submit>
                </Flex>
              </Flex>
            </Form>
          </Stack>

          <Stack gap="xs" mt="sm">
            <Text size="md" mb="sm" fw={500}>
              Reset your password
            </Text>
            {resetSuccess ? (
              <Alert
                py={8.15}
                px="md"
                variant="default"
                radius="sm"
                bg="var(--bg-color)"
              >
                Password reset link sent to {account?.username}
              </Alert>
            ) : (
              <Button
                fullWidth
                onClick={handleResetPassword}
                rightSection={
                  resetLoading ? <Loader size="14" color="#adb5bd" /> : null
                }
                disabled={resetLoading}
              >
                Send password reset link
              </Button>
            )}
          </Stack>
          <Divider my="md" />

          <Button
            color="var(--error-color)"
            variant="light"
            styles={{
              root: {
                backgroundColor: '#BB7B7B17',
              },
            }}
            onClick={() => {
              onClose();
              setShowDeleteAccount(true);
            }}
          >
            Close account
          </Button>
        </Stack>
      </Modal>
      <DeleteAccountModal
        isOpen={showDeleteAccount}
        onClose={() => setShowDeleteAccount(false)}
      />
    </>
  );
};

export default AccountSettingsModal;
