import { IconClock, IconAlertCircle } from '@tabler/icons-react';
import { Box, Grid, Stack, Text, Group, Flex } from '@mantine/core';

import Form from 'components/form';
import { FC, useContext } from 'react';
import { getNames } from 'utils/string';
import AuthContext from 'context/AuthContext';
import { UpgradeWrapper } from 'components/upgrade';
import { AccessLevel } from 'interfaces/GetUserResult';
import { IUserCreate, Status } from 'interfaces/Agent';
import { useFormComponentContext } from 'context/FormContext';
import { checkAvailability, newAvailabilty } from '../schema';

interface IStaffFormProps {
  userId: string | null;
  users: IUserCreate[];
}

const AVAILABILITY_OPTIONS = [
  { value: Status.AVAILABLE, label: 'Available - Send calls through' },
  { value: Status.AWAY, label: 'Unavailable - Take a message' },
  {
    value: Status.SCHEDULE,
    label: 'Schedule - Accept calls based on timetable',
  },
];

const DAYS_MAP = {
  monday: 'Mon',
  tuesday: 'Tue',
  wednesday: 'Wed',
  thursday: 'Thu',
  friday: 'Fri',
  saturday: 'Sat',
  sunday: 'Sun',
};

const TIME_OPTIONS = Array.from({ length: 24 }, (_, i) => {
  const hour = i.toString().padStart(2, '0');
  const displayHour = (i % 12 || 12).toString();
  const ampm = i < 12 ? 'AM' : 'PM';

  return {
    value: `${hour}:00`,
    label: `${displayHour}:00 ${ampm}`,
  };
});

TIME_OPTIONS.push({
  value: '23:59',
  label: 'End of day',
});

const StaffForm: FC<IStaffFormProps> = ({ userId, users }) => {
  const currentIndex = users.findIndex((user) => user.id === userId);
  const index = currentIndex >= 0 ? currentIndex : 0;
  const form = useFormComponentContext();
  const availabilityDisabled =
    form.values.users[index]?.status !== Status.SCHEDULE;
  const {
    data: { account },
  } = useContext(AuthContext);
  const isPremium = account?.access_level === AccessLevel.PRO;

  const onChangeStatus = (value: string | null) => {
    if (value === Status.SCHEDULE) {
      let availability = form.values.users[index]?.availability;

      const isAvailabilityFilled = checkAvailability(availability);

      if (!isAvailabilityFilled) {
        availability = newAvailabilty;
        form.setFieldValue(`users.${index}.availability`, availability);
      }
    } else {
      form.setErrors({
        [`users.${index}.availability`]: undefined,
      });
    }
  };

  return (
    <Box my="sm">
      <Grid gutter={20}>
        <Grid.Col span={{ lg: 5 }}>
          <Form.Input
            required
            showEdit
            autoFocus
            type="text"
            label="Name"
            name={`users.${index}.name`}
            placeholder="Enter staff member full name"
          />
        </Grid.Col>
        <Grid.Col span={{ lg: 5 }}>
          <Form.Input
            required
            showEdit
            type="email"
            label="Email"
            name={`users.${index}.email`}
            placeholder="Enter staff member email address"
          />
        </Grid.Col>
        <Grid.Col span={{ lg: 5 }}>
          <Form.PhoneInput
            required
            showEdit
            name={`users.${index}.phone`}
            label="Phone number"
            placeholder="Enter your phone number"
          />
        </Grid.Col>
        {false && (
          <>
            <Grid.Col span={{ lg: 5 }}>
              <Form.Input
                showEdit
                name={`users.${index}.job_title`}
                label="Job title"
                placeholder="Sales Representative"
              />
            </Grid.Col>
            <Grid.Col span={{ lg: 10 }}>
              <Form.Timezone
                required
                showEdit
                label="Timezone"
                placeholder="Select timezone"
                name={`users.${index}.time_zone`}
              />
            </Grid.Col>
          </>
        )}
        <Grid.Col span={{ lg: 10, base: 12 }}>
          <Form.Tags
            showEdit
            name={`users.${index}.tags`}
            label="Team member roles"
            placeholder="Add role (press enter to add)"
            inputWrapperOrder={['label', 'input', 'description', 'error']}
            description="Allows your Attendant to handle calls based on job title or duties."
          />
        </Grid.Col>
        <Grid.Col span={{ lg: 5, base: 12 }}>
          <Form.Select
            required
            showEdit
            withAsterisk={false}
            name={`users.${index}.status`}
            label="Availability status"
            placeholder={AVAILABILITY_OPTIONS[0].label}
            description="When your Attendant connects callers to you."
            inputWrapperOrder={['label', 'input', 'description', 'error']}
            options={AVAILABILITY_OPTIONS}
            onChange={onChangeStatus}
          />
        </Grid.Col>
        <Grid.Col span={{ lg: 5, base: 12 }}>
          <Text size="sm" fw={500} mb="xs">
            Notification method
          </Text>
          <Group gap="0.4rem" py="md" pb="lg">
            <UpgradeWrapper
              as={Form.SwitchRadio}
              upgradeTitle="Get instant missed call alerts!"
              upgradeDescription="Get your messages as an SMS text message instantly on your mobile phone"
              componentProps={{
                styles: {
                  description: {
                    marginTop: '1.4rem',
                  },
                },
                description:
                  'How your Attendant delivers messages from your callers to you.',
                inputWrapperOrder: ['label', 'input', 'description', 'error'],
                name: `users.${index}.prefered_communication`,
                showEdit: true,
                options: [
                  { value: 'email', label: 'Email' },
                  {
                    value: 'text',
                    disabled: !isPremium,
                    label: (
                      <Flex gap="sm" align="center">
                        SMS{' '}
                        {!isPremium && (
                          <IconAlertCircle stroke={1.5} size={18} />
                        )}
                      </Flex>
                    ),
                  },
                ],
              }}
            />
          </Group>
        </Grid.Col>
        <Grid.Col span={12}>
          <Text size="sm" fw={500} mb="lg">
            Team member availability
          </Text>
          <Stack gap="md">
            {Object.keys(DAYS_MAP).map((day) => {
              const isDayEnabled =
                form.values.users[index]?.availability?.[day]?.enabled;

              return (
                <Flex
                  key={day}
                  align="flex-start"
                  gap={{ md: 'xs', base: 'md' }}
                  mb={{ md: 'xs', base: 'md' }}
                  direction={{ md: 'row', base: 'column' }}
                >
                  <Grid>
                    <Grid.Col span={{ sm: 'content' }}>
                      <Form.Switch
                        showEdit
                        containerProps={{ mt: '0.75rem', w: 120 }}
                        disabled={availabilityDisabled}
                        label={DAYS_MAP[day as keyof typeof DAYS_MAP]}
                        name={`users.${index}.availability.${day}.enabled`}
                        onChange={(value) => {
                          if (value && form.errors[`users.${index}.status`]) {
                            form.setErrors({
                              [`users.${index}.status`]: undefined,
                            });
                          }
                        }}
                      />
                    </Grid.Col>
                  </Grid>
                  <Grid w="100%">
                    <Grid.Col span={{ lg: 5, base: 6 }}>
                      <Flex gap="sm" align="flex-start" wrap="nowrap">
                        <Text
                          size="sm"
                          mt="0.75rem"
                          style={{
                            opacity: availabilityDisabled ? 0.3 : 1,
                          }}
                        >
                          From:
                        </Text>
                        <Form.Select
                          w="90%"
                          mb="sm"
                          clearable
                          showEdit
                          disabled={availabilityDisabled || !isDayEnabled}
                          styles={{ root: { marginBottom: 0 } }}
                          name={`users.${index}.availability.${day}.start`}
                          placeholder="Start time"
                          options={TIME_OPTIONS}
                          leftSection={
                            <IconClock
                              size={16}
                              color="var(--mantine-color-gray-4)"
                            />
                          }
                        />
                      </Flex>
                    </Grid.Col>
                    <Grid.Col span={{ lg: 5, base: 6 }}>
                      <Group
                        gap="sm"
                        align="flex-start"
                        justify="center"
                        wrap="nowrap"
                      >
                        <Text
                          size="sm"
                          mt="0.75rem"
                          style={{
                            opacity: availabilityDisabled ? 0.3 : 1,
                          }}
                        >
                          To:
                        </Text>
                        <Form.Select
                          w="90%"
                          clearable
                          showEdit
                          disabled={availabilityDisabled || !isDayEnabled}
                          styles={{ root: { marginBottom: 0 } }}
                          name={`users.${index}.availability.${day}.end`}
                          placeholder="End time"
                          options={TIME_OPTIONS}
                          leftSection={
                            <IconClock
                              size={16}
                              color="var(--mantine-color-gray-4)"
                            />
                          }
                        />
                      </Group>
                    </Grid.Col>
                  </Grid>
                </Flex>
              );
            })}
          </Stack>
        </Grid.Col>
      </Grid>
    </Box>
  );
};

export default StaffForm;
