import { z } from 'zod';
import { useEffect, useState } from 'react';
import { Modal, Text, Stack, Divider } from '@mantine/core';
import { useContext } from 'react';
import AuthContext from 'context/AuthContext';
import Form from 'components/form';
import { useNavigate } from 'react-router-dom';
import routes from 'utils/routes';
import useFormHandler from 'hooks/useFormHandler';
import LogoutModal from './LogoutModal';

interface DeleteAccountModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const DeleteAccountModal = ({ isOpen, onClose }: DeleteAccountModalProps) => {
  const navigate = useNavigate();
  const {
    data: { company },
    actions: { deleteAccount },
  } = useContext(AuthContext);
  const [showLoggingOut, setShowLoggingOut] = useState(false);
  const [loggingOutMessage, setLoggingOutMessage] = useState({
    title: 'Preparing to close account',
    message: 'Please wait while we process your request...',
  });

  const formSchema = z.object({
    name: z.string().min(1, { message: '' }),
  });

  const { form, error, isValid, setError, loading } = useFormHandler(
    formSchema,
    {
      initialValues: {
        name: '',
      },
    }
  );

  useEffect(() => {
    if (!isOpen) {
      form.setFieldValue('name', '');
    }
  }, [isOpen]);

  const handleDeleteAccount = async (values: z.infer<typeof formSchema>) => {
    if (company?.name !== values.name) {
      setError('Incorrect business name');
      return;
    }

    try {
      onClose();
      setShowLoggingOut(true);

      await new Promise((resolve) => setTimeout(resolve, 500));

      await deleteAccount();

      setLoggingOutMessage({
        title: 'Account closed successfully',
        message: 'Signing you out...',
      });

      setTimeout(() => {
        navigate(routes.signInPath.href);
      }, 2000);
    } catch (error) {
      setShowLoggingOut(false);
      setError('An error occurred. Please try again.');
    }
  };

  return (
    <>
      <Modal
        centered
        size="lg"
        radius="lg"
        shadow="lg"
        padding="lg"
        opened={isOpen}
        onClose={onClose}
        title={
          <Text fw={600} size="xl">
            Close Account
          </Text>
        }
      >
        <Stack>
          <Text size="sm">
            Are you sure you want to close your account and miss out on all the
            wonderful features of Nucleus?
          </Text>
          <Text size="sm">
            Closing your account will forfeit any remaining time on any paid
            subscriptions. This process is permanent and not reversible. To
            close your account, type your business name <b>"{company?.name}"</b>{' '}
            in the box below to confirm.
          </Text>

          <Divider my="md" />

          <Form form={form} error={error} onSubmit={handleDeleteAccount}>
            <Form.Input
              size="md"
              name="name"
              placeholder="Type business name to confirm"
            />
            <Form.Submit
              mt="lg"
              size="md"
              variant="default"
              loading={loading}
              disabled={
                !isValid || loading || company?.name !== form.values.name
              }
            >
              Close account
            </Form.Submit>
          </Form>
        </Stack>
      </Modal>

      <LogoutModal
        isOpen={showLoggingOut}
        title={loggingOutMessage.title}
        message={loggingOutMessage.message}
      />
    </>
  );
};

export default DeleteAccountModal;
