import { useState } from 'react';
import {
  Text,
  Flex,
  Title,
  Group,
  Stack,
  Modal,
  Switch,
  Button,
  Divider,
  Container,
} from '@mantine/core';
import { IconCheck } from '@tabler/icons-react';
import { useContext } from 'react';
import Badge from 'components/Badge';
import { useNavigate } from 'react-router-dom';
import BillingContext, {
  PRO_MONTH_PRICE_NAME,
  PRO_YEAR_PRICE_NAME,
} from 'context/BillingContext';
import routes from 'utils/routes';
import { AccessLevel } from 'interfaces/GetUserResult';
import useStripeUrlHelper from 'hooks/useStripeUrlHelper';

import classes from './Pricing.module.scss';
import AuthContext from 'context/AuthContext';
import dayjs from 'dayjs';
import useStripeTrial from 'hooks/useStripeTrial';

const price = 20;

const prices = {
  MONTHLY: price * 12,
  ANNUAL: price * 10,
};

const freeFeatures = [
  'Interactive natural language AI Attendant',
  'New US or Canadian phone number',
  '100% incoming calls answered; Unlimited usage',
  'Calls transferred to any team member in US or Canada',
  'Add up to 10 team members',
  'Get messages outside of available hours',
  'Customize your Attendant greetings for a tailored experince',
  'Add up to 1000 characters of business information to teach your AI employee',
];

const premiumFeatures = [
  'Get messages instantly by SMS text message on your mobile phone',
  'Add up to 10,000 characters of business information to teach your AI employee',
  'Additional high-quality voice options',
  'Add up to 100 team members',
  'Bring your existing business number',
];

const disclaimers: Record<string, Record<number, string>> = {
  [AccessLevel.FREE]: {
    3: '(some limitations apply)',
  },
};
const PricingFeature = ({
  children,
  disclaimer,
}: {
  children: React.ReactNode;
  disclaimer?: string;
}) => (
  <Flex gap="sm" align="flex-start">
    <span>
      <IconCheck
        size={18}
        strokeWidth={1.5}
        style={{ color: 'var(--green-color)' }}
      />
    </span>
    <Flex direction="column" gap="xs">
      <Text size="sm">{children}</Text>
      {disclaimer && (
        <Text c="dimmed" style={{ fontSize: 10, marginTop: -8 }}>
          {disclaimer}
        </Text>
      )}
    </Flex>
  </Flex>
);

const UpgradePricing = () => {
  const [isAnnual, setIsAnnual] = useState(true);
  const navigate = useNavigate();
  const { getRedirectRoute } = useStripeUrlHelper();
  const { isOnTrial } = useStripeTrial();

  const {
    data: { pricingOpened },
    actions: { setPricingOpened },
  } = useContext(BillingContext);

  const {
    data: { stripe },
  } = useContext(AuthContext);

  const startStripeCheckout = async () => {
    const lookupKey = isAnnual ? PRO_YEAR_PRICE_NAME : PRO_MONTH_PRICE_NAME;

    if (isOnTrial) {
      const now = dayjs().utc();
      const promoCodeExpiry = dayjs.utc(stripe?.promo_code_expiry);
      const timeToExpiry = promoCodeExpiry.diff(now, 'seconds');
      let url = `${routes.trialUpgradePath.href}?lookup_key=${lookupKey}&redirect=${getRedirectRoute()}`;

      if (stripe?.promo_code && timeToExpiry > 0) {
        url = `${url}&promo_code=${stripe?.promo_code}`;
      }

      setPricingOpened(false);
      return navigate(url);
    }

    let url = `${routes.upgradePath.href}?lookup_key=${lookupKey}&redirect=${getRedirectRoute()}`;
    setPricingOpened(false);
    navigate(url);
  };

  return (
    <Modal
      radius="lg"
      shadow="lg"
      size="auto"
      opened={pricingOpened}
      onClose={() => setPricingOpened(false)}
      styles={{
        header: {
          minHeight: 0,
          paddingBottom: 0,
        },
      }}
    >
      <Container size="lg" pb={28} px={{ base: 'xs', lg: 'xl' }}>
        <Stack align="center" mb={30} gap={2}>
          <Title fz={{ base: 20, lg: 24 }}>Pricing</Title>
          <Text fz={14} ta="center" c="#1A1A1AB2">
            Simple pricing, no hidden fees
          </Text>
          <Group align="center" mt={20}>
            <Switch
              size="md"
              checked={isAnnual}
              onChange={(e) => setIsAnnual(e.currentTarget.checked)}
              label={
                <Group gap={8} fw={500} fs="lg">
                  <Text fw={600} size="md">
                    Annual
                  </Text>
                  <Text fw={600} size="md" c="dimmed" opacity={0.7}>
                    (get 2 months free)
                  </Text>
                </Group>
              }
            />
          </Group>
        </Stack>

        <Flex
          gap={{ base: 'md', lg: 0 }}
          align="stretch"
          direction={{ base: 'column', lg: 'row' }}
        >
          {/* Free Plan */}
          <Stack className={classes.leftPlan} gap={14}>
            <Badge
              variant="light"
              color="var(--primary-color)"
              style={{ fontSize: 12 }}
            >
              Starter
            </Badge>
            <Group align="flex-end" style={{ columnGap: 5 }}>
              <Title>Free</Title>
            </Group>
            <Text size="sm" c="dimmed">
              Ideal for startups & solo enterpreneurs
            </Text>
            <Divider />

            <Stack gap={14} my={10}>
              <Text fw={500} size="sm">
                What's included?
              </Text>
              {freeFeatures.map((feature, index) => (
                <PricingFeature
                  key={feature}
                  disclaimer={disclaimers[AccessLevel.FREE][index]}
                >
                  {feature}
                </PricingFeature>
              ))}
            </Stack>

            {!isOnTrial && (
              <Button
                radius="md"
                variant="default"
                mt="auto"
                onClick={() => setPricingOpened(false)}
              >
                Continue using free
              </Button>
            )}
          </Stack>

          {/* Premium Plan */}
          <Stack className={classes.rightPlan} gap={14}>
            <Badge
              variant="light"
              color="var(--green-color)"
              style={{ fontSize: 12 }}
            >
              Premium
            </Badge>
            <Group align="flex-end" style={{ columnGap: 5 }}>
              <Title>
                ${isAnnual ? (prices.ANNUAL / 12).toFixed(2) : price}
              </Title>
              <Text lh={2.5} size="sm" c="dimmed">
                /per month{' '}
                {isAnnual ? `($${prices.ANNUAL} billed annually)` : ''}
              </Text>
            </Group>
            <Text size="sm" c="dimmed">
              Ideal for growing businesses
            </Text>
            <Divider />

            <Stack gap={14} my={10}>
              <Text fw={500} size="sm">
                Includes all free features plus
              </Text>
              {premiumFeatures.map((feature, index) => (
                <PricingFeature
                  key={feature}
                  disclaimer={disclaimers[AccessLevel.PRO]?.[index]}
                >
                  {feature}
                </PricingFeature>
              ))}
            </Stack>

            <Button
              mt="auto"
              radius="md"
              color="var(--green-color)"
              onClick={startStripeCheckout}
            >
              {isOnTrial
                ? 'Continue with Premium - Add Payment'
                : 'Upgrade now'}
            </Button>
          </Stack>
        </Flex>
      </Container>
    </Modal>
  );
};

export default UpgradePricing;
