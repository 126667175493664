import { z } from 'zod';
import { useEffect } from 'react';
import { useLocalStorage } from '@mantine/hooks';
import { STORAGE_CURRENT_FORM } from 'constants/localStorage';
import useFormHandler from './useFormHandler';
import { useFormContext } from 'context/FormContext';
import { useNotification } from 'context/NotificationContext';
import { getObjectDiff } from 'utils/object';

interface IFormPersistProps {
  id: string;
  defaultValues: any;
  formSchema: z.ZodSchema<any>;
  onSubmit: (
    values: any,
    setError: () => void,
    onSuccess: (values: any) => void
  ) => void;
}

export const useFormPersist = ({
  id,
  formSchema,
  onSubmit,
  defaultValues,
}: IFormPersistProps) => {
  const {
    setLoading,
    setOnSubmit,
    setCurrentForm,
    setDefaultValues,
    setSuccess,
    setError,
  } = useFormContext();
  const { showSuccess, showError } = useNotification();

  const getDefaultValues = (defaultValues: any) => {
    const storedData = localStorage.getItem(STORAGE_CURRENT_FORM);

    if (storedData) {
      const parsedData = JSON.parse(storedData);

      if (Object.keys(parsedData[id] || {}).length > 0) {
        return parsedData;
      } else {
        return { ...parsedData, [id]: defaultValues };
      }
    }
    return { [id]: defaultValues };
  };

  const [formValues, setFormValues] = useLocalStorage({
    key: STORAGE_CURRENT_FORM,
    defaultValue: getDefaultValues(defaultValues),
  });

  const { form, error, loading } = useFormHandler(formSchema, {
    initialValues: formValues[id],
  });

  const onSuccess = (values: any) => {
    setSuccess?.(true);
    showSuccess('Your changes have been saved and are now live');
    setFormValues({ ...formValues, [id]: values });
    setDefaultValues?.(values);
    form.setValues(values);
    form.resetDirty();
    setCurrentForm?.(form);
  };

  const onError = (error?: any) => {
    setError?.(error);
    if (error?.status === 400 && error.response?.data?.detail) {
      showError(error.response?.data?.detail);
      return;
    }
    showError('An error occurred while saving your changes');
  };

  const handleSubmit = async (values: any) => {
    return onSubmit(values, onError, onSuccess);
  };

  useEffect(() => {
    const dirtyFields = getObjectDiff(form.values, defaultValues);
    if (dirtyFields.length > 0) {
      const dirtyObject = dirtyFields.reduce(
        (acc, field) => {
          acc[field] = true;
          return acc;
        },
        {} as Record<string, boolean>
      );

      form.setDirty(dirtyObject);
    } else {
      form.setDirty({});
    }
    setCurrentForm?.(form);
    setFormValues({ ...formValues, [id]: form.values });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.values]);

  useEffect(() => {
    setCurrentForm?.(form);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form?.errors]);

  useEffect(() => {
    setLoading?.(loading);
  }, [loading, setLoading]);

  useEffect(() => {
    setCurrentForm?.(form);
    setDefaultValues?.(defaultValues);
    setOnSubmit?.(() => form.onSubmit(handleSubmit));

    return () => {
      setCurrentForm?.(undefined);
      setOnSubmit?.(undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { form, error, handleSubmit, defaultValues };
};
