import { FC } from 'react';
import { TagsInput, TagsInputProps } from '@mantine/core';

import { useFormComponentContext } from 'context/FormContext';
import useEditStyles from '../../hooks/useEditStyles';

interface FormTagsProps extends TagsInputProps {
  name: string;
  label?: string;
  error?: string;
  showEdit?: boolean;
  required?: boolean;
  placeholder?: string;
  description?: string;
  options?: { value: string; label: string }[];
}

const FormTags: FC<FormTagsProps> = ({
  name,
  label,
  options,
  required,
  description,
  placeholder,
  showEdit = false,
  ...props
}) => {
  const form = useFormComponentContext();
  const { editedInputStyles } = useEditStyles({ name, showEdit });

  return (
    <TagsInput
      label={label}
      required={required}
      data={options || []}
      description={description}
      placeholder={placeholder}
      styles={{
        pill: {
          borderRadius: '0.3rem',
          backgroundColor: 'var(--bg-color)',
          border: '1px solid var(--mantine-color-gray-4)',
        },
        input: {
          border: '1px solid var(--mantine-color-gray-4)',
        },
        ...editedInputStyles,
      }}
      {...form.getInputProps(name)}
      {...props}
    />
  );
};

export default FormTags;
