import React from 'react';
import { Text, Stack, Button, Container } from '@mantine/core';
import { Link } from 'react-router-dom';
interface Props {
  children: React.ReactNode;
}

interface State {
  hasError: boolean;
  error?: Error;
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error): State {
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error('Error:', error);
    console.error('Error Info:', errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Container size="sm">
          <Stack align="center" justify="center" h="100vh" gap="lg">
            <Text size="xl" fw={500}>
              Something went wrong
            </Text>
            <Text c="dimmed">
              We apologize for the inconvenience. Please try refreshing the
              page.
            </Text>
            <Button component={Link} to="/">
              Go Home
            </Button>
          </Stack>
        </Container>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
