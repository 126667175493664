import {
  rgba,
  darken,
  lighten,
  MantineProvider,
  parseThemeColor,
  VariantColorsResolver,
  Badge as MantineBadge,
  defaultVariantColorsResolver,
  BadgeProps as MantineBadgeProps,
} from '@mantine/core';
import { FC } from 'react';

interface BadgeProps extends MantineBadgeProps {
  children: React.ReactNode;
}

const variantColorResolver: VariantColorsResolver = (input) => {
  const defaultResolvedColors = defaultVariantColorsResolver(input);
  const parsedColor = parseThemeColor({
    color: input.color || input.theme.primaryColor,
    theme: input.theme,
  });

  if (input.variant === 'light') {
    return {
      background: rgba(parsedColor.value, 0.1),
      hover: rgba(parsedColor.value, 0.15),
      border: `1px solid ${lighten(parsedColor.value, 0.6)}`,
      color: darken(parsedColor.value, 0.1),
    };
  }

  return defaultResolvedColors;
};

const Badge: FC<BadgeProps> = ({ children, ...props }) => {
  return (
    <MantineProvider theme={{ variantColorResolver }}>
      <MantineBadge fw={500} radius="sm" tt="capitalize" p="xs" {...props}>
        {children}
      </MantineBadge>
    </MantineProvider>
  );
};

export default Badge;
