import { FC } from 'react';
import { Loader, TextInput, TextInputProps } from '@mantine/core';

import useEditStyles from 'hooks/useEditStyles';
import { useFormComponentContext } from 'context/FormContext';

interface FormInputProps extends Omit<TextInputProps, 'onChange'> {
  name: string;
  label?: string;
  error?: string;
  loading?: boolean;
  required?: boolean;
  showEdit?: boolean;
  placeholder?: string;
  description?: string;
  onChange?: (value: string | number) => void;
}

const FormInput: FC<FormInputProps> = ({
  name,
  type,
  label,
  loading,
  onChange,
  required,
  placeholder,
  description,
  showEdit = false,
  ...props
}) => {
  const form = useFormComponentContext();
  const { editedInputStyles } = useEditStyles({ name, showEdit });

  const rightSection = loading ? <Loader size="14" color="#adb5bd" /> : null;

  return (
    <TextInput
      type="text"
      label={label}
      required={required}
      placeholder={placeholder}
      description={description}
      rightSection={rightSection}
      styles={{ ...editedInputStyles, ...props.styles }}
      {...form.getInputProps(name)}
      {...props}
      onChange={(e) => {
        onChange?.(e.target.value);
        form.getInputProps(name).onChange(e);
      }}
    />
  );
};

export default FormInput;
