import { FC, ReactNode, useEffect, useState } from 'react';
import { Flex, Group, Switch, SwitchGroupProps } from '@mantine/core';

import { useFormComponentContext } from 'context/FormContext';
import { UpgradeWrapper } from 'components/upgrade';
import useEditStyles from 'hooks/useEditStyles';

interface FormSwitchRadioProps
  extends Omit<SwitchGroupProps, 'onChange' | 'children'> {
  name: string;
  label?: ReactNode;
  error?: string;
  required?: boolean;
  showEdit?: boolean;
  description?: string;
  onChange?: (value: string | number) => void;
  options: {
    value: string;
    label: ReactNode;
    disabled?: boolean;
    wrap?: typeof UpgradeWrapper;
    wrapperProps?: any;
  }[];
}

const FormSwitchRadio: FC<FormSwitchRadioProps> = ({
  name,
  label,
  options,
  onChange,
  required,
  description,
  showEdit = false,
  ...props
}) => {
  const form = useFormComponentContext();
  const { editedSwitchStyles } = useEditStyles({ name, showEdit });
  const [value, setValue] = useState<string[]>([]);

  useEffect(() => {
    const fieldValue = name
      .split('.')
      .reduce((obj, key) => obj?.[key], form.values);
    setValue(fieldValue ? [fieldValue] : []);
  }, [form.values, name]);

  return (
    <Switch.Group
      label={label}
      value={value}
      required={required}
      description={description}
      error={form.getInputProps(name).error}
      {...props}
      onChange={(val) => {
        const value = val[val.length - 1];
        const option = options.find((option) => option.value === value);

        if (!value) return;

        if (!option?.disabled) {
          setValue([value]);
          form.setFieldValue(name, value);
        }
        onChange?.(value);
      }}
    >
      <Group gap="lg" mb="md">
        {options.map((option) => (
          <Flex key={option.value} gap="sm" wrap="nowrap">
            <Switch
              value={option.value}
              label={option.label}
              styles={editedSwitchStyles}
              //styles={{
              //  track: {
              //    borderRadius: '4px',
              //  },
              //  thumb: {
              //    width: '0.9rem',
              //    borderRadius: '2px',
              //  },
              //}}
            />
          </Flex>
        ))}
      </Group>
    </Switch.Group>
  );
};

export default FormSwitchRadio;
