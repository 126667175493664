import { useContext } from 'react';
import { Center, Loader, Stack } from '@mantine/core';
import { Outlet, Navigate, useLocation } from 'react-router-dom';

import routes from 'utils/routes';
import AuthContext from 'context/AuthContext';

const ProtectedRoute = () => {
  const location = useLocation();
  const {
    data: { userFetched, registerEmail, newSignup },
    actions: { isLoggedIn },
  } = useContext(AuthContext);

  if (!userFetched) {
    return (
      <Center h="100vh">
        <Stack align="center">
          <Loader size="md" />
        </Stack>
      </Center>
    );
  }

  const isAuthenticated = isLoggedIn();
  const isRegistrationFlow = !!registerEmail;
  const isWelcome = location.pathname === routes.welcomePath.href;
  const isOnboarding = location.pathname === routes.onboardingPath.href;

  if ((isRegistrationFlow && isOnboarding) || (newSignup && isWelcome)) {
    return <Outlet />;
  }

  if (newSignup) {
    return <Navigate to={routes.welcomePath.href} replace />;
  }

  if (isRegistrationFlow && isOnboarding) {
    return <Outlet />;
  }

  if (isRegistrationFlow && !isOnboarding) {
    return <Navigate to={routes.onboardingPath.href} replace />;
  }

  if (!isAuthenticated) {
    return <Navigate to={routes.signInPath.href} replace />;
  }

  if (isAuthenticated && (isOnboarding || isWelcome)) {
    return <Navigate to={routes.attendantPath.href} replace />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
