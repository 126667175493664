import { v4 as uuidv4 } from 'uuid';
import { Grid } from '@mantine/core';
import { useEffect, useState } from 'react';

import StaffList from './StaffList';
import StaffInfo from './StaffInfo';
import Container from '../Container';
import EmptyState from './EmptyState';
import { emptyAvailabilty } from '../schema';
import { useDeviceType } from 'hooks/useDeviceType';
import { CommunicationType } from 'interfaces/Agent';
import ScrollSection from 'components/ScrollSection';
import { IUserCreate, Status } from 'interfaces/Agent';
import { useFormComponentContext } from 'context/FormContext';

const StaffDirectory = () => {
  const [userId, setUserId] = useState<string | null>(null);
  const [showList, setShowList] = useState(true);
  const { isTablet } = useDeviceType();

  const form = useFormComponentContext();

  const getAlreadyAddedUser = () => {
    return form.values.users.find((user: IUserCreate) => user.unsaved);
  };

  useEffect(() => {
    const alreadyAddedUser = getAlreadyAddedUser();
    if (isTablet === undefined) return;

    if (
      !isTablet &&
      (!userId ||
        !form.values.users.find((user: IUserCreate) => user.id === userId))
    ) {
      setUserId(alreadyAddedUser?.id || form.values.users?.[0]?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.values.users, userId, isTablet]);

  const handleAddStaff = () => {
    const users = form.values.users;
    const id = uuidv4();

    form.setFieldValue('users', [
      ...users,
      {
        id,
        name: '',
        email: '',
        phone: '',
        unsaved: true,
        status: Status.AVAILABLE,
        availability: emptyAvailabilty,
        prefered_communication: CommunicationType.EMAIL,
      },
    ]);
    setUserId(id);
    setShowList(false);
  };

  const handleSetUserId = (id: string) => {
    setShowList(false);
    if (id === userId) {
      return;
    }

    setUserId(id);
  };

  const handleBack = () => {
    setShowList(true);
  };

  return (
    <Container
      data={form.values.users}
      emptyComponent={<EmptyState onAddStaff={handleAddStaff} />}
    >
      <Grid gutter="lg">
        <Grid.Col
          span={{ sm: 'auto', md: 'content' }}
          visibleFrom={showList ? undefined : 'md'}
        >
          <ScrollSection withsStickyHeader>
            <StaffList
              userId={userId}
              setUserId={handleSetUserId}
              users={form.values.users}
              onAddStaff={handleAddStaff}
            />
          </ScrollSection>
        </Grid.Col>
        <Grid.Col
          span={{ sm: 'auto' }}
          visibleFrom={showList ? 'md' : undefined}
        >
          <ScrollSection>
            <StaffInfo
              key={userId}
              setUserId={setUserId}
              userId={userId}
              onBack={handleBack}
              users={form.values.users}
            />
          </ScrollSection>
        </Grid.Col>
      </Grid>
    </Container>
  );
};

export default StaffDirectory;
