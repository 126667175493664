import { Button, Stack, Text, Box, Image } from '@mantine/core';

interface EmptyStateProps {
  onAddStaff: () => void;
}

const EmptyState = ({ onAddStaff }: EmptyStateProps) => {
  return (
    <Stack gap={0} align="center" justify="center" h="100%" py="xl">
      <Box mb="md">
        <Image
          w={30}
          h={30}
          fit="contain"
          alt="Empty Directory"
          src="/images/folder-delete.svg"
        />
      </Box>

      <Text size="xl" fw={500} mb="xs">
        Looks like there aren't any staff members listed here yet.
      </Text>

      <Text size="sm" c="dimmed" ta="center" mb="lg">
        Click below to add staff members and start building the directory.
      </Text>

      <Button radius="md" onClick={onAddStaff}>
        Add Staff Member
      </Button>
    </Stack>
  );
};

export default EmptyState;
