import { createContext, useContext, ReactNode, useState } from 'react';
import { Box, Notification } from '@mantine/core';
import { IconCheck, IconX } from '@tabler/icons-react';

interface NotificationMessage {
  id: string;
  type: 'error' | 'success' | 'info';
  message: string;
}

interface NotificationContextType {
  showError: (message: string) => void;
  showSuccess: (message: string) => void;
  showInfo: (message: string) => void;
}

const NotificationContext = createContext<NotificationContextType>({
  showError: () => {},
  showSuccess: () => {},
  showInfo: () => {},
});

export const useNotification = () => useContext(NotificationContext);

interface NotificationProviderProps {
  children: ReactNode;
}

export const NotificationProvider = ({
  children,
}: NotificationProviderProps) => {
  const [notifications, setNotifications] = useState<NotificationMessage[]>([]);

  const addNotification = (
    type: 'error' | 'success' | 'info',
    message: string
  ) => {
    const id = Math.random().toString(36).substring(7);
    setNotifications((prev) => [...prev, { id, type, message }]);
    setTimeout(() => {
      setNotifications((prev) => prev.filter((n) => n.id !== id));
    }, 5000);
  };

  const showError = (message: string) => addNotification('error', message);
  const showSuccess = (message: string) => addNotification('success', message);
  const showInfo = (message: string) => addNotification('info', message);

  return (
    <NotificationContext.Provider value={{ showError, showSuccess, showInfo }}>
      {children}
      <Box
        style={{
          position: 'fixed',
          top: 16,
          right: 16,
          zIndex: 1000,
          display: 'flex',
          flexDirection: 'column',
          gap: 8,
        }}
      >
        {notifications.map(({ id, type, message }) => (
          <Notification
            key={id}
            title={
              type === 'error'
                ? 'Error'
                : type === 'success'
                  ? 'Success'
                  : 'Info'
            }
            color={
              type === 'error' ? 'red' : type === 'success' ? 'teal' : 'blue'
            }
            icon={
              type === 'error' ? <IconX size={20} /> : <IconCheck size={20} />
            }
            withCloseButton
            onClose={() =>
              setNotifications((prev) => prev.filter((n) => n.id !== id))
            }
          >
            {message}
          </Notification>
        ))}
      </Box>
    </NotificationContext.Provider>
  );
};
