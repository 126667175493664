import { FC } from 'react';
import { Select, SelectProps } from '@mantine/core';
import { useFormComponentContext } from 'context/FormContext';
import {
  useTimezoneSelect,
  allTimezones,
  ITimezoneOption,
} from 'react-timezone-select';

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import useEditStyles from 'hooks/useEditStyles';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

interface FormTimezoneProps extends SelectProps {
  name: string;
  label?: string;
  error?: string;
  required?: boolean;
  showEdit?: boolean;
  placeholder?: string;
  description?: string;
}

const FormTimezone: FC<FormTimezoneProps> = ({
  name,
  label,
  required,
  description,
  placeholder,
  showEdit = false,
  ...props
}) => {
  let zones: {
    [key: string]: string;
  } = {};

  try {
    Intl.supportedValuesOf('timeZone').forEach(
      (tz: string) => (zones[tz] = tz)
    );
  } catch (error) {
    zones = allTimezones;
  }

  const { options } = useTimezoneSelect({
    labelStyle: 'original',
    timezones: zones,
  });

  const form = useFormComponentContext();
  const { editedSelectStyles } = useEditStyles({ name, showEdit });

  const filterOptions: ITimezoneOption[] = [];

  for (const option of options) {
    const tz = filterOptions.find((o) => option.value === o.value);
    if (tz === undefined) {
      filterOptions.push(option);
    }
  }

  return (
    <Select
      searchable
      label={label}
      required={required}
      allowDeselect={false}
      description={description}
      placeholder={placeholder}
      data={filterOptions || []}
      styles={{ ...editedSelectStyles, ...props.styles }}
      {...form.getInputProps(name)}
      {...props}
    />
  );
};

export default FormTimezone;
