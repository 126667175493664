import { useContext, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import AuthContext from 'context/AuthContext';
import { Center, Loader, Stack } from '@mantine/core';
import routes from 'utils/routes';

const VerifyAdmin = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const {
    actions: { loginToken },
  } = useContext(AuthContext);

  useEffect(() => {
    const checkQuery = async () => {
      const query = searchParams.get('token');
      if (query) {
        try {
          await loginToken(query);
        } catch (e) {}

        setTimeout(() => {
          navigate(routes.attendantPath.href);
        }, 1000);
      }
    };

    checkQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Center h="100vh">
      <Stack align="center">
        <Loader size="md" />
      </Stack>
    </Center>
  );
};

export default VerifyAdmin;
