import { FC } from 'react';
import { UnstyledButtonProps, UnstyledButton } from '@mantine/core';

interface CallButtonProps extends UnstyledButtonProps {
  children: React.ReactNode;
  number: string;
}

const CallButton: FC<CallButtonProps> = ({ number, children, ...props }) => {
  const handleCall = () => {
    window.location.href = `tel:${number}`;
  };

  return (
    <UnstyledButton onClick={handleCall} {...props}>
      {children}
    </UnstyledButton>
  );
};

export default CallButton;
