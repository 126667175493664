import { FC, useEffect, useState } from 'react';
import { Menu, UnstyledButton } from '@mantine/core';
import { IconDotsVertical } from '@tabler/icons-react';
import { useFormContext } from 'context/FormContext';
import { ITeamMember, IUserCreate } from 'interfaces/Agent';
import ConfirmModal from 'components/modal/ConfirmModal';

interface IStaffInfoMenuProps {
  userId: string | null;
  users: IUserCreate[];
  setUserId: (id: string | null) => void;
}

const StaffInfoMenu: FC<IStaffInfoMenuProps> = ({
  userId,
  users,
  setUserId,
}) => {
  const { currentForm, onSubmit } = useFormContext();
  const [showConfirm, setShowConfirm] = useState(false);

  const handleDeleteUser = async () => {
    if (!userId || !currentForm) return;

    const updatedUsers = users.filter(
      (user: ITeamMember) => user.id !== userId
    );
    currentForm.setFieldValue('users', updatedUsers);
    onSubmit?.();
    setShowConfirm(false);
  };

  //useEffect(() => {
  //  if (success) {
  //    setShowConfirm(false);
  //    setLoading(false);
  //  }
  //}, [success]);

  //useEffect(() => {
  //  if (error) {
  //    //setUserId(userId);
  //    setLoading(false);
  //    setShowConfirm(false);
  //    currentForm?.setFieldValue('users', recoveryState.users);
  //  }
  //}, [error]);

  const user = users?.find((u: ITeamMember) => u.id === userId);
  const userName = user?.name || user?.email || 'this user';

  return (
    <>
      <Menu
        width={160}
        withinPortal
        position="bottom-end"
        transitionProps={{ transition: 'pop-top-right' }}
      >
        <Menu.Target>
          <UnstyledButton component={IconDotsVertical} />
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item onClick={() => setShowConfirm(true)}>Delete</Menu.Item>
        </Menu.Dropdown>
      </Menu>

      <ConfirmModal
        isOpen={showConfirm}
        onClose={() => setShowConfirm(false)}
        onConfirm={handleDeleteUser}
        title="Delete User"
        message={`Are you sure you want to delete ${userName}? This action cannot be undone.`}
      />
    </>
  );
};

export default StaffInfoMenu;
