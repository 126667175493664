import {
  IconUser,
  IconHelp,
  IconLogout,
  IconBriefcase2,
} from '@tabler/icons-react';
import { useContext, useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import {
  rem,
  Menu,
  Text,
  Flex,
  Group,
  UnstyledButton,
  Button,
  Anchor,
} from '@mantine/core';

import routes from 'utils/routes';
import getClassNames from 'utils/classNames';
import AuthContext from 'context/AuthContext';
import UserAvatar from 'components/UserAvatar';
import Badge from 'components/Badge';
import classes from './UserProfile.module.scss';
import { AccessLevel } from 'interfaces/GetUserResult';
import BillingContext from 'context/BillingContext';
import AccountSettingsModal from 'components/modal/AccountSettingsModal';
import useStripeUrlHelper from 'hooks/useStripeUrlHelper';

const UserProfile = () => {
  const [userMenuOpened, setUserMenuOpened] = useState(false);
  const { getRedirectRoute } = useStripeUrlHelper();
  const {
    data: { account },
  } = useContext(AuthContext);
  const {
    actions: { setPricingOpened },
  } = useContext(BillingContext);

  const [showAccountSettings, setShowAccountSettings] = useState(false);

  return (
    <>
      <Menu
        radius="lg"
        position="bottom-end"
        opened={userMenuOpened}
        onOpen={() => setUserMenuOpened(true)}
        onClose={() => setUserMenuOpened(false)}
        transitionProps={{ transition: 'pop-top-right' }}
        styles={{
          dropdown: {
            minWidth: 270,
            padding: '0.5rem 1rem',
          },
          item: {
            paddingInline: 0,
          },
        }}
        withinPortal
      >
        <Menu.Target>
          <Flex>
            <UnstyledButton
              hiddenFrom="sm"
              className={getClassNames(classes.user, {
                [classes.userActive]: userMenuOpened,
              })}
            >
              <Flex align="center" justify="space-between">
                <Group gap={8}>
                  <UserAvatar
                    title={account?.username}
                    color="var(--primary-color)"
                  />
                  <Text fw={500} size="sm" lh={1} mr={3}>
                    {account?.username}
                  </Text>
                </Group>
              </Flex>
            </UnstyledButton>
            <UnstyledButton visibleFrom="sm">
              <Flex gap={5} align="center" justify="space-between">
                <Group gap={7}>
                  <UserAvatar
                    title={account?.username}
                    color="var(--primary-color)"
                  />
                </Group>
              </Flex>
            </UnstyledButton>
          </Flex>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Label p={0} className={classes.menuItem}>
            <Flex py="sm" gap="sm" wrap="nowrap">
              <UserAvatar
                size={32}
                title={account?.username}
                color="var(--primary-color)"
              />
              <Flex gap={10} align="center" justify="flex-start">
                <Text span fz="sm">
                  {account?.username || 'No email'}
                </Text>
                <Badge variant="light" color="var(--primary-color)">
                  {account?.access_level === AccessLevel.PRO
                    ? 'Premium'
                    : 'Starter'}
                </Badge>
              </Flex>
            </Flex>
          </Menu.Label>

          <Menu.Divider my={10} />

          <Menu.Item
            mb="md"
            className={classes.menuItem}
            onClick={() => setShowAccountSettings(true)}
            leftSection={
              <IconUser
                style={{ width: rem(16), height: rem(16), marginRight: 6 }}
              />
            }
          >
            {routes.accountPath.title}
          </Menu.Item>
          <Menu.Item
            mb="md"
            component={RouterLink}
            to={`${routes.billingPath.href}?redirect=${getRedirectRoute()}`}
            className={classes.menuItem}
            leftSection={
              <IconBriefcase2
                style={{ width: rem(16), height: rem(16), marginRight: 6 }}
              />
            }
          >
            {routes.billingPath.title}
          </Menu.Item>
          <Menu.Item
            component={RouterLink}
            to={routes.faqsPath.href}
            target="_blank"
            className={classes.menuItem}
            leftSection={
              <IconHelp
                style={{ width: rem(16), height: rem(16), marginRight: 6 }}
              />
            }
          >
            {routes.faqsPath.title}
          </Menu.Item>

          <Menu.Divider my={10} />

          <Menu.Item
            mb="md"
            component={RouterLink}
            to={routes.logoutPath.href}
            className={classes.menuItem}
            leftSection={
              <IconLogout
                style={{ width: rem(16), height: rem(16), marginRight: 6 }}
              />
            }
          >
            {routes.logoutPath.title}
          </Menu.Item>
          {account?.access_level !== AccessLevel.PRO && (
            <Menu.Label px={0} className={classes.menuItem}>
              <Button
                w="100%"
                size="sm"
                radius="md"
                color="var(--green-color)"
                onClick={() => {
                  setPricingOpened(true);
                  setUserMenuOpened(false);
                }}
              >
                Upgrade to Premium
              </Button>
            </Menu.Label>
          )}
          <Flex mt={15} mb={3} gap="xs" align="center" justify="center">
            <Anchor
              fz="xs"
              target="_blank"
              href={routes.privacyPath.href}
              style={{
                borderBottom: 'none',
                textUnderlineOffset: '2px',
                textDecoration: 'auto !important',
                color: 'var(--mantine-color-dimmed)',
              }}
            >
              Privacy Policy
            </Anchor>
            <Text color="var(--mantine-color-dimmed)" fz="sm">
              &#x2022;
            </Text>
            <Anchor
              fz="xs"
              target="_blank"
              href={routes.termsPath.href}
              style={{
                borderBottom: 'none',
                textUnderlineOffset: '2px',
                textDecoration: 'auto !important',
                color: 'var(--mantine-color-dimmed)',
              }}
            >
              Terms of Use
            </Anchor>
          </Flex>
        </Menu.Dropdown>
      </Menu>

      <AccountSettingsModal
        isOpen={showAccountSettings}
        onClose={() => setShowAccountSettings(false)}
      />
    </>
  );
};

export default UserProfile;
