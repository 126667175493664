import { create } from 'zustand';

type CountStore = {
  count: number;
  increment: () => void;
};

const useCountStore = create<CountStore>()((set) => ({
  count: 1,
  increment: () => set((state) => ({ count: state.count + 1 })),
}));

export default useCountStore;
