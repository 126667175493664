import ScrollSection from 'components/ScrollSection';
import AttendantForm from './AttendantForm';

const AttendantSettings = () => {
  return (
    <ScrollSection>
      <AttendantForm />
    </ScrollSection>
  );
};

export default AttendantSettings;
