import { FC, ReactNode } from 'react';
import { Checkbox, CheckboxProps } from '@mantine/core';
import { useFormComponentContext } from 'context/FormContext';

interface FormCheckboxProps extends CheckboxProps {
  name: string;
  error?: string;
  label: ReactNode;
  required?: boolean;
}

const FormCheckbox: FC<FormCheckboxProps> = ({
  name,
  label,
  required,
  ...props
}) => {
  const form = useFormComponentContext();

  return (
    <Checkbox
      label={label}
      required={required}
      {...form.getInputProps(name)}
      {...props}
      error={!!form.getInputProps(name).error}
    />
  );
};

export default FormCheckbox;
