import { FC } from 'react';
import { Flex, FlexProps, Switch, SwitchProps } from '@mantine/core';

import { useFormComponentContext } from 'context/FormContext';
import useEditStyles from 'hooks/useEditStyles';

interface FormSwitchProps extends Omit<SwitchProps, 'onChange' | 'children'> {
  name: string;
  label?: string;
  error?: string;
  required?: boolean;
  showEdit?: boolean;
  description?: string;
  containerProps?: FlexProps;
  onChange?: (value: boolean) => void;
}

const FormSwitch: FC<FormSwitchProps> = ({
  name,
  label,
  onChange,
  required,
  description,
  containerProps,
  showEdit = false,
  ...props
}) => {
  const form = useFormComponentContext();
  const { editedSwitchStyles } = useEditStyles({ name, showEdit });

  return (
    <Flex align="center" gap="sm" {...containerProps}>
      <Switch
        label={label}
        required={required}
        styles={{ ...editedSwitchStyles, ...props.styles }}
        {...form.getInputProps(name)}
        {...props}
        checked={form.getInputProps(name).value}
        onChange={(event) => {
          const value = event.target.checked;
          form.getInputProps(name).onChange(event);
          onChange?.(value);
        }}
        //styles={{
        //  track: {
        //    borderRadius: '4px',
        //  },
        //  thumb: {
        //    width: '0.9rem',
        //    borderRadius: '2px',
        //  },
        //}}
      />
    </Flex>
  );
};

export default FormSwitch;
