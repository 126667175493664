import { createContext, ReactElement, ReactNode, useState } from 'react';

import { ICityResult, IPlaceResult } from 'interfaces/Address';
import { IBusiness } from 'interfaces/Business';

import ApiService from 'services/api';
import { IVoiceResult } from 'interfaces/VoiceResult';

interface IBusinessContext {
  actions: {
    getVoices: () => Promise<IVoiceResult[]>;
    setBusiness: (business: IBusiness) => void;
    getBusinesses: (input: string) => Promise<IBusiness[]>;
    validateWebsite: (website: string) => Promise<boolean>;
    getPlaceDetails: (placeId: string) => Promise<IPlaceResult>;
  };
  data: {
    voices: IVoiceResult[];
    business: IBusiness | null;
  };
}

interface IBusinessProvider {
  children: ReactNode;
}

const BusinessContext = createContext<IBusinessContext>({
  actions: {
    getVoices: async () => {
      return [] as IVoiceResult[];
    },
    setBusiness: () => {},
    getBusinesses: async () => {
      return [] as IBusiness[];
    },
    getPlaceDetails: async () => {
      return {} as IPlaceResult;
    },
    validateWebsite: async () => {
      return false;
    },
  },
  data: {
    voices: [],
    business: null,
  },
});

export function BusinessProvider(props: IBusinessProvider): ReactElement {
  const { children } = props;
  const [business, setBusiness] = useState<IBusiness | null>(null);
  const [voices, setVoices] = useState<IVoiceResult[]>([]);

  const getBusinesses = async (input: string) => {
    try {
      const response = await ApiService.get('locations/place/autocomplete', {
        params: { input },
      });

      return response.data.places || [];
    } catch (e: any) {
      throw e;
    }
  };

  const getPlaceDetails = async (placeId: string) => {
    try {
      const response = await ApiService.get('locations/place/details', {
        params: { place_id: placeId },
      });

      const result = response.data;
      let componentsByType = new Map();

      for (var i = 0; i < result.addressComponents.length; i++) {
        var c = result.addressComponents[i];
        componentsByType.set(c.types[0], c);
      }

      const location: ICityResult = {
        latitude: result?.location?.latitude || 0,
        longitude: result?.location?.longitude || 0,
        city: componentsByType.get('locality')?.shortText,
        country: componentsByType.get('country')?.shortText,
        region: componentsByType.get('administrative_area_level_1')?.shortText,
      };

      return { ...result, location };
    } catch (e: any) {
      throw e;
    }
  };

  const validateWebsite = async (website: string) => {
    try {
      const response = await ApiService.get('user/validate-website', {
        params: { url: website },
      });
      return response.data;
    } catch (e: any) {
      console.log('error', e);
      throw e;
    }
  };

  const getVoices = async () => {
    try {
      const response = await ApiService.get(`voices`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const voices: IVoiceResult[] = response.data;
      if (voices) {
        const sorted = voices.sort((a, b) => {
          if (a.is_premium === b.is_premium) return a.name > b.name ? 1 : -1;
          return a.is_premium && !b.is_premium ? 1 : -1;
        });
        setVoices(sorted);
        return sorted;
      } else {
        return [];
      }
    } catch (e: any) {
      throw e;
    }
  };

  return (
    <BusinessContext.Provider
      value={{
        data: {
          voices,
          business,
        },
        actions: {
          getVoices,
          setBusiness,
          getBusinesses,
          getPlaceDetails,
          validateWebsite,
        },
      }}
    >
      {children}
    </BusinessContext.Provider>
  );
}

export default BusinessContext;
