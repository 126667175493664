import { z } from 'zod';
import { useNavigate } from 'react-router-dom';

import routes from 'utils/routes';
import { useContext } from 'react';
import Form from 'components/form';
import AuthContext from 'context/AuthContext';
import useFormHandler from 'hooks/useFormHandler';
import { schemaFields } from 'utils/schemaBuilder';
import Testimonial from 'components/Testimonial';
import TermsAndPolicy from 'components/TermsAndPolicy';

const Signin = () => {
  const {
    actions: { login },
  } = useContext(AuthContext);
  const navigate = useNavigate();

  const formSchema = z.object({
    email: schemaFields.email.schema,
    password: z.string().min(1, 'Password is required'),
  });

  const { form, loading, error, setError } = useFormHandler(formSchema, {
    initialValues: {
      email: '',
      password: '',
    },
  });

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    try {
      await login(values.email, values.password);
      navigate(routes.attendantPath.href);
    } catch (error: any) {
      if (error?.response?.status === 401) {
        setError('Email or password is incorrect');
        return;
      }
      setError('An error occurred. Please try again.');
    }
  };

  return (
    <div className="flex-1 grid grid-cols-1 md:grid-cols-2 min-h-screen overflow-hidden">
      <div className="min-h-screen  overflow-y-auto md:overflow-y-hidden">
        <nav className="h-[70px] sticky top-0 w-full custom-bg-color  px-3 md:px-6 flex items-center justify-between">
          <a
            href={routes.dashboardPath.href}
            className="flex items-center gap-4"
          >
            <img src="/images/logo-icon.svg" alt="Nucleus Logo" />
          </a>

          <span></span>
          <div className="flex items-center gap-4 md:hidden">
            <a
              href={routes.registerPath.href}
              className="custom-border-color font-medium border rounded-md h-[40px] px-6 custom-bg-color hover:bg-hover-primary transition-all flex items-center"
            >
              <span>Sign up</span>
            </a>
          </div>
        </nav>
        <div className="md:flex-col items-center justify-center  lg:items-center signup-container extend px-3 md:px-0">
          <div className="border custom-border-color bg-white rounded-2xl p-6 md:p-12 w-full md:w-5/6 xl:w-[540px] mx-auto">
            <div className="mb-4 text-center">
              <h2 className="text-2xl font-semibold custom-text-color">
                Sign in
              </h2>
            </div>

            <div className="flex flex-col gap-2 mb-5">
              <Form form={form} error={error} onSubmit={onSubmit}>
                <Form.Input
                  name="email"
                  type="email"
                  label="Email"
                  placeholder="name@example.com"
                />
                <Form.Password
                  name="password"
                  label="Password"
                  placeholder="Password"
                />
                <Form.Submit loading={loading} disabled={loading}>
                  Sign in
                </Form.Submit>
              </Form>
            </div>
            <div className="flex items-center text-sm custom-text-color font-semibold gap-2 mt-4 justify-center text-center">
              <a href={routes.forgotPasswordPath.href}>Forgot password?</a>
            </div>
          </div>
        </div>
        <div className="w-full md:h-[50px]">
          <TermsAndPolicy />
        </div>
      </div>
      <div className="min-h-screen hidden md:block bg-white border-l custom-border-color">
        <nav className="h-[70px] sticky bg-white z-10 top-0 w-full  px-6 flex items-center justify-between">
          <span></span>

          <div className="items-center gap-4 hidden md:flex">
            <a
              href={routes.registerPath.href}
              className="custom-border-color font-medium border hover:bg-hover-primary transition-all rounded-md h-[40px] px-6 custom-bg-color flex items-center"
            >
              <span>Sign up</span>
            </a>
          </div>
        </nav>
        <div className="min-h-[calc(100vh-70px)] flex flex-col justify-end">
          <Testimonial />
          <div className="w-full mx-auto relative min-h-[500px] ml-[10%] xl:min-h-[600px] overflow-hidden border-l-[3px] border-t-[3px] border-[#5e6ab2] rounded-ss-3xl">
            <img
              src="/images/report-scrnshot.png"
              alt=""
              className="absolute bottom-0 top-0 custom-box-shadow w-[90%]"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signin;
