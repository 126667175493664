import { useContext } from 'react';
import { Flex, Box, Avatar, Text, Paper } from '@mantine/core';

import { getNames } from 'utils/string';
import { getInitials } from 'utils/string';
import AuthContext from 'context/AuthContext';
import { formatPhoneNumber } from 'utils/phone';

import classes from './UserButton.module.scss';

const UserButton = () => {
  const {
    data: { company },
  } = useContext(AuthContext);
  const name = company?.name || '';
  const { first_name, last_name } = getNames(name);
  const initials = getInitials(first_name, last_name);

  return (
    <Box>
      <Paper
        py="sm"
        radius="md"
        withBorder
        style={{ display: 'flex', justifyContent: 'flex-start' }}
      >
        <Flex
          px="md"
          gap={8}
          direction="column"
          className={classes.userButton}
          style={{ alignSelf: 'center' }}
        >
          <Flex gap="sm" align="center">
            <Avatar
              variant="filled"
              size="sm"
              name={name}
              color="var(--primary-color)"
            >
              {initials}
            </Avatar>
            <Text fz="sm" fw={600}>
              {name || 'Unnamed'}
            </Text>
          </Flex>
          <Text span fz="xs">
            {formatPhoneNumber(company?.did || '')}
          </Text>
        </Flex>
      </Paper>
    </Box>
  );
};

export default UserButton;
