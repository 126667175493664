import ComingSoon from 'components/ComingSoon';

const Dashboard = () => {
  return (
    <ComingSoon
      image="/images/dashboard.png"
      title="🚀 Coming Soon: Your Nucleus Dashboard!"
      description="Get ready for a powerful new way to manage your AI workforce! The Nucleus Dashboard is designed to give you real-time insights and effortless control over your virtual agents."
      features={[
        'Agent Performance Stats – Track how your AI employee is handling calls.',
        'Call Trends & Analytics – Gain insights into call volume, response times, and customer interactions.',
        'Guided Onboarding – Set up your AI employee with ease and customize it for your business needs.',
      ]}
      stayTuned="Stay tuned—your next-level business assistant is almost here!"
    />
  );
};

export default Dashboard;
