const removeProtocol = (url: string | undefined) => {
  if (url === undefined) return { url: '', protocol: 'https://' };

  const matches = url.match(/https?:\/\//g);
  const protocol = matches ? matches[matches.length - 1] : undefined;

  return {
    url: url.replace(/https?:\/\//g, ''),
    protocol,
  };
};

const addProtocol = (url: string, protocol: string = 'https://'): string => {
  if (!url) return '';

  const trimmedUrl = url.trim().toLowerCase();

  if (trimmedUrl.startsWith('http://') || trimmedUrl.startsWith('https://')) {
    return trimmedUrl;
  }

  return `${protocol}${trimmedUrl}`;
};

const isValidUrl = (url: string) => {
  const regex =
    /^(?:http(s)?:\/\/)?(?!localhost)([\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$)/;
  return regex.test(url);
};

export { removeProtocol, addProtocol, isValidUrl };
