import { Text } from '@mantine/core';
import { useCountStore } from '../../store';

const AdditionalDetailsPage = () => {
  const count = useCountStore((state) => state.count);
  const incrementCount = useCountStore((state) => state.increment);

  return (
    <div>
      Additional Details Page
      <Text>The count is {count}</Text>
      <button onClick={incrementCount}>one up</button>
    </div>
  );
};

export default AdditionalDetailsPage;
