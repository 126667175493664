import { io } from 'socket.io-client';
import {
  useEffect,
  ReactNode,
  useContext,
  ReactElement,
  createContext,
} from 'react';
import AuthContext from './AuthContext';
import BillingContext from './BillingContext';
import { useNotification } from 'context/NotificationContext';

const STRIPE_UPDATE = 'stripe:update';
const ACCOUNT_UPDATE = 'account:update';

interface SocketProviderProps {
  children: ReactNode;
}

const SocketContext = createContext<{
  actions: {};
  data: {};
}>({
  actions: {},
  data: {},
});

const socket = io(process.env.REACT_APP_WS_URL, {
  path: '/api/v1/socket.io',
  transports: ['websocket'],
});

export function SocketProvider(props: SocketProviderProps): ReactElement {
  const { children } = props;
  const {
    data: { authToken },
    actions: { isLoggedIn, getUser },
  } = useContext(AuthContext);
  const {
    actions: { syncStripe },
  } = useContext(BillingContext);
  const { showSuccess } = useNotification();

  useEffect(() => {
    if (isLoggedIn()) {
      socket.on('connect', () => {
        socket.emit('join', { token: authToken?.access_token });
        syncStripe();
        getUser();
      });

      socket.on(STRIPE_UPDATE, (value) => {
        getUser();
        showSuccess('Your account has been updated');
      });

      socket.on(ACCOUNT_UPDATE, () => {
        getUser();
      });

      socket.connect();

      if (socket.connected)
        socket.emit('join', { token: authToken?.access_token });

      return () => {
        socket.removeAllListeners();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authToken]);

  return (
    <SocketContext.Provider
      value={{
        actions: {},
        data: {},
      }}
    >
      {children}
    </SocketContext.Provider>
  );
}
export default SocketContext;
