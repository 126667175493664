import { FormErrors } from '@mantine/form';

const getUnsavedCount = (dirty: Record<string, boolean> = {}) => {
  return Object.values(dirty || {}).filter((value) => value === true).length;
};

const getErrorCount = (errors: FormErrors = {}) => {
  return Object.keys(errors).length;
};

const getUnsavedCountByField = (
  dirty: Record<string, boolean> = {},
  fields: string[]
) => {
  return Object.keys(dirty || {}).filter(
    (key) =>
      dirty[key] === true && fields.find((field) => key.startsWith(field))
  ).length;
};

const getErrorCountByField = (errors: FormErrors = {}, fields: string[]) => {
  return Object.keys(errors || {}).filter(
    (key) => errors[key] && fields.find((field) => key.startsWith(field))
  ).length;
};

const getUnsavedMapByField = (
  dirty: Record<string, boolean> = {},
  fields: string[]
) => {
  return Object.keys(dirty || {}).reduce<Record<string, number>>((acc, key) => {
    if (dirty[key] === true && fields.find((field) => key.startsWith(field))) {
      acc[key] = (acc[key] || 0) + 1;
    }
    return acc;
  }, {});
};

export {
  getUnsavedCount,
  getUnsavedCountByField,
  getUnsavedMapByField,
  getErrorCountByField,
  getErrorCount,
};
