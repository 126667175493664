import { FC } from 'react';
import { Autocomplete, AutocompleteProps, Loader } from '@mantine/core';

import { useFormComponentContext } from 'context/FormContext';
import useEditStyles from 'hooks/useEditStyles';

interface FormAutocompleteProps extends Omit<AutocompleteProps, 'onSelect'> {
  name: string;
  label?: string;
  error?: string;
  loading?: boolean;
  required?: boolean;
  showEdit?: boolean;
  placeholder?: string;
  description?: string;
  filterBySearch?: boolean;
  onChange: (value: string) => void;
  onOptionSubmit: (value: string) => void;
  data: { value: string; label: string }[];
}

const filter = ({ options, search }: { options: any[]; search: string }) => {
  if (search.length > 0) {
    return options;
  }
  return [];
};

const FormAutocomplete: FC<FormAutocompleteProps> = ({
  name,
  data,
  label,
  loading,
  onChange,
  required,
  description,
  placeholder,
  renderOption,
  onOptionSubmit,
  showEdit = false,
  filterBySearch = false,
  ...props
}) => {
  const form = useFormComponentContext();
  const { editedInputStyles } = useEditStyles({ name, showEdit });

  const propsByFilter: any = { ...props };

  if (!filterBySearch) {
    propsByFilter.filter = filter;
  }

  return (
    <Autocomplete
      clearable
      label={label}
      data={data || []}
      required={required}
      placeholder={placeholder}
      description={description}
      renderOption={renderOption}
      onOptionSubmit={onOptionSubmit}
      rightSection={loading ? <Loader size={14} /> : null}
      onClear={() => form.setFieldValue(name, '')}
      styles={{ ...editedInputStyles, ...props.styles }}
      {...form.getInputProps(name)}
      {...propsByFilter}
      onChange={(value) => {
        onChange(value);
        form.getInputProps(name).onChange(value);
      }}
    />
  );
};

export default FormAutocomplete;
