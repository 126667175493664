import { useFormComponentContext } from 'context/FormContext';

interface useEditStylesProps {
  name: string;
  showEdit?: boolean;
}

const editedColor = '#fffbf6';

const useEditStyles = ({ name, showEdit }: useEditStylesProps) => {
  const form = useFormComponentContext();
  const isDirty = form.getDirty()[name];

  let editedInputStyles = undefined;
  let editedBoxStyles = undefined;
  let editedSelectStyles = undefined;
  let editedCheckboxStyles = undefined;
  let editedSwitchStyles = undefined;

  if (showEdit && isDirty) {
    editedInputStyles = {
      input: {
        backgroundColor: editedColor,
      },
    };

    editedBoxStyles = {
      backgroundColor: editedColor,
    };

    editedSelectStyles = {
      input: {
        backgroundColor: editedColor,
      },
    };

    editedCheckboxStyles = {
      checkbox: {
        backgroundColor: editedColor,
      },
    };

    editedSwitchStyles = {
      thumb: {
        backgroundColor: editedColor,
      },
    };
  }

  return {
    editedBoxStyles,
    editedInputStyles,
    editedSelectStyles,
    editedCheckboxStyles,
    editedSwitchStyles,
  };
};

export default useEditStyles;
