export const DEFAULT_COUNTRY = 'CA';

export function default_welcome(): string {
  const welcome = process.env.REACT_APP_WELCOME_DEFAULT;
  if (welcome) return welcome;
  return 'Hello, thank you for calling {company_name}. How can I help you?';
}

export function default_goodbye(): string {
  return 'Thank you for calling {company_name}. Have a great day!';
}
