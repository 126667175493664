import { Modal, Text, Group, Button, Stack, ThemeIcon } from '@mantine/core';
import { IconAlertTriangle } from '@tabler/icons-react';

interface NavBlockerModalProps {
  isOpen: boolean;
  onClose: () => void;
  onOk: () => void;
  onCancel: () => void;
}

const NavBlockerModal = ({
  isOpen,
  onClose,
  onOk,
  onCancel,
}: NavBlockerModalProps) => {
  return (
    <Modal
      centered
      radius="md"
      shadow="lg"
      opened={isOpen}
      onClose={onClose}
      withCloseButton={false}
      closeOnClickOutside={false}
      closeOnEscape={false}
    >
      <Stack align="center" mb="xl">
        <ThemeIcon size={48} radius="xl" color="yellow.4" variant="light">
          <IconAlertTriangle size={28} />
        </ThemeIcon>
        <Stack gap={4} align="center">
          <Text fw={500} size="lg">
            Unsaved Changes
          </Text>
          <Text size="sm" c="dimmed" ta="center">
            You have unsaved changes. Are you sure you want to leave this page?
            Your changes will be lost.
          </Text>
        </Stack>
      </Stack>

      <Group justify="center" gap="sm">
        <Button size="sm" variant="default" onClick={onCancel} w={130}>
          Stay Here
        </Button>
        <Button size="sm" color="red" onClick={onOk} w={130}>
          Leave Page
        </Button>
      </Group>
    </Modal>
  );
};

export default NavBlockerModal;
