import {
  ReactNode,
  useContext,
  ReactElement,
  createContext,
  useState,
} from 'react';

import ApiService from 'services/api';
import AuthContext from './AuthContext';
import { IStoreResult } from 'interfaces/GetUserResult';

export const PRO_MONTH_PRICE_NAME = 'PRO_MONTH';
export const PRO_YEAR_PRICE_NAME = 'PRO_YEAR';

interface IBillingContext {
  actions: {
    setPricingOpened: (pricingOpened: boolean) => void;
    createStoreSession: (data: IStoreResult) => Promise<string>;
    createStoreUpgradeSession: (data: IStoreResult) => Promise<string>;
    createStorePortalSession: (
      return_url: string | undefined
    ) => Promise<string>;
    syncStripe: () => Promise<string>;
  };
  data: {
    pricingOpened: boolean;
  };
}

interface IBillingProvider {
  children: ReactNode;
}

const BillingContext = createContext<IBillingContext>({
  actions: {
    setPricingOpened: () => {},
    createStoreSession: async () => '',
    createStoreUpgradeSession: async () => '',
    createStorePortalSession: async () => '',
    syncStripe: async () => '',
  },
  data: {
    pricingOpened: false,
  },
});

export function BillingProvider(props: IBillingProvider): ReactElement {
  const { children } = props;
  const {
    data: { authToken },
    actions: { isLoggedIn, setAccount },
  } = useContext(AuthContext);
  const [pricingOpened, setPricingOpened] = useState(false);

  const createStoreSession = async (data: IStoreResult) => {
    if (!isLoggedIn()) {
      throw new Error('You are not logged in. Please log in and try again');
    }
    try {
      const token = authToken;
      const response = await ApiService.post(
        `stripe/create`,
        {
          promo_code: data?.promo_code, //Stripe coupon promotion code
          return_url: data?.return_url, //The url the user is redirected to after purchase (Entitlements might be out of sync because of the webook)
          lookup_key: data?.lookup_key, //The lookup_key of the price object the user is presented with (PRO_MONTH, PRO_YEAR etc.)
          refer_id: data?.refer_id, //The refer id from the affiliate program
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token?.access_token}`,
          },
        }
      );

      return response.data;
    } catch (e: any) {
      throw e;
    }
  };

  const createStoreUpgradeSession = async (data: IStoreResult) => {
    if (!isLoggedIn()) {
      throw new Error('You are not logged in. Please log in and try again');
    }
    try {
      const token = authToken;
      const response = await ApiService.post(
        `stripe/upgrade/create`,
        {
          promo_code: data?.promo_code,
          return_url: data?.return_url,
          lookup_key: data?.lookup_key,
          refer_id: data?.refer_id,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token?.access_token}`,
          },
        }
      );

      return response.data;
    } catch (e: any) {
      throw e;
    }
  };

  const createStorePortalSession = async (return_url?: string) => {
    if (!isLoggedIn()) {
      throw new Error('You are not logged in. Please log in and try again');
    }
    try {
      const token = authToken;
      const response = await ApiService.post(
        `stripe/portal/create`,
        { return_url },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token?.access_token}`,
          },
        }
      );

      return response.data;
    } catch (e: any) {
      throw e;
    }
  };

  const syncStripe = async () => {
    try {
      const token = authToken;
      const response = await ApiService.put(
        'stripe/sync',
        {},
        {
          headers: {
            Authorization: `Bearer ${token?.access_token}`,
          },
        }
      );
      const newAccount = response.data;
      setAccount(newAccount);
      return response.data;
    } catch (e: any) {
      throw e;
    }
  };

  return (
    <BillingContext.Provider
      value={{
        data: {
          pricingOpened,
        },
        actions: {
          syncStripe,
          setPricingOpened,
          createStoreSession,
          createStorePortalSession,
          createStoreUpgradeSession,
        },
      }}
    >
      {children}
    </BillingContext.Provider>
  );
}

export default BillingContext;
