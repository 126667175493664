import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { formatPhoneNumber } from 'utils/phone';
import { Button } from '@mantine/core';
import CallButton from 'components/call/CallButton';
import TermsAndPolicy from 'components/TermsAndPolicy';
import routes from 'utils/routes';
import AuthContext from 'context/AuthContext';

const Welcome = () => {
  const navigate = useNavigate();
  const {
    data: { company, account, newSignup },
    actions: { setNewSignup },
  } = useContext(AuthContext);

  const navigateToDashboard = () => {
    setNewSignup(null);
    navigate(routes.attendantPath.href);
  };

  const hasCompleteDetails = newSignup?.website ? true : false;

  return (
    <div className="flex-1 grid grid-cols-1  min-h-screen overflow-hidden">
      <div className="md:min-h-screen  overflow-y-auto md:overflow-y-hidden">
        <nav className="h-[70px] sticky top-0 w-full custom-bg-color  px-3 md:px-6 flex items-center justify-between">
          <a
            href={routes.dashboardPath.href}
            className="flex items-center gap-4"
          >
            <img src="/images/logo-icon.svg" alt="Nucleus Logo" />
          </a>

          <span></span>
          <div className="flex items-center gap-4">
            <p className="text-sm custom-text-color pl-2 md:pl-0">
              <span className="opacity-70">You are signed in as</span>{' '}
              <span className="font-bold">{account?.username}</span>
            </p>
          </div>
        </nav>
        <div className="block md:flex md:flex-col items-center justify-center lg:items-center md:h-[calc(100vh-120px)] min-h-[calc(100vh-120px)] px-3 md:px-0">
          <div className="w-full pt-12 md:pt-0 md:w-5/6 xl:w-[560px] mx-auto mb-12 text-center md:text-left">
            <h2 className="text-3xl text-center font-bold mb-5 custom-text-color">
              {hasCompleteDetails
                ? `🎉 Your AI business number is ready!`
                : '🎉 Your AI employee is ready to work!'}
            </h2>
            <p className="custom-text-color text-md mb-5 text-center flex flex-wrap items-center justify-center gap-2">
              <span className="font-semibold opacity-70">
                Call your new number now:
              </span>
              <span className="font-bold text-[#149041] text-xl inline-flex items-center gap-2">
                <CallButton number={company?.did || ''}>
                  {formatPhoneNumber(company?.did || '')}
                </CallButton>
                <img
                  src="/images/call-icon.svg"
                  alt="Call icon"
                  className="w-6"
                />
              </span>
            </p>
          </div>
          <hr className="w-full border-t border-gray-200 mb-5 h-[1px] max-w-[90%] mx-auto" />

          {!hasCompleteDetails && (
            <div className="border block md:flex custom-border-color bg-white rounded-2xl mt-16  mx-auto">
              <div className="w-full p-6 md:p-12">
                <div className="mb-8">
                  <h2 className="text-2xl font-semibold custom-text-color">
                    Visit your Dashboard
                  </h2>
                </div>

                <div>
                  <ul className=" mb-8">
                    <li className="mb-4 custom-text-color flex items-center gap-2">
                      <img
                        src="/images/settings.svg"
                        alt="Check Icon"
                        className="min-w-3"
                      />
                      <span className="opacity-80">
                        Train your AI and customize its voice
                      </span>
                    </li>
                    <li className="custom-text-color mb-4 flex items-center gap-2">
                      <img
                        src="/images/user.svg"
                        alt="Check Icon"
                        className="min-w-3"
                      />
                      <span className="opacity-80">
                        Add your team for your AI employee to direct your
                        callers
                      </span>
                    </li>
                    <li className="custom-text-color mb-4 flex items-center gap-2">
                      <img
                        src="/images/bar-chart-2.svg"
                        alt="Check Icon"
                        className="min-w-3"
                      />
                      <span>
                        <span className="opacity-80">
                          View real time analytical data on calls{' '}
                          <b>(coming soon!)</b>
                        </span>
                      </span>
                    </li>
                  </ul>

                  <Button miw="100%" onClick={navigateToDashboard}>
                    Go to Dashboard
                  </Button>
                </div>
              </div>
            </div>
          )}
          {hasCompleteDetails && (
            <div className="border block md:flex custom-border-color bg-white rounded-2xl mt-16 w-[90%] mx-auto">
              <div className="w-full md:w-1/2 p-6 md:p-12 border-b md:border-b-0 md:border-r custom-border-color">
                <div className="mb-7">
                  <h2 className="text-2xl font-semibold custom-text-color">
                    Call your AI employee and ask it about your business
                  </h2>
                </div>

                <div>
                  <h4 className="text-base text-[#172784] border border-[#A8A5D3] h-8 font-semibold bg-[#E4E6F2] rounded-lg p-2 inline-flex items-center justify-center mb-6">
                    Try these questions:
                  </h4>

                  <ul className="">
                    <li className="mb-3 custom-text-color flex items-center gap-2">
                      <img
                        src="/images/inclined-arr.svg"
                        alt="Check Icon"
                        className="min-w-3"
                      />
                      <span className="opacity-80">
                        What services does {company?.name} offer for businesses?
                      </span>
                    </li>
                    <li className="custom-text-color mb-3 flex items-center gap-2">
                      <img
                        src="/images/inclined-arr.svg"
                        alt="Check Icon"
                        className="min-w-3"
                      />
                      <span className="opacity-80">
                        Can you tell me about {company?.name} residential
                        services?
                      </span>
                    </li>
                    <li className="custom-text-color mb-3 flex items-center gap-2">
                      <img
                        src="/images/inclined-arr.svg"
                        alt="Check Icon"
                        className="min-w-3"
                      />
                      <span className="opacity-80">
                        What is the history of {company?.name}?
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="w-full md:w-1/2 p-6 md:p-12">
                <div className="mb-7">
                  <h2 className="text-2xl font-semibold custom-text-color">
                    Visit your Dashboard
                  </h2>
                </div>

                <div>
                  <ul className="mb-7">
                    <li className="mb-4 custom-text-color flex items-center gap-2">
                      <img
                        src="/images/settings.svg"
                        alt="Check Icon"
                        className="min-w-3"
                      />
                      <span className="opacity-80">
                        Train your AI and customize its voice
                      </span>
                    </li>
                    <li className="custom-text-color mb-4 flex items-center gap-2">
                      <img
                        src="/images/user.svg"
                        alt="Check Icon"
                        className="min-w-3"
                      />
                      <span className="opacity-80">
                        Add your team for your AI employee to direct your
                        callers
                      </span>
                    </li>
                    <li className="custom-text-color mb-4 flex items-center gap-2">
                      <img
                        src="/images/bar-chart-2.svg"
                        alt="Check Icon"
                        className="min-w-3"
                      />
                      <span className="opacity-80">
                        View real time analytical data on calls{' '}
                        <b>(coming soon!)</b>
                      </span>
                    </li>
                  </ul>

                  <Button miw="100%" onClick={navigateToDashboard}>
                    Go to Dashboard
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="w-full md:h-[50px]">
          <TermsAndPolicy />
        </div>
      </div>
    </div>
  );
};

export default Welcome;
