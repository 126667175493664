import { Modal, Text, Group, Button } from '@mantine/core';

interface ConfirmModalProps {
  title: string;
  message: string;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  loading?: boolean;
}

const ConfirmModal = ({
  title,
  message,
  isOpen,
  onClose,
  loading,
  onConfirm,
}: ConfirmModalProps) => {
  return (
    <Modal
      centered
      radius="md"
      shadow="lg"
      opened={isOpen}
      onClose={onClose}
      title={<Text fw={500}>{title}</Text>}
    >
      <Text size="sm" mb="xl">
        {message}
      </Text>
      <Group justify="flex-end" gap="sm">
        <Button
          size="sm"
          variant="default"
          onClick={onClose}
          disabled={loading}
        >
          Cancel
        </Button>
        <Button color="red" size="sm" onClick={onConfirm} loading={loading}>
          Delete
        </Button>
      </Group>
    </Modal>
  );
};

export default ConfirmModal;
