import React, { useCallback, useContext, useEffect, useState } from 'react';
import ApiService from 'services/api';
import { ICardItem } from 'interfaces/CardItem';
import { IVoiceResult } from 'interfaces/VoiceResult';
import AuthContext from './AuthContext';

interface OrganizationProviderProps {
  children: React.ReactNode;
}

const OrganizationContext = React.createContext<{
  actions: {
    getVoices: () => Promise<IVoiceResult[]>;
    setSuccess: (_: string | null | undefined) => void;
    setError: (_: string | null | undefined) => void;
    setWarning: (_: string | null | undefined) => void;
  };
  data: {
    defaultCards: ICardItem[];
    voices: IVoiceResult[];
    success: string | null | undefined;
    error: string | null | undefined;
    warning: string | null | undefined;
  };
}>({
  actions: {
    getVoices: async () => [],
    setSuccess: () => {},
    setError: () => {},
    setWarning: () => {},
  },
  data: {
    defaultCards: [],
    voices: [],
    success: undefined,
    error: undefined,
    warning: undefined,
  },
});

export function OrganizationProvider(
  props: OrganizationProviderProps
): React.ReactElement {
  const { children } = props;
  const {
    data: { authToken },
    actions: { isLoggedIn },
  } = useContext(AuthContext);

  const [voices, setVoices] = useState<IVoiceResult[]>([]);
  const [defaultCards, setDefaultCards] = useState<ICardItem[]>([]);

  const [warning, setWarning] = useState<string | null | undefined>();
  useEffect(() => {
    if (warning) setTimeout(() => setWarning(null), 10 * 1000);
  }, [warning, setWarning]);

  const [error, setError] = useState<string | null | undefined>(null);
  const [success, setSuccess] = useState<string | null | undefined>(null);

  const getVoices = useCallback(() => {
    return new Promise<IVoiceResult[]>(async (resolve, reject) => {
      try {
        const response = await ApiService.get(`voices`, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const voices: IVoiceResult[] = response.data;
        if (voices) {
          const sorted = voices.sort((a, b) => {
            if (a.is_premium === b.is_premium) return a.name > b.name ? 1 : -1;
            return a.is_premium && !b.is_premium ? 1 : -1;
          });
          setVoices(sorted);
          resolve(sorted);
        } else {
          resolve([]);
        }
      } catch (e: any) {
        reject(e);
      }
    });
  }, []);

  const getDefaultCards = useCallback(() => {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const response = await ApiService.get(`default_context`, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        setDefaultCards(response.data.cards);
        resolve(response.data);
      } catch (e: any) {
        reject(e);
      }
    });
  }, []);

  useEffect(() => {
    async function fetchData() {
      const v = await getVoices();

      setVoices(v);
    }
    fetchData();
  }, [getVoices]);

  useEffect(() => {
    if (isLoggedIn()) getDefaultCards();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authToken, isLoggedIn]);

  return (
    <OrganizationContext.Provider
      value={{
        actions: {
          getVoices,
          setSuccess,
          setError,
          setWarning,
        },
        data: {
          defaultCards,
          voices,
          success,
          error,
          warning,
        },
      }}
    >
      {children}
    </OrganizationContext.Provider>
  );
}
export default OrganizationContext;
